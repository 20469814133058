import * as ActionTypes from "./actionTypes";

const setToken = (accessToken, expiration) => ({
  type: ActionTypes.SET_TOKEN,
  accessToken,
  expiration,
});

const clearToken = () => ({
  type: ActionTypes.CLEAR_TOKEN
});

export default {
  setToken,
  clearToken
};
