import * as ActionTypes from "./actionTypes";

const initialState = {
  all: []
};

export default function messages(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.SET_EVENTS:
      return {
        ...state,
        all: action.events
      };
    case ActionTypes.REMOVE_EVENT:
      return {
        ...state,
        all: state.all.filter(a => a._id !== action.id)
      };
    default:
      return state;
  }
}
