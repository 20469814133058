import React from "react";

import Input from "../../../../forms/Input";
import Checkbox from "../../../../forms/Checkbox";
import Modal from "../../../../components/Modal";

import loader from "../../../../assets/images/common/loader-web.svg";
import { call } from "../../../../api/core";

const statuses = [
  {name: 'Draft', _id: 'draft'},
  {name: 'Published', _id: 'published'},
];
class FilterModal extends React.Component {
  state = {
    types: [],
    seasons: [],
    loaded: false,
    selectedTypes: [],
    selectedSeasons: [],
    selectedStatuses: ['draft','published'],
  };

  async componentDidMount() {
    const typesCall = call("/enum/recipeTypes", "GET", {});
    const seasonCall = call("/enum/seasons", "GET", {});

    const [types, seasons] = await Promise.all([
      typesCall,
      seasonCall,
    ]);

    this.setState({
      types: types.data,
      seasons: seasons.data,
      loaded: true
    });
  }

  onCheckboxChange = (type, id) => {
    const copy = [...this.state[type]];

    if (this.state[type].includes(id)) {
      const index = this.state[type].indexOf(id);
      copy.splice(index, 1);
    } else {
      copy.push(id);
    }

    this.setState({
      [type]: copy
    });
  };

  onSubmit = () => {
    const params = {
      types: this.state.selectedTypes,
      seasons: this.state.selectedSeasons,
      status: this.state.selectedStatuses,
    };

    Object.keys(params).forEach(key => {
      if (params[key].length === 0) {
        delete params[key];
      }
    });

    this.props.onSubmit(params);
    this.props.close();
  };

  render() {
    return (
      <Modal
        {...this.props}
        loading={!this.state.loaded}
        className="recipes-modal"
        buttons={[
          {
            children: "Close",
            outlined: true,
            small: true,
            onClick: this.props.close
          },
          {
            children: "Select",
            small: true,
            onClick: this.onSubmit
          }
        ]}
      >
        {this.state.loaded ? (
          <React.Fragment>
            <h6 className="page-section">Filter</h6>
            <span className="label">Recipe Type</span>
            <div className="checkboxes-list">
              {this.state.types.map(t => (
                <Checkbox
                  key={t._id}
                  name={t.name}
                  value={this.state.selectedTypes.includes(t._id)}
                  onChange={() => this.onCheckboxChange("selectedTypes", t._id)}
                />
              ))}
            </div>
            <span className="label">Recipe Season</span>
            <div className="checkboxes-list">
              {this.state.seasons.filter(s => s.forRecipe).map(s => (
                <Checkbox
                  key={s._id}
                  name={s.name}
                  value={this.state.selectedSeasons.includes(s._id)}
                  onChange={() =>
                    this.onCheckboxChange("selectedSeasons", s._id)
                  }
                />
              ))}
            </div>
            <span className="label">Status</span>
            <div className="checkboxes-list">
              {statuses.map(t => (
                <Checkbox
                  key={t._id}
                  name={t.name}
                  value={this.state.selectedStatuses.includes(t._id)}
                  onChange={() => this.onCheckboxChange("selectedStatuses", t._id)}
                />
              ))}
            </div>
          </React.Fragment>
        ) : (
          <img className="loader " src={loader} alt="loader" />
        )}
      </Modal>
    );
  }
}

export default FilterModal;
