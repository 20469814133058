import React from "react";
import { Row, Col } from "react-grid-system";
import { connect } from "react-redux";

import Layout from "../../components/Layout";
import PageHeader from "../../components/PageHeader";
import Input from "../../forms/Input";
import Button from "../../components/Button";
import { isValidEmail } from "../../utils/helpers";
import { Link } from "react-router-dom/cjs/react-router-dom";

class Login extends React.Component {
  state = { email: "", password: "", errors: {}, loading: false };

  updateField = (e, field) => {
    this.setState({
      [field]: e.target.value,
      errors: {}
    });
  };

  validateFields = () => {
    const isEmailValid = isValidEmail(this.state.email);
    const isPasswordExists = !!this.state.password;

    if (isEmailValid && isPasswordExists) {
      return true;
    }

    this.setState({
      errors: {
        email: !isEmailValid ? "Email is invalid" : null,
        password: !isPasswordExists ? "Password cannot be blank" : null
      }
    });
  };

  loginRequest = () => {
    if (this.validateFields()) {
      this.props.login(this.state.email, this.state.password);
    }
  };

  handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      this.loginRequest();
    }
  }

  render() {
    return (
      <Layout disableSidebar>
        <Row justify="center">
          <Col md={6} lg={4}>
            <div className="login-form">
              <PageHeader title="Log In" />
              <Input
                disabled={this.props.loading}
                error={this.state.errors.email}
                label="Email"
                placeholder="Type Here"
                type="email"
                value={this.state.email}
                onChange={e => this.updateField(e, "email")}
              />
              <Input
                disabled={this.props.loading}
                error={this.state.errors.password}
                label="Password"
                placeholder="Type Here"
                type="password"
                value={this.state.password}
                onChange={e => this.updateField(e, "password")}
                onKeyPress={this.handleKeyPress}
              />
              <Link to="/forgot" className="forgot-password-link">Forgot password?</Link>
              <Button
                disabled={this.props.loading}
                limited
                onClick={this.loginRequest}
              >
                {this.props.loading ? "Authentication..." : "Log In"}
              </Button>
              <p className="error">{this.props.error}</p>
            </div>
          </Col>
        </Row>
      </Layout>
    );
  }
}

const mapStateToProps = state => ({
  error: state.error["LOGIN"],
  loading: state.loading["LOGIN"]
});

const mapDispatchToProps = dispatch => ({
  login: (email, password) => dispatch({ type: "LOGIN", email, password })
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
