import React from "react";

class Tabs extends React.Component {
  state = { active: 0 };

  componentDidMount() {
    this.setState({ active: this.props.defaultActive ?? 0 });
  }

  changeActive = index => {
    this.props.onChange(index)
    this.setState({
      active: index
    });
  };

  render() {
    const roundedLeft = this.state.active !== 0;
    const roundedRight = this.state.active !== this.props.tabs.length - 1;

    return (
      <div className="tabs">
        <div className="buttons">
          {this.props.tabs.map((tab, index) => (
            <button
              key={index}
              className={index === this.state.active ? "active" : ""}
              onClick={() => this.changeActive(index)}
            >
              {tab}
            </button>
          ))}
        </div>
        <div
          className={`body ${roundedLeft ? "rounded-left" : ""} ${
            roundedRight ? "rounded-right" : ""
          }`}
        >
          {this.props.children[this.state.active]}
        </div>
      </div>
    );
  }
}

export default React.memo(Tabs);
