import { call, put, takeLatest } from "redux-saga/effects";
import EnumActions from "../../redux/enum/actions";
import ArticleActions from "../../redux/article/actions";
import {
  callAddArticle,
  callUpdateArticle,
  callGetArticles,
  callRemoveArticle
} from "../../api/article";
import { callGetSeasons } from "../../api/enum";

export function* getSeasons(action) {
  try {
    yield put({ type: "GET_SEASONS_REQUEST" });

    const response = yield call(callGetSeasons);
    yield put(EnumActions.setEnums({ seasons: response.data }));

    yield put({ type: "GET_SEASONS_SUCCESS" });
  } catch (error) {
    yield put({
      type: "GET_SEASONS_FAILURE",
      error: error.message
    });
  }
}

export function* getArticles(action) {
  try {
    yield put({ type: "GET_ARTICLE_REQUEST" });

    const response = yield call(callGetArticles, action.page, action.params);
    yield put(ArticleActions.setArticles(response.data));

    yield put({ type: "GET_ARTICLE_SUCCESS" });
  } catch (error) {
    yield put({
      type: "GET_ARTICLE_FAILURE",
      error: error.message
    });
  }
}

export function* addArticle(action) {
  try {
    yield put({ type: "ADD_ARTICLE_REQUEST" });

    const response = yield call(callAddArticle, action.article);
    action.onSuccess();

    yield put({ type: "ADD_ARTICLE_SUCCESS" });
  } catch (error) {
    yield put({
      type: "ADD_ARTICLE_FAILURE",
      error: error.message
    });
  }
}

export function* updateArticle(action) {
  try {
    yield put({ type: "UPDATE_ARTICLE_REQUEST" });

    const response = yield call(callUpdateArticle, action.id, action.body);
    action.onSuccess();

    yield put({ type: "UPDATE_ARTICLE_SUCCESS" });
  } catch (error) {
    yield put({
      type: "UPDATE_ARTICLE_FAILURE",
      error: error.message
    });
  }
}

export function* removeArticle(action) {
  try {
    yield put({ type: "REMOVE_ARTICLE_REQUEST" });

    const response = yield call(callRemoveArticle, action.id);
    yield put(ArticleActions.removeArticle(action.id));

    yield put({ type: "REMOVE_ARTICLE_SUCCESS" });
  } catch (error) {
    yield put({
      type: "REMOVE_ARTICLE_FAILURE",
      error: error.message
    });
  }
}

const articleSaga = [
  takeLatest("GET_SEASONS", getSeasons),
  takeLatest("GET_ARTICLES", getArticles),
  takeLatest("ADD_ARTICLE", addArticle),
  takeLatest("UPDATE_ARTICLE", updateArticle),
  takeLatest("REMOVE_ARTICLE", removeArticle)
];

export default articleSaga;
