import { combineReducers, createStore, applyMiddleware } from "redux";
import createSagaMiddleware from "redux-saga";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { createLogger } from "redux-logger";

import sessionReducer from "./session/reducer";
import userReducer from "./user/reducer";
import loadingReducer from "./handlers/loading";
import errorReducer from "./handlers/error";
import recipeReducer from "./recipe/reducer";
import eventsReducer from "./event/reducer";
import articleReducer from "./article/reducer";
import enumReducer from "./enum/reducer";
import mealsReducer from "./meal/reducer";
import usersReducer from "./users/reducer";
import adminsReducer from "./admins/reducer";
import reviewsReducer from "./reviews/reducer";
import quotesReducer from "./quotes/reducer";
import backgroundsReducer from "./backgrounds/reducer";
import settingsReducer from "./settings/reducer";
import aacpPlansReducer from "./aacpPlans/reducer";
import sagas from "../sagas";

const reducers = {
  loading: loadingReducer,
  error: errorReducer,
  session: sessionReducer,
  user: userReducer,
  articles: articleReducer,
  recipe: recipeReducer,
  enum: enumReducer,
  meal: mealsReducer,
  event: eventsReducer,
  users: usersReducer,
  admins: adminsReducer,
  reviews: reviewsReducer,
  quotes: quotesReducer,
  settings: settingsReducer,
  backgrounds: backgroundsReducer,
  aacpPlans: aacpPlansReducer
};

const sagaMiddleware = createSagaMiddleware();
const rootReducer = combineReducers(reducers);

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["session", "user"],
};
const persistedReducer = persistReducer(persistConfig, rootReducer);

const logger = createLogger({
  collapsed: true,
});

export const store = createStore(
  persistedReducer,
  applyMiddleware(sagaMiddleware, logger)
);
export const persistor = persistStore(store);

sagaMiddleware.run(sagas);
