import React from "react";
import withPermissions from "../../../../utils/PermissionManager";
import Tooltip from "../../../../components/Tooltip";

import star from "../../../../assets/images/common/star.svg";
import edit from "../../../../assets/images/common/edit.svg";
import trash from "../../../../assets/images/common/trash.svg";
import refresh from "../../../../assets/images/common/refresh.svg";
import login from "../../../../assets/images/common/login.svg";
import { callLoginAsUser } from "../../../../api/users";
import Cookies from "js-cookie";
import { FRONTEND_BASE_URL } from "../../../../config";
import { connect } from "react-redux";

class UserMenu extends React.Component {
  state = {
    isLoading: false
  };

  goReviewsPage = () => {
    this.props.history.push(`/reviews/user/${this.props.id}`);
  };

  editUser = () => {
    this.props.history.push(`/rawkstars/edit-user/${this.props.id}`);
  };

  getDeleteItem = () => {
    return (
      this.props.can("delete", "user") && {
        title: "Delete",
        icon: trash,
        onClick: () => this.props.onDelete(this.props.id)
      }
    );
  };

  getRestoreItem = () => {
    return (
      this.props.can("delete", "user") && {
        title: "Restore",
        icon: refresh,
        onClick: () => this.props.onRestore(this.props.id)
      }
    );
  };

  loginAs = async () => {
    try {
      this.setState({ isLoading: true });
      const response = await callLoginAsUser(this.props.id);
      const token = response?.data?.token;
      if (token) {
        const domain = new URL(FRONTEND_BASE_URL).hostname;
        const rootDomain = domain
          .split(".")
          .reverse()
          .splice(0, 2)
          .reverse()
          .join(".");
        Cookies.set("rawk_loginAsToken", token, { domain: rootDomain });
        setTimeout(() => {
          window.open(FRONTEND_BASE_URL + '/login', "_blank");
        }, 300);
      }
    } catch (err) {
    } finally {
      this.setState({ isLoading: false });
    }
  };

  deleteForever = async () => {
    this.setState({ isLoading: true });
    this.props.onDelete(this.props.id)
  };

  render() {
    return (
      <Tooltip
        isLast={this.props.isLast}
        isLoading={this.state.isLoading}
        buttons={[
          {
            title: "Reviews",
            icon: star,
            onClick: () => this.goReviewsPage()
          },
          {
            title: "Login as",
            icon: login,
            onClick: () => this.loginAs()
          },
          this.props.can("edit", "user") && {
            title: "Edit",
            icon: edit,
            onClick: () => this.editUser()
          },

          this.props.isDeleted ? this.getRestoreItem() : this.getDeleteItem(),
          this.props.isDeleted && {
            title: "Delete forever",
            icon: trash,
            onClick: () => this.deleteForever(),
          },
        ]}
      />
    );
  }
}
const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) => ({
  hardDeleteUser: (id) => dispatch({ type: "HARD_DELETE_USER", id }),
});
export default withPermissions(
  connect(mapStateToProps, mapDispatchToProps)(UserMenu)
);
