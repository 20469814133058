import moment from "moment";

const emailRegEx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const isValidEmail = (email) => emailRegEx.test(email);

export const getUpdatedBody = (fields, state) => {
  const body = {};

  fields.forEach((field) => {
    body[field] = state[field];
  });

  return body;
};

export const transformNumberWeek = (numberOfWeek) => {
  return `${moment()
    .week(numberOfWeek ?? 1)
    .day(0)
    .format("D MMM")} - ${moment()
    .week(numberOfWeek ?? 1)
    .day(7)
    .format("D MMM")}`;
};

export const ingredientUnits = [
  {
    value: "cup",
    label: "cup",
  },
  {
    value: "tbsp",
    label: "tbsp",
  },
  {
    value: "tsp",
    label: "tsp",
  },
  {
    value: "fl oz",
    label: "fl oz",
  },
  {
    value: "can",
    label: "can",
  },
  {
    value: "oz",
    label: "oz",
  },
  {
    value: "lbs",
    label: "lbs",
  },
  {
    value: "inch",
    label: "inch",
  },
  {
    value: "drops",
    label: "drops",
  },
];


export const transformDateBackToFront = (date) => {
  return moment(date).format("YYYY-MM-DD")
}

export const transformDateFrontToBack = (date) => {
  return moment(date).format("MM/DD/YYYY")
}