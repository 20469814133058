import { useEffect, useMemo, useState } from "react";

export const useValidateQuotes = ({ body, author, number }) => {
  const [errorBody, setErrorBody] = useState("");
  const [errorAuthor, setErrorAuthor] = useState("");
  const [errorNumber, setErrorNumber] = useState("");
  const [hasError, setHasError] = useState(true);

  useEffect(() => {
    setErrorBody(!body || body.length === 0 ? "Body is required field" : "");

    setErrorNumber(!number ? "Number is required field" : "");

    setHasError(!body || body?.length === 0 || !number);
  }, [body, author, number]);

  return useMemo(
    () => ({
      errorBody,
      errorAuthor,
      errorNumber,
      hasError,
    }),
    [errorAuthor, errorBody, errorNumber, hasError]
  );
};
