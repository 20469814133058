import { call } from "../core";

export const callUploadFile = (file) => {
  const formData = new FormData();
  formData.append("file", file);

  return call("/files/upload", "POST", {
    body: formData,
    headers: { "Content-Type": "multipart/form-data" },
  });
};
