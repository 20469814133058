import { call } from "../core";

export const callAddQuote = (admin) => {
  return call("/quotes", "POST", { body: admin });
};

export const callGetQuotes = () => {
  return call(`/quotes`, "GET", {});
};

export const callRemoveQuote = (id) => {
  return call(`/quotes/${id}`, "DELETE", {});
};

export const callUpdateQuote = ({ _id, ...quote }) => {
  return call(`/quotes/${_id}`, "PATCH", { body: quote });
};
