import React, { Component } from "react";
import { connect } from "react-redux";

import Layout from "../../components/Layout";
import PageHeader from "../../components/PageHeader";
import Tabs from "./components/Tabs";
import TabContent from "./components/TabContent";
import Article from "./components/Article";
import Event from "./components/Event";
import withPermissions from "../../utils/PermissionManager";

class Wellness extends Component {
  componentDidMount() {
    this.props.getSeasons();
    this.props.getArticles();
    this.props.getEvents();
  }

  editArticle = id => {
    this.props.history.push(`/wellness/edit-article/${id}`);
  };

  deleteArticle = id => {
    this.props.removeArticle(id);
  };

  editEvent = id => {
    this.props.history.push(`/wellness/edit-event/${id}`);
  };

  deleteEvent = id => {
    this.props.removeEvent(id);
  };

  render() {
    return (
      <Layout>
        <PageHeader title="Wellness" />
        <Tabs
          tabs={[...this.props.seasons.map(s => s.name), "Calendar of Events"]}
          children={[
            ...this.props.seasons.map(season => (
              <TabContent
                buttonText="+ Add Article"
                data={this.props.articles.filter(
                  article => article.season && article.season._id === season._id
                )}
                component={Article}
                link={{
                  pathname: "/wellness/add-article",
                  state: {
                    season: season._id
                  }
                }}
                onEditClick={this.editArticle}
                onDeleteClick={this.deleteArticle}
                canCreate={this.props.can("create", "article")}
                canEdit={this.props.can("edit", "article")}
                canDelete={this.props.can("delete", "article")}
              />
            )),
            <TabContent
              buttonText="+ Add Event"
              onButtonClick={this.addEvent}
              data={this.props.events}
              component={Event}
              link="/wellness/add-event"
              onEditClick={this.editEvent}
              onDeleteClick={this.deleteEvent}
              canCreate={this.props.can("create", "event")}
              canEdit={this.props.can("edit", "event")}
              canDelete={this.props.can("delete", "event")}
            />
          ]}
        />
      </Layout>
    );
  }
}

const mapStateToProps = state => ({
  seasons: state.enum.seasons,
  articles: state.articles.all,
  events: state.event.all
});

const mapDispatchToProps = dispatch => ({
  getSeasons: () => dispatch({ type: "GET_SEASONS" }),
  getArticles: () => dispatch({ type: "GET_ARTICLES" }),
  removeArticle: id => dispatch({ type: "REMOVE_ARTICLE", id }),
  getEvents: () => dispatch({ type: "GET_EVENTS" }),
  removeEvent: id => dispatch({ type: "REMOVE_EVENT", id })
});

export default withPermissions(
  connect(mapStateToProps, mapDispatchToProps)(Wellness)
);
