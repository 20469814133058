import * as ActionTypes from "./actionTypes";

const setQuotes = (quotes) => ({
  type: ActionTypes.SET_QUOTES,
  quotes,
});

const removeQuotes = (id) => {
  return {
    type: ActionTypes.REMOVE_QUOTES,
    id,
  };
};

const addQuote = (quote) => {
  return {
    type: ActionTypes.ADD_QUOTES,
    quote,
  };
};

const editQuote = (quote) => ({
  type: ActionTypes.EDIT_QUOTES,
  quote,
});

export default {
  setQuotes,
  removeQuotes,
  addQuote,
  editQuote,
};
