import { call, put, select, takeLatest } from "redux-saga/effects";
import SessionActions from "../../redux/session/actions";
import UserActions from "../../redux/user/actions";
import { callLogin } from "../../api/users";
import { callGetAdmin, callRequestReset, callResetPassword } from "../../api/admins";
import jwt from "jsonwebtoken";

function* authenticateUser(user) {
  const tokenPayload = jwt.decode(user.token, { complete: true }).payload;

  yield put(UserActions.setUser(user._id, user.name));
  yield put(SessionActions.setToken(user.token, tokenPayload.exp * 1000));
}

export function* login(action) {
  try {
    yield put({ type: "LOGIN_REQUEST" });
    const response = yield call(callLogin, action.email, action.password);

    yield authenticateUser(response.data);
    yield put({ type: "LOGIN_SUCCESS" });
  } catch (error) {
    yield put({
      type: "LOGIN_FAILURE",
      error:
        error.code === 401 ? "Invalid username or password" : error.message,
    });
  }
}

export function* getPermissionsForAdmin(action) {
  try {
    yield put({ type: "GET_PERMISSIONS_FOR_ADMIN_REQUEST" });

    const id = yield select((state) => state.user.id);

    const adminResponse = yield call(callGetAdmin, id);

    yield put(UserActions.setPermissions(adminResponse.data.permissions));

    yield put({ type: "GET_PERMISSIONS_FOR_ADMIN_SUCCESS" });
  } catch (error) {
    yield put({
      type: "GET_PERMISSIONS_FOR_ADMIN_FAILURE",
      error: error.message,
    });
  }
}

export function* logout() {
  yield put(UserActions.clearUser());
  yield put(SessionActions.clearToken());
}

export function* requestReset(action) {
  try {
    yield put({ type: "POST_RESET_REQUEST" });
    const response = yield call(callRequestReset, action.email);

    action.onSuccess();

    yield put({ type: "POST_RESET_SUCCESS" });
  } catch (error) {
    console.log(error);
    yield put({
      type: "POST_RESET_FAILURE",
      error: error.message
    });
  }
}

export function* resetPassword(action) {
  try {
    yield put({ type: "RESET_PASSWORD_REQUEST" });
    const response = yield call(
      callResetPassword,
      action.token,
      action.password
    );

    action.onSuccess();

    yield put({ type: "RESET_PASSWORD_SUCCESS" });
  } catch (error) {
    yield put({
      type: "RESET_PASSWORD_FAILURE",
      error: error.message
    });
  }
}

const authSaga = [
  takeLatest("LOGIN", login),
  takeLatest("LOGOUT", logout),
  takeLatest("REQUEST_RESET", requestReset),
  takeLatest("RESET_PASSWORD", resetPassword),
  takeLatest("GET_PERMISSIONS_FOR_ADMIN", getPermissionsForAdmin),
];

export default authSaga;
