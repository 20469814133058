import React, { Component } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";

import logo from "../../assets/images/sidebar/logo-new-black.png";
import plan from "../../assets/images/sidebar/plan.svg";
import recipes from "../../assets/images/sidebar/recipes.svg";
import rawkstars from "../../assets/images/sidebar/rawkstars.svg";
import wellness from "../../assets/images/sidebar/wellness.svg";
import user from "../../assets/images/sidebar/user.png";
import settings from "../../assets/images/sidebar/settings.svg";

const sidebarPages = [
  { label: "Plan", path: "/plan", icon: plan },
  { label: "Recipes", path: "/recipes", icon: recipes },
  { label: "Users", path: "/rawkstars", icon: rawkstars },
  // { label: "Wellness", path: "/wellness", icon: wellness },
  { label: "Settings", path: "/settings", icon: settings },
];

class Layout extends Component {
  render() {
    return (
      <div className="page">
        <div className="sidebar">
          <div className="sidebar-content">
            <img src={logo} alt="logo" className="logo" />
            {!this.props.disableSidebar && (
              <>
                <div className="sidebar-links">
                  {sidebarPages.map((link) => (
                    <NavLink
                      key={link.path}
                      to={link.path}
                      className="sidebar-link"
                      activeClassName="active"
                    >
                      <img src={link.icon} alt={link.label} />
                      {link.label}
                    </NavLink>
                  ))}
                </div>
                <div className="sidebar-account">
                  <img src={user} alt="user" className="user-avatar" />
                  <div className="divider" />
                  <button className="logout-button" onClick={this.props.logout}>
                    Log Out
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
        <div className="content animated fadeIn faster">
          {this.props.children}
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch({ type: "LOGOUT" }),
});

export default withRouter(connect(null, mapDispatchToProps)(Layout));
