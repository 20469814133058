import React, { Component } from 'react';
import moment from "moment";
import Layout from "../../components/Layout";
import withPermissions from '../../utils/PermissionManager';
import Column from '../Plan/components/Column';
import { connect } from 'react-redux';
import Modal from '../../components/Modal';
import SuggestInput from '../../forms/SuggestInput';
import { call } from '../../api/core';
import { withRouter } from 'react-router-dom/cjs/react-router-dom.min';
import arrowLeft from '../../assets/images/common/arrow-left.svg';
import Button from '../../components/Button';
import AddRecipeModal from '../../components/AddRecipeModal';

class AACPPlan extends Component {
  state = {
    modalVisible: false,
    options: [],
    loading: false,
    start: null,
    end: null,
    isPreviousWeek: false,
    adding: false,
    addingDate: null,
    addingCategory: null,
    swappingMeal: null,
    currentTab: 0,
    addPlanModalVisible: false,
    newPlanName: '',
    mealIndex: null,
  };

  componentDidMount() {
    const start = moment().startOf("isoWeek");
    const end = moment().endOf("isoWeek");

    this.setState({
      start,
      end,
    });

    this.revalidatePlan();
  }

  getTransformedData = () => {
    const days = {};

    if (this.props.current && this.props.current?.mealPlans) {
      this.props.current.mealPlans.forEach((meal) => {
        if (days[meal.index]) {
          days[meal.index].push(meal);
        } else {
          days[meal.index] = [meal];
        }
      });
    }

    return days;
  };

  openModal = () => {
    this.setState({
      modalVisible: true,
    });
  };

  closeModal = () => {
    this.setState({
      modalVisible: false,
      adding: false,
      addingDate: null,
      addingCategory: null,
      swappingMeal: null,
      options: [],
      loading: false,
      addPlanModalVisible: false,
      newPlanName: '',
    });

    if (this.searchInput) {
      this.searchInput.value = "";
    }
  };

  async getSimilarRecipes(search) {
    if (this.timer) {
      return;
    }

    this.timer = true;
    this.setState({
      loading: true,
    });

    const options = await call("/recipes/match", "GET", { params: { search } });

    this.setState({
      options: options.data,
      loading: false,
    });

    this.timer = false;
  }

  onInputChange = (e) => {
    this.getSimilarRecipes(e.target.value);
  };

  revalidatePlan() {
    this.props.getAACPPlans(this.props.match.params.id);
  }

  togglePlanActivity() {
    this.props.updatePlan(
      this.props.current._id,
      undefined,
      this.props.current.nameSlug,
      undefined,
      !this.props.current.isPlanActive,
      this.revalidatePlan.bind(this)
    )
  }

  onInputSubmit = (recipe) => {
    if (this.state.adding) {
      this.props.updatePlan(
        this.props.current._id,
        [
          {
            portions: 1,
            recipe: recipe._id,
            date: moment(this.state.addingDate).format("MM/DD/YYYY"),
            category: this.state.addingCategory,
            index: this.state.mealIndex
          }
        ],
        this.props.current.nameSlug,
        undefined,
        this.props.current.isPlanActive,
        this.revalidatePlan.bind(this)
      );
    } else {
      const oldMeal = this.props.current.mealPlans.find(
        (m) => m._id === this.state.swappingMeal
      );

      const body = {
        recipe: recipe._id,
        portions: oldMeal.portions,
      };

      this.props.updateMeal(
        this.props.current._id,
        this.state.swappingMeal,
        body,
        this.revalidatePlan.bind(this)
      );
    }

    this.closeModal();
  };

  updateMeal(id, data) {
    this.props.updateMeal(
      this.props.current._id,
      id,
      data,
      this.revalidatePlan.bind(this)
    );
  }

  addMeal = (date, category, index) => {
    this.openModal();
    this.setState({
      adding: true,
      addingDate: date,
      addingCategory: category,
      mealIndex: index
    });
  };

  swapMeal = (id) => {
    this.openModal();
    this.setState({
      swappingMeal: id,
    });
  };

  deleteMeal = (id) => {
    this.props.deleteMeal(this.props.current._id, id, this.revalidatePlan.bind(this));
  } 

  render() {
    const data = this.getTransformedData();
    const week = new Array(7).fill().map((_, i) =>
      moment(moment().startOf("isoWeek"))
        .add(i, "d")
        .startOf("d")
        .toString()
    );

    return <Layout>
      <div className='aacp-header'>
        <button className='aacp-header-button' onClick={this.props.history.goBack}>
          <img src={arrowLeft} />
          Back
        </button>
        <Button small={true} outlined={true} onClick={this.togglePlanActivity.bind(this)}>
          {this.props.current?.isPlanActive ? 'Disable plan' : 'Activate plan'}
        </Button>
      </div>
      <h2 style={{ margin: '10px 0' }} >{this.props.current?.name}</h2>
      <div
        className={`plan-columns ${
          this.props.loading ? "meals-loading" : ""
        }`}
      >
        {week.map((date, index) => (
          <Column
            data={data[index] || []}
            updateMeal={this.updateMeal.bind(this)}
            swapMeal={this.swapMeal.bind(this)}
            addMeal={this.addMeal.bind(this)}
            removeMeal={this.deleteMeal.bind(this)}
            date={date}
            index={index}
            withoutDate={true}
            canEdit={this.props.can("edit", "plan")}
            canCreate={this.props.can("create", "plan")}
            canDelete={this.props.can("delete", "plan")}
          />
        ))}
      </div>
      <AddRecipeModal
        visible={this.state.modalVisible}
        close={this.closeModal}
        onInfoClick={(id) => window.open(`/recipes/edit-recipe/${id}`)}
        onRecipeClick={this.onInputSubmit}
      />
    </Layout>
  }
}

const mapStateToProps = (state) => ({
  loading: state.loading["GET_MEALS"],
  current: state.aacpPlans.current,
});

const mapDispatchToProps = (dispatch) => ({
  getAACPPlans: (slug) => dispatch({ type: 'GET_AACP_PLANS', slug }),
  updatePlan: (id, meals, slug, name, isPlanActive, onSuccess) =>
    dispatch({
      type: "UPDATE_AACP_PLAN",
      id,
      meals,
      slug,
      name,
      isPlanActive,
      onSuccess,
    }),
  deleteMeal: (id, mealId, onSuccess) => dispatch({ type: 'DELETE_AACP_MEAL', id, mealId, onSuccess }),
  updateMeal: (id, mealId, mealPlan, onSuccess) => dispatch({ type: 'UPDATE_AACP_MEAL', id, mealId, mealPlan, onSuccess })
});

export default withPermissions(
  connect(mapStateToProps, mapDispatchToProps)(withRouter(AACPPlan))
);