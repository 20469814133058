import { call, callBlob, callFormEncoded } from "../core";

export const callLogin = (email, password) => {
  return callFormEncoded("/auth/login", "POST", { body: { email, password } });
};

export const callAddUser = (user) => {
  return call("/user", "POST", { body: user });
};

export const callGetUsers = (params) => {
  return call(`/user`, "GET", { params });
};

export const callRemoveUser = (id) => {
  return call(`/user/bulk-soft-delete`, "DELETE", { params: { ids: id } });
};

export const callHardDeleteUser = (id) => {
  return call(`/user/bulk-hard-delete`, "DELETE", { params: { ids: id } });
};

export const callRestoreUser = (id) => {
  return call(`/user/${id}/restore`, "PATCH", {});
};

export const callUpdateUser = (id, body) => {
  return call(`/user/${id}`, "PUT", { body });
};

export const callLoginAsUser = (id) => {
  return call(`/user/${id}/login`, "GET", {});
};

export const callGetUsersCSV = ({ filters }) => {
  const newFilters = filters
  
  Object.keys(newFilters).forEach((key) => {
    if (newFilters[key] === "") {
      delete newFilters[key];
    }
  });
  return callBlob("/user/export", "GET", { params: {...newFilters} }).then((resp) => {
    return resp.data;
  });
};
