import React from "react";
import moment from "moment";

import Tooltip from "../../../../components/Tooltip";

import location from "../../../../assets/images/common/location.svg";
import edit from "../../../../assets/images/common/edit.svg";
import trash from "../../../../assets/images/common/trash.svg";

const getDateRange = (start, end) => {
  const startMoment = moment(start, "MM/DD/YYYY");

  if (!end) {
    return startMoment.format("MMM D, YYYY")
  }

  const endMoment = moment(end, "MM/DD/YYYY");

  const startMonth = startMoment.get("month");
  const startYear = startMoment.get("year");

  const endMonth = endMoment.get("month");
  const endYear = endMoment.get("year");

  if (startYear !== endYear) {
    return `${startMoment.format("MMM D, YYYY")} - ${endMoment.format(
      "MMM D, YYYY"
    )}`;
  }

  if (startMonth !== endMonth) {
    return `${startMoment.format("MMM D")} - ${endMoment.format(
      "MMM D, YYYY"
    )}`;
  }

  return `${startMoment.format("MMM D")}-${endMoment.format("D, YYYY")}`;
};

const Event = props => (
  <div className='event'>
    <div className="photo" style={{ backgroundImage: `url(${props.photo})` }}>
      <div className="badge">{getDateRange(props.start, props.end)}</div>
      <Tooltip
        buttons={[
          props.canEdit && {
            title: "Edit",
            icon: edit,
            onClick: () => props.onEditClick(props._id)
          },
          props.canDelete && {
            title: "Delete",
            icon: trash,
            onClick: () => props.onDeleteClick(props._id)
          }
        ]}
      />
    </div>
    <h3 className={`title ${props.canEdit ? 'hoverable': ''}`} onClick={props.canEdit ? () => props.onEditClick(props._id) : undefined}>{props.title}</h3>
    <div className="location-container">
      <img src={location} alt="location" />
      <p className="location">{props.location}</p>
    </div>
  </div>
);

export default Event;
