import React from "react";
import { useOptimizerImage } from "../../../utils/useOptimizerImage";

function BackgroundPreview(props) {
  const { images } = props;

  const optimizedImage = useOptimizerImage({
    common: images.mobile,
    retina: images.mobileX2,
  });
  return (
    <div className="user-profile">
      <div className="photo background" style={optimizedImage} />
    </div>
  );
}

export default BackgroundPreview;
