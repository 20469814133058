import { call } from "../core";

export const callAddMeal = meal => {
  return call("/plan", "POST", { body: meal });
};

export const callGetMeals = (start, end) => {
  return call(`/plan`, "GET", { params: { start, end }});
};

export const callRemoveMeal = id => {
  return call(`/plan/${id}`, "DELETE", {});
};

export const callUpdateMeal = (id, body) => {
  return call(`/plan/${id}`, "PUT", { body });
};
