import * as ActionTypes from "./actionTypes";

const initialState = {
  maxPlanQuantity: '',
};

export default function messages(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.SET_MAX_PLAN_QUANTITY:
      return {
        ...state,
        maxPlanQuantity: action.maxPlanQuantity,
      };
    

    default:
      return state;
  }
}
