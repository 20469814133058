import React from "react";
import { useRef } from "react";
import ClickOutside from "react-click-outside";
import closeWhite from "../../assets/images/common/close-white.svg"
import Button from "../Button";

const Modal = (props) => {
  const ref = useRef();
  return (
    <div
      ref={ref}
      className={`
      modal-overlay
      ${props.className}
      ${props.visible ? "active" : ""}
      ${props.loading ? "loading" : ""}
    `}
    >
      <ClickOutside
        onClickOutside={(e) => {
          // e.stopPropagation();
          // e.preventDefault();
          if (e.target !== ref.current) return;
          props.close();
        }}
      >
        <div className="modal">
          {props.withClose && (
            <button className="close-button" onClick={props.close}>
              <img src={closeWhite} alt="Close" />
            </button>
          )}
          <div className={!props.withoutBorder && "body"}>{props.children}</div>
          <div className="modal-buttons">
            {props.buttons.map((b) => (
              <Button key={b.children} {...b} />
            ))}
          </div>
        </div>
      </ClickOutside>
    </div>
  );
};

export default Modal;
