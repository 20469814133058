import React from "react";

const Button = props => (
  <button
    {...props}
    className={`
    button
    ${props.outlined ? "button-outlined" : ""}
    ${props.small ? "button-small" : ""}
    ${props.limited ? "button-limited" : ""}
    ${props.text ? "button-text" : ""}
    ${props.disabled ? "button-disabled" : ""}
    ${props.className}
  `}
  >
    {props.children}
  </button>
);

export default Button;
