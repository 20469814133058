import React from "react";

const Input = (props) => (
  <div className="input-container">
    {props.label && <label htmlFor={props.label}>{props.label}</label>}
    <div className={`wrapper ${props.disabled ? "disabled" : ""}`}>
      {props.prefix && <p className={`prefix`}>{props.prefix}</p>}
      <input
        ref={props.innerRef}
        id={props.label ? props.label : undefined}
        className={`input ${props.postfix ? "with-postfix" : ""} ${props.prefix ? "with-prefix" : ""} ${props
          ?.classNames?.input ?? ""}`}
        {...props}
      />
      {props.postfix && <p className={`postfix ${props.isDropdownVisible ? 'active' : ''}`}>{props.postfix}</p>}
    </div>
    <span className="error">{props.error}</span>
  </div>
);

export default Input;
