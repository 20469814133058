import * as ActionTypes from "./actionTypes";

const initialState = {
  backgrounds: [],
};

export default function messages(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.SET_BACKGROUNDS:
      return {
        ...state,
        backgrounds: action.backgrounds,
      };
    case ActionTypes.REMOVE_BACKGROUND:
      return {
        ...state,
        backgrounds: state.backgrounds.filter((a) => a._id !== action.id),
      };
    case ActionTypes.ADD_BACKGROUND: {
      return {
        ...state,
        backgrounds: [action.background, ...state.backgrounds],
      };
    }
    case ActionTypes.EDIT_BACKGROUND: {
      return {
        ...state,
        backgrounds: state.backgrounds.map((background) => {
          return background._id === action.background._id ? action.background : background;
        }),
      };
    }

    default:
      return state;
  }
}
