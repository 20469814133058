import React, { useEffect, useRef, useState } from "react";
import arrowUp from "../../assets/images/common/arrow-up.svg";
import arrowDown from "../../assets/images/common/arrow-down.svg";


export function PageLimit(props) {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(props.options.find(el => el.value === props.value));
  const selectRef = useRef(null);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleSelectOption = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
    props.onChange(option);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (selectRef.current && !selectRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="page-limit">
      <h5 className="page-limit-title">Page size</h5>
      <div className="page-limit-select">
        {/* <Select {...props} /> */}
        <div className="custom-select" ref={selectRef}>
          <div className={`select-selected ${!selectedOption && 'gray'}`} onClick={toggleDropdown}>
            {selectedOption?.label ?? 'Select option'}
            <img className={`arrow ${isOpen && 'active'}`} src={arrowDown} />
          </div>
          {isOpen && (
            <div className="select-items">
              {props.options.map((option, index) => (
                <div
                  key={option.value}
                  onClick={() => handleSelectOption(option)}
                  className="select-item"
                >
                  {option.label}
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
