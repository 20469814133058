import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { BackgroundsFormModal } from "../../../forms/BackgroundsForm";
import { AddButton } from "./AddButton";
import { TableBackgrounds } from "./TableBackgrounds";

export const BackgroundsTab = () => {
  const [openedAdd, setOpenedAdd] = useState(false);
  const [openedEdit, setOpenedEdit] = useState(false);
  const [editId, setEditId] = useState("");
  const dispatch = useDispatch();

  const addBackgroundButtonHandler = () => {
    setOpenedAdd(true);
  };

  const editBackgroundButtonHandler = (id) => {
    setEditId(id);
    setOpenedEdit(true);
  };

  useEffect(() => {
    dispatch({ type: "GET_BACKGROUNDS" });
  }, [dispatch]);

  return (
    <div className="backgrounds-wrapper">
      <p className="backgrounds-title">Backgrounds</p>
      <AddButton buttonHandler={addBackgroundButtonHandler}>
        + Add background
      </AddButton>
      <TableBackgrounds editQuoteButtonHandler={editBackgroundButtonHandler} />
      {openedAdd && (
        <BackgroundsFormModal
          opened={openedAdd}
          setOpened={setOpenedAdd}
          isEdit={false}
          key={1}
        />
      )}
      {openedEdit && (
        <BackgroundsFormModal
          key={2}
          opened={openedEdit}
          setOpened={setOpenedEdit}
          id={editId}
          isEdit
        />
      )}
    </div>
  );
};
