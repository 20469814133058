import { call } from "../core";

export const callGetAdmin = id => {
  return call(`/admin/${id}`, "GET", {});
};

export const callAddAdmin = admin => {
  return call("/admin", "POST", { body: admin });
};

export const callGetAdmins = () => {
  return call(`/admins/1`, "GET", {});
};

export const callRemoveAdmin = id => {
  return call(`/admin/${id}`, "DELETE", {});
};

export const callUpdateAdmin = (id, body) => {
  return call(`/admin/${id}`, "PUT", { body });
};

export const callResetAdminPassword = (id, password) => {
  return call(`/admin/${id}/password`, "POST", { body: { password } });
};

export const callRequestReset = (email) => {
  return call("/auth/password/reset", "POST", { body: { email } });
};

export const callResetPassword = (token, password) => {
  return call("/auth/password/reset", "PUT", { body: { token, password } });
};
