import * as ActionTypes from "./actionTypes";

const setBackgrounds = (backgrounds) => ({
  type: ActionTypes.SET_BACKGROUNDS,
  backgrounds,
});

const removeBackground = (id) => {
  return {
    type: ActionTypes.REMOVE_BACKGROUND,
    id,
  };
};

const addBackground = (background) => {
  return {
    type: ActionTypes.ADD_BACKGROUND,
    background,
  };
};

const editBackground = (background) => ({
  type: ActionTypes.EDIT_BACKGROUND,
  background,
});

export default {
  setBackgrounds,
  removeBackground,
  addBackground,
  editBackground
};
