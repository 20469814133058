import * as ActionTypes from "./actionTypes";

const setAdmins = admins => ({
  type: ActionTypes.SET_ADMINS,
  admins
});

const removeAdmin = id => ({
  type: ActionTypes.REMOVE_ADMIN,
  id
});

export default {
  setAdmins,
  removeAdmin
};
