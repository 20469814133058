export default state => {
  let hasErrors = false;
  const errors = {};

  if (!state.title || state.title.length < 6) {
    hasErrors = true;
    errors.title = "Title is required (minimum 6 characters)";
  }

  if (!state.start) {
    hasErrors = true;
    errors.start = "Date is required";
  } else {
    if (!/\d{4}-\d{2}-\d{2}/.test(state.start)) {
      hasErrors = true;
      errors.start = "Date is wrong";
    }
  }

  if (!state.location) {
    hasErrors = true;
    errors.location = "Location is required";
  }

  if (!state.info || state.info === "<p><br></p>") {
    hasErrors = true;
    errors.info = "Info is required";
  }

  if (!state.signUpUrl) {
    hasErrors = true;
    errors.signUpUrl = "Signup URL is required";
  } else if (!state.signUpUrl.includes('http')) {
    hasErrors = true;
    errors.signUpUrl = "Signup URL should be valid (contains HTTP)";
  }

  if (!state.callToActionText) {
    hasErrors = true;
    errors.callToActionText = "Call to action Text is required";
  }

  if (hasErrors) {
    return errors;
  }

  return false;
};
