import { useMemo } from "react";
import moment from "moment";

export const useValidateBackgrounds = ({ startDate, endDate, image }) => {
  return useMemo(
    function() {
      const errorEndDate = (startDate === "" && endDate === "") ||
      moment(endDate).isAfter(startDate)
        ? ""
        : "The end date cannot be earlier than the start date."
      return {
        isDisabled:  errorEndDate || !startDate || !endDate || !image,
        requiredImage: !image ? "Image is required" : "",
        requiredStartDate: !startDate ? "Require field" : "",
        requiredEndDate: !endDate ? "Require field" : "",
        errorEndDate,
      };
    },
    [endDate, image, startDate]
  );
};
