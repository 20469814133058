import * as ActionTypes from "./actionTypes";

const initialState = {
  all: []
};

export default function messages(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.SET_RECIPES:
      return {
        ...state,
        all: action.recipes
      };
    default:
      return state;
  }
}
