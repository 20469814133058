import React from "react";
import { Row, Col } from "react-grid-system";

import SuggestInput from "../../../SuggestInput";
import Chip from "../../../../components/Chip";
import { call } from "../../../../api/core";

class SimilarRecipes extends React.Component {
  state = {
    inputValue: "",
    options: [],
    loading: false
  };

  async getSimilarRecipes(search) {
    if (this.timer) {
      return;
    }

    this.timer = true;
    this.setState({
      loading: true
    });

    const options = await call("/recipes/match", "GET", { params: { search } });

    this.setState({
      options: options.data,
      loading: false
    });

    this.timer = false;
  }

  onInputChange = e => {
    this.setState({
      inputValue: e.target.value
    });

    this.getSimilarRecipes(e.target.value);
  };

  addChip = chip => {
    const newChips = [...this.props.chips, chip];
    this.setState({
      inputValue: "",
      options: []
    });

    this.props.onChange(newChips);
  };

  removeChip = index => {
    const copy = [...this.props.chips];
    copy.splice(index, 1);

    this.props.onChange(copy);
  };

  render() {
    return (
      <React.Fragment>
        <h6 className="similar-title">Similar Recipes</h6>
        <Row>
          <Col md={3}>
            <SuggestInput
              loading={this.state.loading}
              options={this.state.options}
              value={this.state.inputValue}
              onChange={this.onInputChange}
              callback={this.addChip}
            />
          </Col>
          <Col md={9}>
            <div className="chip-list">
              {this.props.chips.map((chip, index) => (
                <Chip
                  value={chip.title}
                  onRemove={() => this.removeChip(index)}
                />
              ))}
            </div>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

export default SimilarRecipes;
