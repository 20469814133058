import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Col } from "react-grid-system";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import Button from "../../../components/Button";
import Modal from "../../../components/Modal";
import Input from "../../../forms/Input";

export const MaxNumberPlans = () => {
  const dispatch = useDispatch();

  const maxPlanQuantity = useSelector(
    (state) => state.settings.maxPlanQuantity
  );
  const [localMaxPlanQuantity, setLocalMaxPlanQuantity] = useState();
  const [successModalVisible, setSuccessModalVisible] = useState(false);
  useEffect(() => {
    dispatch({
      type: "GET_MAX_PLAN_QUANTITY",
      onSuccess: () => {
        setLocalMaxPlanQuantity(maxPlanQuantity);
      },
    });
  }, [dispatch, maxPlanQuantity]);

  const confirmHandler = () => {
    dispatch({
      type: "UPDATE_MAX_PLAN_QUANTITY",
      localMaxPlanQuantity,
      onSuccess: () => setSuccessModalVisible(true),
      onError: (error) => {
        toast.error(error.code === 400 ? "Invalid format input" : error.message);
      },
    });
  };

  return (
    <>
      <Col md={8}>
        <Input
          placeholder="Max number of plans"
          onChange={(e) => setLocalMaxPlanQuantity(e.target.value)}
          value={localMaxPlanQuantity}
          type="number"
          label={"Max number of plans"}
          classNames={{input: "number-without-controller"}}
        />
      </Col>
      <Col md={2}>
        <Button className={`button button--mt-2`} onClick={confirmHandler}>
          Save
        </Button>
      </Col>

      <Modal
        visible={successModalVisible}
        close={() => setSuccessModalVisible(false)}
        withoutBorder
        withClose
        loading={false}
        className={{}}
        buttons={[]}
      >
        Max number of plans successfully updated!
      </Modal>
    </>
  );
};
