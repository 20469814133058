/* @flow */
import axios from "axios";
import { BASE_URL } from "../config";
import { store } from "../redux";

axios.interceptors.request.use((config) => {
  const token = store.getState().session.accessToken;
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.data.message) {
      return Promise.reject({
        code: error.response.status,
        message: error.response.data.message,
      });
    } else if (error.response) {
      return Promise.reject({
        code: error.response.status,
        message: `An error has occured (code ${error.response.status})`,
      });
    } else if (error.request) {
      let json = error.toJSON();
      return Promise.reject({
        code: json.code,
        message: json.message,
      });
    } else {
      return Promise.reject({
        code: undefined,
        message: error.message,
      });
    }
  }
);

export const call = (path, method, request, signal) => {
  return callRaw(path, method, {
    ...request,
    headers: {
      Accept: "application/json",
      ...request.headers,
    },
    cancelToken: signal
  }).then((response) => {
    if (response.data) {
      return response.data;
    }
    return response;
  });
};

export const callRaw = (path, method, request) => {
  return axios({
    method,
    url: `${BASE_URL}${path}`,
    headers: request.headers,
    data: request.body,
    params: request.params,
    cancelToken: request.cancelToken
  });
};
export const callBlob = (path, method, request) => {
  return axios({
    method,
    url: `${BASE_URL}${path}`,
    headers: request.headers,
    responseType: "blob",
    params: request.params,
  });
};
export const callFormEncoded = (path, method, request) => {
  return call(path, method, {
    ...request,
    headers: {
      ...request.headers,
      "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
    },
    body: new URLSearchParams({ ...request.body }).toString(),
  });
};
