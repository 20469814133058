import React from "react";

import close from "../../assets/images/common/close-w.svg";

const Chip = props => (
  <div className="chip">
    {props.value}
    <button className="remove-button" onClick={props.onRemove}>
      <img src={close} alt="close" />
    </button>
  </div>
);

export default Chip;
