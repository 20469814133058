import React, { Component } from "react";

import Counter from "../../../components/Counter";
import Tooltip from "../../../components/Tooltip";

import refresh from "../../../assets/images/common/refresh.svg";
import trash from "../../../assets/images/common/trash.svg";
import { useOptimizerImage } from "../../../utils/useOptimizerImage";

class Card extends Component {
  state = {
    portions: null,
  };

  updatePortions = (portions) => {
    this.setState({ portions });
    this.props.onPortionUpdate(portions);
  };


  render() {
    const optimizedImage = useOptimizerImage({
      common: this.props?.images?.square,
      retina: this.props?.images?.squareX2,
    });
    return !this.props.empty ? (
      <div className="card">
        <div className="card-image" style={optimizedImage} />
        <div className="card-body">
          <p className="card-name">{this.props.title}</p>
          <div className="card-buttons">
            <Counter
              defaultValue={this.props.portions}
              value={this.state.portions}
              onChange={this.updatePortions}
              disabled={!this.props.canEdit}
            />
            <Tooltip
              buttons={[
                this.props.canEdit && {
                  icon: refresh,
                  title: "Swap Out",
                  onClick: this.props.onSwapClick,
                },
                this.props.canDelete && {
                  icon: trash,
                  title: "Delete",
                  onClick: this.props.onDeteleClick,
                },
              ]}
            />
          </div>
        </div>
      </div>
    ) : (
      <div className="card card--empty">
        <button
          className={`card-button ${
            !this.props.canCreate ? "button-disabled" : ""
          }`}
          onClick={this.props.onClick}
        >
          {this.props.canCreate ? "+ Add recipe" : "Unable to add a recipe"}
        </button>
      </div>
    );
  }
}

export default Card;
