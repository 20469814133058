import React from "react";
import moment from "moment";

import Rating from "../../../../components/Rating";
import Tooltip from "../../../../components/Tooltip";

const Review = props => (
  <div className="review">
    <div className="info-container">
      <p className="info">
        <span>
          {props.user && props.user.firstName}{" "}
          {props.user && props.user.lastName}
        </span>{" "}
        for <span>{props.recipe && props.recipe.title}</span>
      </p>
      <div className="extra">
        <Rating value={props.rating} />
        <Tooltip buttons={props.tooltipButtons} />
      </div>
    </div>
    <p className="message">{props.review}</p>
    <p className="date">{moment(props.date).format("LLL")}</p>
  </div>
);

export default Review;
