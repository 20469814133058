import React from "react";

import star from "../../assets/images/common/star.svg";
import starEmpty from "../../assets/images/common/star-empty.svg";

const Rating = props => {
  const flooredValue = Math.floor(props.value);
  const stars = [];

  for (let i = 1; i <= 5; i++) {
    if (i <= flooredValue) {
      stars.push(<img key={i} src={star} alt="star" />);
    } else {
      stars.push(<img key={i} src={starEmpty} alt="star" />);
    }
  }

  return <div className="rating">{stars}</div>;
};

export default Rating;
