import React, { useState } from 'react';
import { Col, Row } from "react-grid-system"
import Layout from "../../components/Layout"
import PageHeader from "../../components/PageHeader"
import Input from '../../forms/Input';
import Button from '../../components/Button';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import { connect } from 'react-redux';
import { isValidEmail } from '../../utils/helpers';
import check from "../../assets/images/common/check-w.svg";

const ForgotPassword = (props) => {

  const [state, setCurrentState] = useState({
    email: "",
    errors: {},
    success: false,
  });

  const setState = (newState) => setCurrentState((prevState) => ({ ...prevState, ...newState }));

  const updateField = (e, field) => {
    setState({
      [field]: e.currentTarget.value,
      errors: {},
    });
  };

  const validateFields = () => {
    const isEmailValid = isValidEmail(state.email);

    if (isEmailValid) return true;

    setState({ errors: { email: !isEmailValid ? "Email is invalid" : null }});
  };

  const onSuccess = () => setState({ success: true, });

  const resetRequest = () => {
    if (validateFields()) props.sendResetLink(state.email, onSuccess);
  };

  let actionButtonText = props.loading ? "Resetting..." : "Reset Password";
  if (state.success) actionButtonText = "Sent";

  return (<Layout disableSidebar>
    <Row justify="center">
      <Col md={6} lg={4}>
        <div className="login-form">
          <PageHeader title="Forgot password" />
          <Input
          disabled={props.loading}
          error={state.errors.email}
          label="Email"
          placeholder="Your Email"
          type="email"
          value={state.email}
          onChange={(e) => updateField(e, "email")}
        />
          <p style={{ alignSelf: 'flex-start' }}>
            Back to <Link className='forgot-password-link' to="/login">Log In</Link>
          </p>
        <Button
          className="action-button"
          disabled={props.loading || state.success}
          primary={state.success}
          limited
          onClick={resetRequest}
        >
          {state.success && <img src={check} alt="Sent" />}
          {actionButtonText}
        </Button>
        <p className="error">{props.error}</p>
        </div>
      </Col>
    </Row>
  </Layout>)
}

const mapStateToProps = (state) => ({
  loading: state.loading["POST_RESET"],
  error: state.error["POST_RESET"],
});

const mapDispatchToProps = (dispatch) => ({
  sendResetLink: (email, onSuccess) =>
    dispatch({ type: "REQUEST_RESET", email, onSuccess }),
});

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
