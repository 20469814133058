import React from "react";

const Status = (props) => {
  return (
    <div className={`status ${props.color ? "status-" + props.color : ""} ${props.className}`}>
      {props.children}
    </div>
  );
};

export default Status;
