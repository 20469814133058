import React from "react";
import { Row, Col } from "react-grid-system";
import { connect } from "react-redux";
import { withRouter } from "react-router";

import { call } from "../../api/core";
import Layout from "../../components/Layout";
import PageHeader from "../../components/PageHeader";
import Input from "../Input";
import Button from "../../components/Button";
import ImageUpload from "../../components/ImageUpload";
import AddInfo from "../AddInfo";
import PDFUploader from "./components/PDFUploader";
import validate from "./validation";
import { getUpdatedBody } from "../../utils/helpers";
import withPermissions from "../../utils/PermissionManager";
import NoPermissions from "../../components/NoPermissions";

class AddArticle extends React.Component {
  state = {
    title: "",
    duration: '',
    durationUnit: '',
    pdf: "",
    photo: "",
    content: "",
    changedFields: [],
    errors: null,
    priority:"",
    busy:false
  };

  async componentDidMount() {
    if (this.props.editing) {
      const response = await call(
        `/article/${this.props.match.params.id}`,
        "GET",
        {}
      );

      this.setState({
        ...response.data,
        duration: response.data.duration.split(' ')[0],
        durationUnit: response.data.duration.split(' ')[1],
      });
    }
  }

  changeField = (field, value) => {
    const changedFields = [...this.state.changedFields];
    if(field==="priority"&&value!==""&&(!/^[0-9]+$/.test(value)||parseInt(value)<=0)) {
      return;
    }
    if (changedFields.indexOf(field) === -1) {
      changedFields.push(field);
    }

    this.setState({
      [field]: value,
      changedFields
    });
  };

  onSuccess = () => {
    this.props.history.push("/wellness");
  };

  onSubmit = () => {
    const errors = validate(this.state);

    if (errors) {
      this.setState({ errors });
      return;
    } else {
      this.setState({ errors: null });
    }

    if (this.props.editing) {
      const updatedBody = getUpdatedBody(this.state.changedFields, this.state);

      if (updatedBody.duration || updatedBody.durationUnit) {
        updatedBody.duration = `${this.state.duration} ${this.state.durationUnit}`;
      }

      this.props.updateArticle(
        this.props.match.params.id,
        updatedBody,
        this.onSuccess
      );
    } else {
      this.props.addArticle(
        {
          title: this.state.title,
          duration: `${this.state.duration} ${this.state.durationUnit}`,
          pdf: this.state.pdf,
          photo: this.state.photo,
          content: this.state.content,
          season: this.props.location.state && this.props.location.state.season,
          priority:this.state.priority||99999,
        },
        this.onSuccess
      );
    }
  };

  uploadStarted=()=>{
    this.setState({busy:true});
  }

  uploadFinished=()=>{
    this.setState({busy:false});
  }

  render() {
    const buttonText = this.props.editing ? "Update" : "Publish";
    const loading = this.props.adding || this.props.updating;

    const canRead = this.props.can("read", "article");
    const canEdit = this.props.can("edit", "article");
    const canCreate = this.props.can("create", "article");

    const editingMode = canRead && canEdit && this.props.editing;
    const creatingMode = canCreate && !this.props.editing;

    return (
      <Layout>
        <PageHeader
          title="Add Article"
          breadcrumbs="Back to Wellness"
          breadcrumbsLink="/wellness"
        />
        {editingMode || creatingMode ? (
          <>
            <Row>
              <Col md={6}>
                <ImageUpload
                  onUploadStart={this.uploadStarted}
                  onUploadEnd={this.uploadFinished}
                  disableUpload={!this.props.can("create", "file")}
                  value={this.state.photo}
                  onChange={value => this.changeField("photo", value)}
                />
              </Col>
              <Col md={6}>
                <Input
                  label="Title"
                  placeholder="Type here"
                  value={this.state.title}
                  onChange={e => this.changeField("title", e.target.value)}
                  error={this.state.errors && this.state.errors.title}
                />
                <Row>
                  <Col md={6}>
                    <Input
                      label="Duration"
                      placeholder="1"
                      value={this.state.duration}
                      onChange={e => this.changeField("duration", e.target.value)}
                      error={this.state.errors && this.state.errors.duration}
                      type="number"
                    />
                  </Col>
                  <Col md={6}>
                    <Input
                      label="Duration Unit"
                      placeholder="day"
                      value={this.state.durationUnit}
                      onChange={e => this.changeField("durationUnit", e.target.value)}
                      error={this.state.errors && this.state.errors.duration}
                    />
                  </Col>
                </Row>
                <Input
                  label="Sorting Priority"
                  placeholder="1 = Top Priority"
                  value={this.state.priority}
                  onChange={e => this.changeField("priority", e.target.value)}
                  error={this.state.errors && this.state.errors.priority}
                />
                <PDFUploader
                  onUploadStart={this.uploadStarted}
                  onUploadEnd={this.uploadFinished}
                  disableUpload={!this.props.can("create", "file")}
                  value={this.state.pdf}
                  onChange={value => this.changeField("pdf", value)}
                />
              </Col>
            </Row>
            <AddInfo
              value={this.state.content}
              onChange={value => this.changeField("content", value)}
              error={this.state.errors && this.state.errors.content}
            />
            
            <Button disabled={this.state.busy} limited onClick={this.onSubmit}>
              {buttonText + ((loading||this.state.busy) ? "..." : "")}
            </Button>
            <p className="error">
              {this.state.errors
                ? "Fill all requirement fields and try again"
                : ""}
            </p>
          </>
        ) : (
          <NoPermissions />
        )}
      </Layout>
    );
  }
}

const mapStateToProps = state => ({
  adding: state.loading["ADD_ARTICLE"],
  updating: state.loading["UPDATE_ARTICLE"]
});

const mapDispatchToProps = dispatch => ({
  addArticle: (article, onSuccess) =>
    dispatch({ type: "ADD_ARTICLE", article, onSuccess }),
  updateArticle: (id, body, onSuccess) =>
    dispatch({ type: "UPDATE_ARTICLE", id, body, onSuccess })
});

export default withPermissions(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(AddArticle))
);
