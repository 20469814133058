import * as ActionTypes from "./actionTypes";

const setUsers = users => ({
  type: ActionTypes.SET_USERS,
  users
});

const removeUser = id => ({
  type: ActionTypes.REMOVE_USER,
  id
});

const restoreUser = id => ({
  type: ActionTypes.RESTORE_USER,
  id
});

export default {
  setUsers,
  removeUser,
  restoreUser
};
