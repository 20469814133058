import React from "react";
import ClickOutside from "react-click-outside";

import Input from "../Input";

import loader from "../../assets/images/common/loader-web.svg";

class SuggestInput extends React.Component {
  state = {
    isMenuActive: false
  };

  onFocus = () => {
    this.setState({
      isMenuActive: true
    });
  };

  onClickOutside = () => {
    this.setState({
      isMenuActive: false
    });
  };

  selectOption = option => {
    if (this.props.callback) {
      this.props.callback(option);
    }

    this.setState({
      isMenuActive: false
    });
  };

  render() {
    return (
      <ClickOutside onClickOutside={this.onClickOutside}>
        <div className={`suggest-input ${this.props.loading ? "loading" : ""}`}>
          <Input
            value={this.props.value}
            onChange={this.props.onChange}
            onFocus={this.onFocus}
            placeholder="Start typing"
            innerRef={this.props.innerRef}
          />
          <img src={loader} alt="loader" />
          <div className={`options ${this.state.isMenuActive ? "active" : ""}`}>
            {this.props.options.map((option, i) => (
              <div key={i} className="option" onClick={() => this.selectOption(option)}>
                {option.title}
              </div>
            ))}
          </div>
        </div>
      </ClickOutside>
    );
  }
}

export default SuggestInput;
