import React from 'react';
import cx from 'classnames';
import { isMobile } from 'mobile-device-detect';

import closeWhite from '../../../assets/images/common/close_btn.svg';
import ModalLayout from "./ModalLayout"
import Button from '../../Button';

const FullScreenModal = (props) => (
  <ModalLayout>
    <div ref={props.innerRef} className={cx('fullscreen-modal', props.className, { visible: props.visible })}>
      {isMobile && props.visible && (
        <style>
          {`body {
            overflow: hidden;
          }`}
        </style>
      )}
      <div className={`scrollable-layer ${props.layer}`}>
        <div className={`content ${props.content}`}>
          <div className={cx("button-container", { 'with-action': props.mobileButtonVisible })}>
            <button
              className="close-button"
              onClick={props.actionButton}
            >
              <img src={closeWhite} alt="Close"/>
            </button>
            <Button link className="mobile-action" onClick={props.mobileButtonAction}>
              {props.mobileButton}
            </Button>
          </div>
          {props.children}
        </div>
      </div>
    </div>
  </ModalLayout>
);

export default FullScreenModal;
