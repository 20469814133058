import React from "react";
import { Row, Col } from "react-grid-system";
import { Link } from "react-router-dom";

import Button from "../../../../components/Button";

const TabContent = props => (
  <div className="tab-content">
    <div className="button-wrapper">
      <Link
        to={props.link}
        className={`button ${!props.canCreate ? "button-disabled" : ""}`}
      >
        {props.buttonText}
      </Link>
    </div>
    <Row>
      {props.data.map(data => (
        <Col md={4}>
          {props.component({
            ...data,
            canEdit: props.canEdit,
            onEditClick: props.onEditClick,
            canDelete: props.canDelete,
            onDeleteClick: props.onDeleteClick
          })}
        </Col>
      ))}
    </Row>
  </div>
);

export default TabContent;
