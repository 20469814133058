import * as ActionTypes from "./actionTypes";

const initialState = {
  allergens: [],
  types: [],
  seasons: [],
  forms: [],
  units: [],
  categories: [],
  permissions: [],
  ingredients: [],
};

export default function messages(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.SET_ENUMS:
      return {
        ...state,
        ...action.data
      };
    default:
      return state;
  }
}
