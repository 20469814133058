import * as ActionTypes from "./actionTypes";

const initialState = {
  all: []
};

export default function messages(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.SET_MEALS:
      return {
        ...state,
        all: action.meals,
      };
    case ActionTypes.ADD_MEAL:
      return {
        ...state,
        all: [...state.all, action.meal]
      };
    case ActionTypes.DELETE_MEAL:
      return {
        ...state,
        all: state.all.filter(m => m._id !== action.id)
      };
    case ActionTypes.UPDATE_MEAL:
      const copy = [...state.all];
      const mealIndex = copy.findIndex(m => m._id === action.meal._id);
      const savedMeal = copy[mealIndex];
      const newMeal = { ...savedMeal, ...action.meal, date: savedMeal.date };

      copy.splice(mealIndex, 1, newMeal);

      return {
        ...state,
        all: copy
      };
    default:
      return state;
  }
}
