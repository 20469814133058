import * as ActionTypes from "./actionTypes";

const setEvents = events => ({
  type: ActionTypes.SET_EVENTS,
  events
});

const removeEvent = id => ({
  type: ActionTypes.REMOVE_EVENT,
  id
});

export default {
  setEvents,
  removeEvent
};
