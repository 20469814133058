import React from "react";
import { useEffect } from "react";
import { useMemo } from "react";
import { useCallback } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { callGetBackgroundById } from "../../api/backgrounds";
import { callUploadFile } from "../../api/files";
import Modal from "../../components/Modal";
import { UploadImage } from "../../components/UploadImage";
import {
  transformDateBackToFront,
  transformDateFrontToBack,
} from "../../utils/helpers";
import { useOptimizerImage } from "../../utils/useOptimizerImage";
import Input from "../Input";
import { useValidateBackgrounds } from "./use-validate-backgrounds";

export const BackgroundsFormModal = (props) => {
  const { opened, setOpened, id, isEdit = false } = props;

  const background = useSelector((state) =>
    state.backgrounds.backgrounds.find((background) => id === background._id)
  );

  const [imageUrl, setImageUrl] = useState("");
  const [images, setImages] = useState({});
  const [imageFile, setImageFile] = useState(null);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();

  const clearForm = () => {
    setImageUrl("");
    setImages({});
    setImageFile(null);
    setEndDate("");
    setStartDate("");
  };

  useEffect(() => {
    if (!isEdit) {
      clearForm();
    } else {
      setImages(background?.images);
      setImageUrl("");
      setEndDate(transformDateBackToFront(background?.endDate));
      setStartDate(transformDateBackToFront(background?.startDate));
    }
  }, [background, isEdit]);

  const confirmHandler = async () => {
    let imageKey;
    if (imageFile) {
      try {
        setIsLoading(true);
        const resp = await callUploadFile(imageFile);

        imageKey = resp?.data?.tmpFile;
      } catch (error) {
        setIsLoading(false);
        toast.error(error.message);
      }
    }
    const successHandler = () => {
      setIsLoading(false);
      // clearForm();

      setOpened(false);
    };
    const errorHandler = () => {
      setIsLoading(false);
    };
    if (!isEdit) {
      dispatch({
        type: "ADD_BACKGROUND",
        background: {
          imageKey,
          startDate: transformDateFrontToBack(startDate),
          endDate: transformDateFrontToBack(endDate),
        },
        onSuccess: successHandler,
        onError: errorHandler,
      });
    } else {
      dispatch({
        type: "UPDATE_BACKGROUND",
        background: {
          _id: id,
          imageKey,
          startDate: transformDateFrontToBack(startDate),
          endDate: transformDateFrontToBack(endDate),
        },
        onSuccess: successHandler,
        onError: errorHandler,
      });
    }
  };

  const closeHandler = () => {
    !isEdit && clearForm();
    setOpened(false);
  };

  const optimizedImage = useOptimizerImage({
    common: images?.mobile,
    retina: images?.mobileX2,
  });

  const imagePreview = useMemo(() => {
    if (imageUrl) return { backgroundImage: `url("${imageUrl}")` };
    if (Object.keys(images).length > 0) return optimizedImage;
    return;
  }, [imageUrl, images, optimizedImage]);

  const {
    errorEndDate,
    requiredEndDate,
    requiredStartDate,
    requiredImage, isDisabled
  } = useValidateBackgrounds({
    startDate,
    endDate,
    image: imagePreview,
  });
  return (
    <Modal
      className="recipes-modal"
      withoutBorder
      visible
      isLoading={isLoading}
      close={closeHandler}
      buttons={[
        {
          children: "Cancel",
          disabled: false,
          outlined: true,
          small: false,
          onClick: closeHandler,
        },
        {
          children: "Save",
          disabled: isDisabled,
          outlined: false,
          small: false,
          onClick: confirmHandler,
        },
      ]}
    >
      <h3 className="quotes-title">{isEdit ? "Edit background" : "Add background"}</h3>
      <UploadImage
        onChangeImageUrl={setImageUrl}
        onChangeImageFile={setImageFile}
        onChangeImages={setImages}
        isEmpty={!imageUrl && !imageFile && !Object.keys(images).length > 0}
        isLoading={isLoading}
        imageValue={imagePreview}
        error={requiredImage}
      />
      <Input
        label="Start date"
        placeholder="Type here"
        type="date"
        value={startDate}
        onChange={(e) => {
          setStartDate(e.target.value);
        }}
        error={requiredStartDate}
      />
      <Input
        label="End date"
        placeholder="Type here"
        type="date"
        value={endDate}
        onChange={(e) => {
          setEndDate(e.target.value);
        }}
        error={ requiredEndDate || errorEndDate }
      />
    </Modal>
  );
};
