import React, { useState, useEffect } from "react";

import info from "../../../assets/images/common/info-w.svg";
import star from "../../../assets/images/common/star-b.svg";
import clock from "../../../assets/images/common/clock.svg";
import { useOptimizerImage } from "../../../utils/useOptimizeImage";

const RecipeCard = (props) => {
  const optimizedImageLink = useOptimizerImage({
    common: props?.images?.square,
    retina: props?.images?.squareX2
  });

  return (
    <div className="recipe-card-new" onClick={props.onClick}>
      <div className="image" style={optimizedImageLink}>
        {!props.withTooltip && (
          <button className="info-button" onClick={props.onInfoClick}>
            <img src={info} alt="Info" />
          </button>
        )}
      </div>
      <div className="info-container">
        <div className="info">
          <p className="title">{props.title}</p>
        </div>
        <div className="statistics">
          <div className="element">
            <img src={clock} alt="Star" />
            <p className="text-muted">{props.time} MIN</p>
          </div>
          <div className="element">
            <img src={star} alt="Star" />
            <p className="text-muted">{props.rating} ({props.reviewsAmount})</p>
          </div>
          {!props.withTooltip && (
            <button className="info-button" onClick={props.onInfoClick}>
              <img src={info} alt="Info" />
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default RecipeCard;
