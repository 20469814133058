import * as ActionTypes from "./actionTypes";

const initialState = {
  id: null,
  name: null
};

export default function messages(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.SET_USER:
      return {
        ...state,
        id: action.id,
        name: action.name
      };
    case ActionTypes.SET_PERMISSIONS:
      return {
        ...state,
        permissions: action.permissions
      };
    case ActionTypes.CLEAR_USER:
      return {
        ...initialState
      };
    default:
      return state;
  }
}
