import React from "react";

import tick from "../../assets/images/common/tick.svg";

class Checkbox extends React.Component {
  state = { active: false };

  toggle = () => {
    this.props.onChange();
    this.setState({
      active: !this.state.active
    });
  };

  render() {
    const active =
      this.props.value !== undefined ? this.props.value : this.state.active;

    return (
      <div
        className={`checkbox ${active ? "active" : ""}`}
        onClick={this.toggle}
      >
        <div className="box">
          <img className="checkbox-img" src={tick} alt="check" />
        </div>
        {this.props.name}
      </div>
    );
  }
}

export default Checkbox;
