import { call } from "../core";

export const callCreateAACPPlan = plan => {
  return call("/aacp/plan", "POST", { body: plan });
};

export const callgetAACPPlans = (slug) => {
  return call(`/aacp/plan?nameSlug=${slug ?? ''}`, "GET", {});
};

export const callDeleteAACPPlans = (id) => {
  return call(`/aacp/plan/${id}`, "DELETE", {});
};

export const callUpdateAACPPlan = (id, mealPlans, name = null, isPlanActive) => {
  return call(`/aacp/plan/${id}`, "PUT", { body: { mealPlans, name, isPlanActive } });
};

export const callDeleteAACPMeal = (id, mealId) => {
  return call(`/aacp/plan/${id}/meal/${mealId}`, "DELETE", {});
};

export const callUpdateAACPMeal = (id, mealId, mealPlan) => {
  return call(`/aacp/plan/${id}/meal/${mealId}`, "PUT", { body: { mealPlan } });
};