import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";

import Layout from "../../components/Layout";
import PageHeader from "../../components/PageHeader";
import Column from "./components/Column";

import Modal from "../../components/Modal";
import SuggestInput from "../../forms/SuggestInput";
import { call } from "../../api/core";
import withPermissions from "../../utils/PermissionManager";
import Button from "../../components/Button";
import { FRONTEND_BASE_URL } from "../../config";
import PreviewCard from "./components/AACP/PreviewCard";
import { withRouter } from "react-router-dom/cjs/react-router-dom";
import Input from "../../forms/Input";
import AddRecipeModal from "../../components/AddRecipeModal";

class Plan extends Component {
  state = {
    modalVisible: false,
    options: [],
    loading: false,
    start: null,
    end: null,
    isPreviousWeek: false,
    adding: false,
    addingDate: null,
    addingCategory: null,
    swappingMeal: null,
    currentTab: 0,
    addPlanModalVisible: false,
    newPlanName: '',
    idToDelete: null,
    selectedPlanId: null,
  };

  componentDidMount() {
    const start = moment().startOf("isoWeek");
    const end = moment().endOf("isoWeek");

    this.setState({
      start,
      end,
    });

    this.props.getMeals(start.format("MM/DD/YYYY"), end.format("MM/DD/YYYY"));
    this.props.getAACPPlans();
  }

  getTransformedDate = () => {
    if (this.state.start && this.state.end) {
      let start = this.state.start.format("MMM D");
      let end = this.state.end.format("D");

      if (this.state.start.format("MMM") !== this.state.end.format("MMM")) {
        start = this.state.start.format("MMM D ");
        end = this.state.end.format(" MMM D");
      }

      return `${start}-${end}`;
    }
  };

  getUrlDate = () => {
    if (this.state.start && this.state.end) {
      let start = this.state.start.format("MMM-D-YY");

      return `${start}`;
    }
  };

  getTransformedData = () => {
    const days = {};

    this.props.meals.forEach((meal) => {
      const date = moment(meal.date).toString();

      if (days[date]) {
        days[date].push(meal);
      } else {
        days[date] = [meal];
      }
    });

    return days;
  };

  openModal = () => {
    this.setState({
      modalVisible: true,
    });
  };

  closeModal = () => {
    this.setState({
      modalVisible: false,
      adding: false,
      addingDate: null,
      addingCategory: null,
      swappingMeal: null,
      options: [],
      loading: false,
      addPlanModalVisible: false,
      deleteAACPPLanModal: false,
      newPlanName: '',
      idToDelete: null,
      selectedPlanId: null
    });

    if (this.searchInput) {
      this.searchInput.value = "";
    }
  };

  async getSimilarRecipes(search) {
    if (this.timer) {
      return;
    }

    this.timer = true;
    this.setState({
      loading: true,
    });

    const options = await call("/recipes/match", "GET", { params: { search } });

    this.setState({
      options: options.data,
      loading: false,
    });

    this.timer = false;
  }

  onInputChange = (e) => {
    this.getSimilarRecipes(e.target.value);
  };

  onInputSubmit = (recipe) => {
    if (this.state.adding) {
      const body = {
        portions: 1,
        recipe: recipe._id,
        date: moment(this.state.addingDate).format("MM/DD/YYYY"),
        category: this.state.addingCategory,
      };

      this.props.addMeal(body);
    } else {
      const oldMeal = this.props.meals.find(
        (m) => m._id === this.state.swappingMeal
      );

      const body = {
        recipe: recipe._id,
        portions: oldMeal.portions,
        date: oldMeal.date,
      };

      this.props.updateMeal(this.state.swappingMeal, body);
    }

    this.closeModal();
  };

  addMeal = (date, category) => {
    this.openModal();
    this.setState({
      adding: true,
      addingDate: date,
      addingCategory: category,
    });
  };

  swapMeal = (id) => {
    this.openModal();
    this.setState({
      swappingMeal: id,
    });
  };

  changeWeek = (action, shouldFetch = true) => {
    const start = moment(this.state.start)[action](7, "day");
    const end = moment(this.state.end)[action](7, "day");
    const currentStart = moment().startOf("isoWeek");

    this.setState({
      start,
      end,
      isPreviousWeek: currentStart > start,
    });

    shouldFetch && this.props.getMeals(start.format("MM/DD/YYYY"), end.format("MM/DD/YYYY"));
  };

  changeTab = (index) => () => {
    this.setState({
      currentTab: index
    })
  }

  goToPage(id) {
    this.props.history.push(`/plan/${id}`);
  }

  createAACPPlan() {
    if (this.state.adding) {
      this.props.createAACPPlan({
        name: this.state.newPlanName,
        mealPlans: [],
      },
      this.props.getAACPPlans);
    } else {
      this.props.updateAACPPlan(
        this.state.selectedPlanId,
        this.state.newPlanName,
        this.props.getAACPPlans()
      )
    }
    this.closeModal();
  }

  deleteAACPPlan() {
    this.props.deleteAACPPlan(this.state.idToDelete, this.closeModal.bind(this))
  }

  render() {
    const data = this.getTransformedData();
    const week = new Array(7).fill().map((_, i) =>
      moment(this.state.start)
        .add(i, "d")
        .startOf("d")
        .toString()
    );
    const url = `${FRONTEND_BASE_URL}/plans/${this.getUrlDate()}`;

    return (
      <Layout>
        <div className="plan-tabs">
          <button onClick={this.changeTab(0)} className={`tab-button ${this.state.currentTab === 0 && 'active'}`}>Week plan</button>
          <button onClick={this.changeTab(1)} className={`tab-button ${this.state.currentTab === 1 && 'active'}`}>AACP plan</button>
        </div>
        {
          this.state.currentTab === 0 ? (
          <>
            <PageHeader
              url={url}
              title={this.getTransformedDate()}
            />
            <div className="meal-button">
              <Button small outlined onClick={() => this.changeWeek("subtract")}>
                Past week
              </Button>
              <Button small outlined onClick={() => this.changeWeek("add")}>
                Next week
              </Button>
            </div>
            <div
              className={`plan-columns ${
                this.props.loading ? "meals-loading" : ""
              }`}
            >
              {week.map((date, index) => (
                <Column
                  data={data[date] || []}
                  updateMeal={this.props.updateMeal}
                  swapMeal={this.swapMeal}
                  addMeal={this.addMeal}
                  removeMeal={this.props.deleteMeal}
                  date={date}
                  index={index}
                  canEdit={this.props.can("edit", "plan")}
                  canCreate={this.props.can("create", "plan")}
                  canDelete={this.props.can("delete", "plan")}
                />
              ))}
            </div>
          </>) : (
          <>
            <div className="aacp-header">
              <PageHeader />
              <Button small={true} onClick={() => this.setState({ addPlanModalVisible: true, adding: true })}>Add plan</Button>
            </div>
            <div
              className={`plan-columns ${
                this.props.loading ? "meals-loading" : ""
              } aacp`}
            >
              {this.props.aacpPlans.map(plan => (
                <PreviewCard
                  key={plan._id}
                  label={plan.nameSlug}
                  name={plan.name}
                  id={plan._id}
                  onClick={this.goToPage.bind(this, plan.nameSlug)}
                  openRenameModal={(e, name, id) => {
                    e.stopPropagation();
                    this.setState({ addPlanModalVisible: true, newPlanName: name, selectedPlanId: id });
                  }}
                  openDeleteModal={(e, id) => {
                    e.stopPropagation();
                    this.setState({ deleteAACPPLanModal: true, idToDelete: id });
                  }}
                  isPlanActive={plan.isPlanActive}
                  images={plan.mealPlans[0]?.images}
                />
              ))}
            </div>
          </>)
        }
        
        {/* <Modal
          visible={this.state.modalVisible}
          buttons={[
            { onClick: this.closeModal, children: "Close", small: true },
          ]}
        >
          <h6 className="page-section">Select Recipe</h6>
          <SuggestInput
            innerRef={(ref) => (this.searchInput = ref)}
            onChange={this.onInputChange}
            options={this.state.options}
            callback={this.onInputSubmit}
          />
        </Modal> */}
        <Modal
          visible={this.state.addPlanModalVisible}
          buttons={[
            { onClick: this.closeModal, children: "Close" },
            { onClick: this.createAACPPlan.bind(this), children: "Save" },
          ]}
        >
          <h6 className="page-section">Plan name</h6>
          <Input value={this.state.newPlanName} onChange={(e) => this.setState({ newPlanName: e.target.value })} />
        </Modal>
        <AddRecipeModal
          visible={this.state.modalVisible}
          close={this.closeModal}
          onInfoClick={(id) => window.open(`/recipes/edit-recipe/${id}`)}
          onRecipeClick={this.onInputSubmit}
        />
        <Modal
          visible={this.state.deleteAACPPLanModal}
          buttons={[
            { onClick: this.closeModal, children: "Close" },
            { onClick: this.deleteAACPPlan.bind(this), children: "Delete" },
          ]}
        >
          <h6 className="page-section">Are you sure you want to delete plan ?</h6>
        </Modal>
      </Layout>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: state.loading["GET_MEALS"],
  meals: state.meal.all,
  aacpPlans: state.aacpPlans.all
});

const mapDispatchToProps = (dispatch) => ({
  addMeal: (meal) => dispatch({ type: "ADD_MEAL", meal }),
  getMeals: (start, end) => dispatch({ type: "GET_MEALS", start, end }),
  updateMeal: (id, body, ignoreUpdating) =>
    dispatch({ type: "UPDATE_MEAL", id, body, ignoreUpdating }),
  deleteMeal: (id) => dispatch({ type: "DELETE_MEAL", id }),
  createAACPPlan: (plan, onSuccess) => dispatch({ type: 'CREATE_AACP_PLAN', plan, onSuccess }),
  getAACPPlans: () => dispatch({ type: 'GET_AACP_PLANS' }),
  deleteAACPPlan: (id, onSuccess) => dispatch({ type: 'DELETE_AACP_PLAN', id, onSuccess }),
  updateAACPPlan: (id, name, onSuccess) => dispatch({ type: 'UPDATE_AACP_PLAN', id, name, onSuccess})
});

export default withPermissions(
  connect(mapStateToProps, mapDispatchToProps)(withRouter(Plan))
);
