import * as ActionTypes from "./actionTypes";

const setEnums = data => ({
  type: ActionTypes.SET_ENUMS,
  data
});

export default {
  setEnums
};
