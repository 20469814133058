import { call, put, takeLatest } from "redux-saga/effects";
import EventsActions from "../../redux/event/actions";
import {
  callAddEvent,
  callGetEvents,
  callRemoveEvent,
  callUpdateEvent
} from "../../api/event";

export function* getEvents(action) {
  try {
    yield put({ type: "GET_EVENTS_REQUEST" });

    const response = yield call(callGetEvents);
    yield put(EventsActions.setEvents(response.data));

    yield put({ type: "GET_EVENTS_SUCCESS" });
  } catch (error) {
    yield put({
      type: "GET_EVENTS_FAILURE",
      error: error.message
    });
  }
}

export function* addEvent(action) {
  try {
    yield put({ type: "ADD_EVENT_REQUEST" });

    const response = yield call(callAddEvent, action.event);
    action.onSuccess();

    yield put({ type: "ADD_EVENT_SUCCESS" });
  } catch (error) {
    yield put({
      type: "ADD_EVENT_FAILURE",
      error: error.message
    });
  }
}

export function* updateEvent(action) {
  try {
    yield put({ type: "UPDATE_EVENT_REQUEST" });

    const response = yield call(callUpdateEvent, action.id, action.body);
    action.onSuccess();

    yield put({ type: "UPDATE_EVENT_SUCCESS" });
  } catch (error) {
    yield put({
      type: "UPDATE_EVENT_FAILURE",
      error: error.message
    });
  }
}

export function* removeEvent(action) {
  try {
    yield put({ type: "REMOVE_EVENT_REQUEST" });

    const response = yield call(callRemoveEvent, action.id);
    yield put(EventsActions.removeEvent(action.id));

    yield put({ type: "REMOVE_EVENT_SUCCESS" });
  } catch (error) {
    yield put({
      type: "REMOVE_EVENT_FAILURE",
      error: error.message
    });
  }
}

const eventsSaga = [
  takeLatest("GET_EVENTS", getEvents),
  takeLatest("ADD_EVENT", addEvent),
  takeLatest("UPDATE_EVENT", updateEvent),
  takeLatest("REMOVE_EVENT", removeEvent)
];

export default eventsSaga;
