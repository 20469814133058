import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { QuotesFormModal } from "../../../forms/QuotesForm";
import { AddButton } from "./AddButton";
import { TableQuotes } from "./TableQuotes";

export const QuotesTab = () => {
  const [openedAdd, setOpenedAdd] = useState(false);
  const [openedEdit, setOpenedEdit] = useState(false);
  const [editId, setEditId] = useState("");
  const dispatch = useDispatch();

  const addQuoteButtonHandler = () => {
    setOpenedAdd(true);
  };

  const editQuoteButtonHandler = (id) => {
    setEditId(id);
    setOpenedEdit(true);
  };

  useEffect(() => {
    dispatch({ type: "GET_QUOTES" });
  }, []);

  return (
    <div>
      <AddButton buttonHandler={addQuoteButtonHandler}>+ Add quote</AddButton>
      <TableQuotes editQuoteButtonHandler={editQuoteButtonHandler} />
      <QuotesFormModal opened={openedAdd} setOpened={setOpenedAdd} />
      <QuotesFormModal
        opened={openedEdit}
        setOpened={setOpenedEdit}
        id={editId}
        isEdit
      />
    </div>
  );
};
