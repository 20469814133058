import React from "react";
import { Row, Col } from "react-grid-system";
import { connect } from "react-redux";
import ReactPaginate from "react-paginate";

import Layout from "../../components/Layout";
import PageHeader from "../../components/PageHeader";
import Review from "./components/Review";

import withPermissions from "../../utils/PermissionManager";
import deleteIcon from "../../assets/images/common/trash.svg";
import arrowBack from "../../assets/images/common/arrow-back.svg";
import arrowNext from "../../assets/images/common/arrow-next.svg";

class Reviews extends React.Component {
  state = {
    pageCount: 0,
    savedPage: 1
  };

  componentDidMount() {
    this.getReviews(1, true);
  }

  areReviewsForUser = () => {
    return this.props.location.pathname.includes("user");
  };

  getReviews = (page, updatePagination) => {
    this.props.getReviews(
      page,
      {
        [this.areReviewsForUser() ? "user" : "recipe"]: this.props.match.params
          .id
      },
      updatePagination && this.setPageCount
    );
  };

  handleRemove = id => {
    this.props.removeReview(id, () =>
      this.getReviews(this.state.savedPage, true)
    );
  };

  onPageChange = page => {
    this.getReviews(page.selected + 1);
    this.setState({ savedPage: page.selected + 1 });
  };

  setPageCount = pageCount => {
    this.setState({ pageCount });
  };

  render() {
    return (
      <Layout>
        <PageHeader title="Reviews" />
        <Row>
          <Col md={6}>
            <div className="reviews-list">
              {!this.props.reviews.length && <p>No reviews yet</p>}
              {this.props.reviews.map(review => (
                <Review
                  key={review._id}
                  {...review}
                  tooltipButtons={[
                    this.props.can("delete", "review") && {
                      title: "Delete",
                      icon: deleteIcon,
                      onClick: () => this.handleRemove(review._id)
                    }
                  ]}
                />
              ))}
            </div>
            {this.state.pageCount > 1 && (
              <div className="pagination">
                <ReactPaginate
                  onPageChange={this.onPageChange}
                  pageCount={this.state.pageCount}
                  activeClassName="active"
                  previousLabel={<img src={arrowBack} alt="back" />}
                  nextLabel={<img src={arrowNext} alt="next" />}
                />
              </div>
            )}
          </Col>
        </Row>
      </Layout>
    );
  }
}

const mapStateToProps = state => ({
  reviews: state.reviews.all
});

const mapDispatchToProps = dispatch => ({
  getReviews: (page, params, updatePagination) =>
    dispatch({ type: "GET_REVIEWS", page, params, updatePagination }),
  removeReview: (id, onSuccess) =>
    dispatch({ type: "REMOVE_REVIEW", id, onSuccess })
});

export default withPermissions(
  connect(mapStateToProps, mapDispatchToProps)(Reviews)
);
