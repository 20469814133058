import { call } from "../core";

export const callAddBackground = (createBackgroundInfo) => {
  return call("/backgrounds", "POST", { body: createBackgroundInfo });
};

export const callGetBackgrounds = () => {
  return call(`/backgrounds`, "GET", {});
};

export const callGetBackgroundById = (id) => {
  return call(`/backgrounds/${id}`, "GET", {} );
};

export const callRemoveBackground = (id) => {
  return call(`/backgrounds/${id}`, "DELETE", {});
};

export const callUpdateBackground = ({ _id, ...background }) => {
  return call(`/backgrounds/${_id}`, "PATCH", { body: background });
};
