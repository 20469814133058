import * as ActionTypes from "./actionTypes";

const setPlans = plans => ({
  type: ActionTypes.SET_AACP_PLANS,
  plans
});

const setCurrentPlan = plan => ({
  type: ActionTypes.SET_CURRENT_AACP_PLAN,
  plan
})

export default {
  setPlans,
  setCurrentPlan
}