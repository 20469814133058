import { call, put, takeLatest } from "redux-saga/effects";
import QuotesActions from "../../redux/quotes/actions";

import {
  callAddQuote,
  callGetQuotes,
  callRemoveQuote,
  callUpdateQuote,
} from "../../api/quotes";

export function* getQuotes(action) {
  try {
    yield put({ type: "GET_QUOTES_REQUEST" });

    const response = yield call(callGetQuotes);
    yield put(QuotesActions.setQuotes(response.data));

    yield put({ type: "GET_QUOTES_SUCCESS" });
  } catch (error) {
    yield put({
      type: "GET_QUOTES_FAILURE",
      error: error.message,
    });
  }
}

export function* removeQuote(action) {
  try {
    yield put({ type: "REMOVE_QUOTE_REQUEST" });

    const response = yield call(callRemoveQuote, action.id);

    yield put(QuotesActions.removeQuotes(action.id));

    yield put({ type: "REMOVE_QUOTE_SUCCESS" });
  } catch (error) {
    yield put({
      type: "REMOVE_QUOTE_FAILURE",
      error: error.message,
    });
  }
}

export function* updateQuote(action) {
  try {
    yield put({ type: "UPDATE_QUOTE_REQUEST" });

    yield call(callUpdateQuote, action.quote);

    yield put(QuotesActions.editQuote(action.quote));

    yield put({ type: "UPDATE_QUOTE_SUCCESS" });
  } catch (error) {
    yield put({
      type: "UPDATE_QUOTE_FAILURE",
      error: error.message,
    });
  }
}

export function* addQuote(action) {
  try {
    yield put({ type: "ADD_QUOTE_REQUEST" });

    const response = yield call(callAddQuote, action.quote);

    yield put(QuotesActions.addQuote(response?.data));

    yield put({ type: "ADD_QUOTE_SUCCESS" });
  } catch (error) {
    yield put({
      type: "ADD_QUOTE_FAILURE",
      error: error.message,
    });
  }
}

const quotesSaga = [
  takeLatest("GET_QUOTES", getQuotes),
  takeLatest("ADD_QUOTE", addQuote),
  takeLatest("UPDATE_QUOTE", updateQuote),
  takeLatest("REMOVE_QUOTE", removeQuote),
];

export default quotesSaga;
