import { useRef, useState } from "react";

export const useDownloadFile = ({
  apiDefinition,
  preDownloading,
  postDownloading,
  onError,
  fileName,
}) => {
  const ref = useRef(null);
  const [url, setFileUrl] = useState();
  const [name, setFileName] = useState();
  function s2ab(s) {
    var buf = new ArrayBuffer(s.length);
    var view = new Uint8Array(buf);
    for (var i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
    return buf;
  }
  const download = async () => {
    try {
      preDownloading();

      const resp = await apiDefinition();

      const blob = new Blob([resp], {
        type:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = `${fileName}.xlsx`;
      // setFileUrl(url);

      // eslint-disable-next-line no-unused-expressions
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      postDownloading();
    } catch (error) {
      onError(error);
    }
  };

  return { download, ref, url, name };
};
