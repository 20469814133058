import { call, put, takeLatest } from "redux-saga/effects";
import MealActions from "../../redux/meal/actions";
import {
  callAddMeal,
  callGetMeals,
  callRemoveMeal,
  callUpdateMeal
} from "../../api/meal";

export function* getMeals(action) {
  try {
    yield put({ type: "GET_MEALS_REQUEST" });

    const response = yield call(callGetMeals, action.start, action.end);
    yield put(MealActions.setMeals(response.data));

    yield put({ type: "GET_MEALS_SUCCESS" });
  } catch (error) {
    yield put({
      type: "GET_MEALS_FAILURE",
      error: error.message
    });
  }
}

export function* updateMeal(action) {
  try {
    yield put({ type: "UPDATE_MEAL_REQUEST" });

    const response = yield call(callUpdateMeal, action.id, action.body);
    if (!action.ignoreUpdating) {
      yield put(MealActions.updateMeal(response.data));
    }

    yield put({ type: "UPDATE_MEAL_SUCCESS" });
  } catch (error) {
    yield put({
      type: "UPDATE_MEAL_FAILURE",
      error: error.message
    });
  }
}

export function* addMeal(action) {
  try {
    yield put({ type: "ADD_MEAL_REQUEST" });

    const response = yield call(callAddMeal, action.meal);
    yield put(MealActions.addMeal(response.data));

    yield put({ type: "ADD_MEAL_SUCCESS" });
  } catch (error) {
    yield put({
      type: "ADD_MEAL_FAILURE",
      error: error.message
    });
  }
}

export function* deleteMeal(action) {
  try {
    yield put({ type: "DELETE_MEAL_REQUEST" });

    const response = yield call(callRemoveMeal, action.id);
    yield put(MealActions.deleteMeal(action.id));

    yield put({ type: "DELETE_MEAL_SUCCESS" });
  } catch (error) {
    yield put({
      type: "DELETE_MEAL_FAILURE",
      error: error.message
    });
  }
}

const mealSaga = [
  takeLatest("GET_MEALS", getMeals),
  takeLatest("UPDATE_MEAL", updateMeal),
  takeLatest("ADD_MEAL", addMeal),
  takeLatest("DELETE_MEAL", deleteMeal)
];

export default mealSaga;
