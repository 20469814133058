import { call } from "../core";

export const callAddEvent = event => {
  return call("/event", "POST", { body: event });
};

export const callGetEvents = () => {
  return call(`/event/list/1`, "GET", {});
};

export const callRemoveEvent = id => {
  return call(`/event/${id}`, "DELETE", {});
};

export const callUpdateEvent = (id, body) => {
  return call(`/event/${id}`, "PUT", { body });
};
