import * as ActionTypes from "./actionTypes";

const setRecipes = recipes => ({
  type: ActionTypes.SET_RECIPES,
  recipes
});

export default {
  setRecipes
};
