import * as ActionTypes from "./actionTypes";

const initialState = {
  quotes: [],
};

export default function messages(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.SET_QUOTES:
      return {
        ...state,
        quotes: action.quotes,
      };
    case ActionTypes.REMOVE_QUOTES:
      return {
        ...state,
        quotes: state.quotes.filter((a) => a._id !== action.id),
      };
    case ActionTypes.ADD_QUOTES: {
      return {
        ...state,
        quotes: [action.quote, ...state.quotes],
      };
    }
    case ActionTypes.EDIT_QUOTES: {
      return {
        ...state,
        quotes: state.quotes.map((quote) => {
          return quote._id === action.quote._id ? action.quote : quote;
        }),
      };
    }

    default:
      return state;
  }
}
