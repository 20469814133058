import React, { useState, useEffect, useRef } from "react";
import cx from "classnames";
import { connect } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";

import withWindowSize from '../../utils/withWindowSize';
import FullScreenModal from "./components/FullscreenModal";
import SearchInput from "./components/SearchInput";
import RecipesFilter from "./components/RecipesFilter";
import RecipeCard from "./components/RecipeCard";
import NotFoundMessage from "./components/NotFoundMessage";

import settings from "../../assets/images/common/sliders-vert.svg";
import { callGetRecipes } from "../../api/recipe/index";
import CustomSelect from "./components/CustomSelect";
import PageHeader from "../PageHeader";

const SORT_OPTIONS = [
  {
    value: 'new',
    label: 'Newest'
  },
  {
    value: 'old',
    label: 'oldest'
  },
  {
    value: 'quick',
    label: 'quickest'
  },
  {
    value: 'rate',
    label: 'highest rated'
  },
  {
    value: 'alphabet',
    label: 'Alphabetical'
  }
]

const initialFilters = {
  title: "",
  seasons: [],
  types: [],
  time: [],
  reviews: undefined,
  favorites: undefined,
  haveMade: undefined,
  windowWidth: 0,
  search: '',
  sort: 'new'
};

const AddRecipe = (props) => {
  console.log('props: ', props);
  const [state, setCurrentState] = useState({
    ...initialFilters,
    isFilterOpen: false,
    tab: "all",

    recipes: [],
    page: 0,
    pages: null,
    loading: false,
    shouldFetchMore: true,

    loadingCount: false,
    filteredRecipesCount: 0,
    windowWidth: 0,
    search: '',
    sort: initialFilters.sort
  })

  const scrollable = useRef();

  const setState = (newState) => {
    setCurrentState((prevState) => ({ ...prevState, ...newState }));
  }

  useEffect(() => {
    props.getFormData();
    props.getSeasons();
    const fetchData = async () => {
      await handleLoad();
    }
    fetchData();
  }, [])

  useEffect(() => {
    if (state.shouldFetchMore) {
      handleLoad();
      countRecipesWithFilters(getQuery());
    }
  },[state.shouldFetchMore])

  const handleLoad = async () => {
    
    setState({ loading: true, shouldFetchMore: false });

    const filterParams = countFilters() > 0 ? getQuery() : {};

    const response = await callGetRecipes(state.page + 1, {
      ...filterParams,
      ...getPermanentParams()
    });

    setState({
      loading: false,
      page: parseInt(response.data.page),
      pages: Math.ceil(response.data.pages),
      recipes: [...state.recipes, ...response.data.results]
    });
  };

  const getPermanentParams = () => {
    return {
      haveMade: state.haveMade,
      favorite: state.favorites,
      title: state.title || undefined,
      sort: state.sort
    };
  };

  const changeFilter = (field, value) => {
    let newValue = value;
    if ((typeof state[field] !== 'object' || !value) && state[field] === value) {
      newValue = initialFilters[field];
    }
    setState({
      [field]: newValue
    });

    setTimeout(() => {
      countRecipesWithFilters(getQuery({ [field]: newValue }));
      getFreshRecipes();
    });
  };

 const toggleFavorites = () => {
    setState({
      favorites: !state.favorites ? true : undefined
    });
    setTimeout(() => {
      countRecipesWithFilters(getQuery());
      getFreshRecipes();
    });
  }

 const toggleHaveMade = () => {

    setState({
      haveMade: !state.haveMade ? true : undefined
    });
    setTimeout(() => {
      countRecipesWithFilters(getQuery());
      getFreshRecipes();
    });
  }

  const getQuery = (newValue) => {
    return {
      seasons: state.seasons,
      types: state.types,
      minTime: state.time[0],
      maxTime: state.time[1],
      minRating: state.reviews,
      sort: state.sort,
      ...newValue
    };
  };

  const countRecipesWithFilters = async params => {
    if (state.loadingCount) return

   setState({ loadingCount: true });
    const response = await callGetRecipes(1, {
      ...params,
      ...getPermanentParams()
    });

   setState({
      loadingCount: false,
      filteredRecipesCount: response.data.count
    });
  };

  const clearFilters = () => {
    setState(initialFilters);
    setState({tab: 'all'});
    setTimeout(() => { getFreshRecipes() });
  };

  const countFilters = () => {
    let count = 0;

    count += !state.title ? 0 : 1;
    count += !state.seasons.length ? 0 : 1;
    count += !state.types.length ? 0 : 1;
    count +=
     state.time[0] !== undefined ||state.time[1] !== undefined
        ? 1
        : 0;
    count += state.reviews !== undefined ? 1 : 0;
    count += state.favorites ? 1 : 0;
    count += state.haveMade ? 1 : 0;

    return count;
  };

  const openFilters = () => {
   setState({
      isFilterOpen: true
    });
  };

  const closeFilters = () => {
   setState({
      isFilterOpen: false
    });
  };

  const getFreshRecipes = () => {
   setState({
        recipes: [],
        page: 0,
        shouldFetchMore: true
      }
     );
  };

  const applySearchFilter = e => {
    if (e.key === "Enter") {
     setState({
        title: e.target.value
      });

      setTimeout(() => {
       getFreshRecipes();
      });
    }
  };

  const onRangeChange = (value) => {
    setState({
      time: [0, value[0]]
    })
  }


  const getFiltersWithProps = () => {
    return (
      <RecipesFilter
        season={state.seasons}
        type={state.types}
        time={state.time}
        reviews={state.reviews}
        onChange={changeFilter}
        onClearClick={clearFilters}
        search={state.search}
        onSearchChange={(e) => setState({ search: e.target.value })}
        onKeyPress={applySearchFilter}
        countFilters={countFilters}
        onFinalChange={(value) => changeFilter('time', [0, value[0]])}
        onRangeChange={onRangeChange}
        disableHeader={true}

        filtersCount={countFilters()}
        filteredRecipesCount={state.filteredRecipesCount}
        loadingCount={state.loadingCount}

        seasonsOptions={props.seasons}
        typesOptions={props.types}

        windowWidth={props.windowWidth}
        favorites={state.favorites}
        toggleFavorites={toggleFavorites}
        haveMade={state.haveMade}
        toggleHaveMade={toggleHaveMade}
      />
    );
  };
    const hasMorePages =state.page < state.pages;

    return (
      <FullScreenModal
      visible={props.visible}
      actionButton={
        state.isFilterOpen ? closeFilters : props.close
      }
      // mobileButton="Clear all"
      mobileButtonAction={clearFilters}
      mobileButtonVisible={state.isFilterOpen}
      innerRef={scrollable}
      content={`${state.isFilterOpen && "no-padding-modal"} overflow pt-0`}
    >
      <div className="add-recipe add-recipe-modal">
        <div className={cx("sidebar-new sidebar-modal", { visible: state.isFilterOpen })}>
          {getFiltersWithProps()}
        </div>
        <div className={cx("content-container",{hidden: state.isFilterOpen})}>
            <h2 className={cx("section-header", 'spaceless')}>
              Add recipe
            </h2>
            <div className='search-container'>
              <SearchInput
                value={state.search}
                onChange={(e) => setState({ search: e.target.value })}
                placeholder='Search all recipes...'
                onKeyPress={applySearchFilter}
                classNames={{ input: "mobile-search" }}
              />
              <div className="buttons">
                <button className='filter-button' onClick={openFilters}>
                  <img src={settings} alt='Filters' />
                  <p className='small-uppercase'>show filters</p>
                </button>
                <div className='divider' />
                <button className='filter-button' onClick={clearFilters}>
                  <p className='small-uppercase'>Clear filters</p>
                </button>
              </div>
            </div>
          <div
            className={cx('recipes-container')}
          >
            <CustomSelect
              options={SORT_OPTIONS}
              value={state.sort}
              onChange={(opt) => {
                changeFilter('sort', opt);
              }}
              leftLabel='sort by:'
              wrapperClassName='self-end'
            />
            {(state.loading ||
              !!state.recipes.length ||
              state.shouldFetchMore) &&
            scrollable.current ? (
              <InfiniteScroll
                style={{ overflow: "initial" }}
                dataLength={state.recipes.length}
                next={handleLoad}
                hasMore={
                  !state.loading &&
                  (state.shouldFetchMore || hasMorePages)
                }
                loader={null}
                className="recipes-grid-container"
                scrollableTarget={scrollable.current}
              >
                <div
                  className={cx("recipes-grid", {
                    visible: state.isFilterOpen
                  })}
                >
                  {state.recipes.map((recipe, i) => (
                    <RecipeCard
                      key={i}
                      {...recipe}
                      onInfoClick={e => props.onInfoClick(recipe._id, e)}
                      onClick={() => props.onRecipeClick(recipe)}
                    />
                  ))}
                </div>
              </InfiniteScroll>
            ) : (
              <NotFoundMessage />
            )}
          </div>
        </div>
      </div>
    </FullScreenModal>
    );
}

const mapStateToProps = state => ({
  seasons: state.enum.seasons,
  types: state.enum.types,
});

const mapDispatchToProps = dispatch => ({
  getEnums: (enums) => dispatch({ type: "GET_ENUMS", enums }),
  getFormData: () => dispatch({ type: "GET_RECIPE_FORM_DATA" }),
  getSeasons: () => dispatch({ type: "GET_SEASONS" }),
});

export default connect(mapStateToProps, mapDispatchToProps)(withWindowSize(AddRecipe));
