import * as ActionTypes from "./actionTypes";

const setReviews = reviews => ({
  type: ActionTypes.SET_REVIEWS,
  reviews
});

export default {
  setReviews
};
