import React from "react";

const UserProfile = props => (
  <div className="user-profile">
    <div className="photo" style={{ backgroundImage: `url(${props.photo})` }} />
    <p className="name">{props.name}</p>
  </div>
);

export default UserProfile;
