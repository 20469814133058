import { useEffect, useState } from "react";
import debounce from "../../utils/debounce";
import valueOnError from "../../utils/valueOnError";

const checkCanScroll = valueOnError(
  (element) => element.clientWidth < element.firstChild.clientWidth,
  () => false
);

const checkCanScrollToLeft = valueOnError(
  (element) => element.scrollLeft > 0,
  () => false
);

const checkCanScrollToRight = valueOnError(
  (element) =>
    element.clientWidth + element.scrollLeft < element.firstChild.clientWidth,
  () => false
);

export default function useCanScrollTable(ref) {
  const [canScroll, setCanScroll] = useState(false);
  const [canScrollToLeft, setCanScrollToLeft] = useState(false);
  const [canScrollToRight, setCanScrollToRight] = useState(false);

  useEffect(() => {
    if (!ref?.current) return undefined;

    const element = ref?.current;

    const handle = () => {
      setCanScroll(checkCanScroll(element));
      setCanScrollToLeft(checkCanScrollToLeft(element));
      setCanScrollToRight(checkCanScrollToRight(element));
    };

    const observer = new ResizeObserver(handle);

    observer.observe(element);

    handle();

    return () => observer.disconnect();
  }, []);

  useEffect(() => {
    if (!ref?.current) return undefined;

    const handle = debounce(() => {
      const canScrollToLeftNew =
        ref?.current && checkCanScrollToLeft(ref.current);

      if (canScrollToLeftNew !== canScrollToLeft) {
        setCanScrollToLeft(!!canScrollToLeftNew);
      }

      const canScrollToRightNew =
        ref?.current && checkCanScrollToRight(ref.current);

      if (canScrollToRightNew !== canScrollToRight) {
        setCanScrollToRight(!!canScrollToRightNew);
      }
    }, 100);

    handle();

    ref.current.addEventListener("scroll", handle);

    return () => ref?.current?.removeEventListener("scroll", handle);
  }, [canScrollToLeft, canScrollToRight]);

  return {
    canScroll,
    canScrollToLeft,
    canScrollToRight,
  };
}
