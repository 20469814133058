import React from "react";
import Admins from "../../Admins";
import { withRouter } from "react-router";

export const AdminsTab = withRouter((navigation) => {
  return (
    <div>
      <Admins history={navigation.history} />
    </div>
  );
});
