import React from "react";
import { connect } from "react-redux";

const mapStateToProps = state => ({
  userPermissions: state.user.permissions
});

const withPermissions = Component => {
  class HOC extends React.Component {
    can = (action, subject) => {
      if (!this.props.userPermissions) {
        return false;
      }

      const userPermission = this.props.userPermissions.find(
        p => p.permission.endpoint === subject
      );

      if (!userPermission) {
        return false;
      }

      return userPermission[action];
    };

    render() {
      return <Component {...this.props} can={this.can} />;
    }
  }

  return connect(mapStateToProps)(HOC);
};

export default withPermissions;
