import React from "react";
import { Col, Row } from "react-grid-system";
import { connect } from "react-redux";
import { withRouter } from "react-router";

import PageHeader from "../../components/PageHeader";
import Input from "../Input";
import Button from "../../components/Button";
import Layout from "../../components/Layout";
import { call } from "../../api/core";
import withPermissions from "../../utils/PermissionManager";

import validate from "./validation";
import { getUpdatedBody } from "../../utils/helpers";
import Checkbox from "../Checkbox";
import NoPermissions from "../../components/NoPermissions";

class AdminForm extends React.Component {
  state = {
    name: "",
    email: "",
    password: "",
    permissions: [],
    changedFields: [],
    errors: null,
  };

  async componentDidMount() {
    this.setState({
      permissions: this.props.permissions.map((p) => ({
        read: false,
        create: false,
        edit: false,
        delete: false,
        permission: {
          endpoint: p.endpoint,
        },
      })),
    });

    if (this.props.editing) {
      const response = await call(
        `/admin/${this.props.match.params.id}`,
        "GET",
        {}
      );

      this.setState(response.data);
    }
  }

  updateChangedFields = (field) => {
    const changedFields = [...this.state.changedFields];

    if (changedFields.indexOf(field) === -1) {
      changedFields.push(field);
    }

    this.setState({
      changedFields,
    });
  };

  changeField = (field, value) => {
    this.setState({
      [field]: value,
    });

    this.updateChangedFields(field);
  };

  changePermission = (permission, field) => {
    const copy = [...this.state.permissions];
    const permissionIndex = copy.findIndex((p) => p === permission);

    copy[permissionIndex][field] = !copy[permissionIndex][field];

    this.setState({
      permissions: copy,
    });

    this.updateChangedFields("permissions");
  };

  onSuccess = () => {
    if (this.props.editing) {
      this.props.refetchOwnPermissions();
    }

    this.props.history.push("/settings/admins");
  };

  getPermissionsForRequest = () => {
    return this.state.permissions.map((permission) => {
      const permissionProps = this.props.permissions.find(
        (p) => p.endpoint === permission.permission.endpoint
      );

      return {
        read: permission.read,
        create: permission.create,
        edit: permission.edit,
        delete: permission.delete,
        permission: permissionProps._id,
      };
    });
  };

  onSubmit = () => {
    const errors = validate(this.state);

    if (errors) {
      if (this.state.password.length < 6 && !this.props.editing) {
        errors.password = "Minimum 6 characters";
      }

      this.setState({ errors });
      return;
    } else {
      this.setState({ errors: null });
    }

    const body = {
      name: this.state.name,
      email: this.state.email,
      permissions: this.getPermissionsForRequest(),
    };

    if (this.props.editing) {
      this.props.updateAdmin(
        this.props.match.params.id,
        getUpdatedBody(this.state.changedFields, body),
        this.onSuccess
      );
    } else {
      body.password = this.state.password;

      this.props.addAdmin(body, this.onSuccess);
    }
  };

  render() {
    const buttonText = this.props.editing ? "Update" : "Add";
    const loading = this.props.adding || this.props.updating;

    const canRead = this.props.can("read", "admin");
    const canEdit = this.props.can("edit", "admin");
    const canCreate = this.props.can("create", "admin");

    const editingMode = canRead && canEdit && this.props.editing;
    const creatingMode = canCreate && !this.props.editing;

    return (
      <Layout>
        <PageHeader
          title={`${this.props.editing ? "Edit" : "Add"} Admin`}
          breadcrumbs="Back to Admins"
          breadcrumbsLink="/settings/admins"
        />
        {editingMode || creatingMode ? (
          <>
            <Row>
              <Col md={6}>
                <Input
                  label="Name"
                  placeholder="Type here"
                  value={this.state.name}
                  onChange={(e) => this.changeField("name", e.target.value)}
                  error={this.state.errors && this.state.errors.name}
                />
                <Input
                  label="Email"
                  placeholder="Type here"
                  value={this.state.email}
                  onChange={(e) => this.changeField("email", e.target.value)}
                  error={this.state.errors && this.state.errors.email}
                />
                {!this.props.editing && (
                  <Input
                    label="Password"
                    placeholder="Type here"
                    type="password"
                    value={this.state.password}
                    onChange={(e) =>
                      this.changeField("password", e.target.value)
                    }
                    error={this.state.errors && this.state.errors.password}
                  />
                )}
                {this.props.permissions.map((p) => {
                  const userPermission = this.state.permissions.find(
                    ({ permission }) => p.endpoint === permission.endpoint
                  );

                  if (userPermission) {
                    return (
                      <div className="permission">
                        <h6 className="label">{p.name}</h6>
                        <p>{p.description}</p>
                        <div className="checkboxes-list">
                          <Checkbox
                            name="Read"
                            value={userPermission.read}
                            onChange={() =>
                              this.changePermission(userPermission, "read")
                            }
                          />
                          <Checkbox
                            name="Create"
                            value={userPermission.create}
                            onChange={() =>
                              this.changePermission(userPermission, "create")
                            }
                          />
                          <Checkbox
                            name="Edit"
                            value={userPermission.edit}
                            onChange={() =>
                              this.changePermission(userPermission, "edit")
                            }
                          />
                          <Checkbox
                            name="Delete"
                            value={userPermission.delete}
                            onChange={() =>
                              this.changePermission(userPermission, "delete")
                            }
                          />
                        </div>
                      </div>
                    );
                  }
                })}
              </Col>
            </Row>
            <Button limited onClick={this.onSubmit}>
              {buttonText + (loading ? "..." : "")}
            </Button>
            <p className="error">
              {this.state.errors
                ? "Fill all requirement fields and try again"
                : ""}
            </p>
          </>
        ) : (
          <NoPermissions />
        )}
      </Layout>
    );
  }
}

const mapStateToProps = (state) => ({
  adding: state.loading["ADD_ADMIN"],
  updating: state.loading["UPDATE_ADMIN"],
  permissions: state.enum.permissions,
});

const mapDispatchToProps = (dispatch) => ({
  addAdmin: (admin, onSuccess) =>
    dispatch({ type: "ADD_ADMIN", admin, onSuccess }),
  updateAdmin: (id, body, onSuccess) =>
    dispatch({ type: "UPDATE_ADMIN", id, body, onSuccess }),
  refetchOwnPermissions: () => dispatch({ type: "GET_PERMISSIONS_FOR_ADMIN" }),
});

export default withPermissions(
  connect(mapStateToProps, mapDispatchToProps)(withRouter(AdminForm))
);
