const initialState = {};

export default function error(state = initialState, action) {
  const { type, error } = action;
  const matches = /(.*)_(CLEAR|REQUEST|FAILURE)/.exec(type);
  if (!matches) {
    return state;
  }
  const [, requestName, requestState] = matches;
  return {
    ...state,
    [requestName]: requestState === "FAILURE" ? error : ""
  };
}
