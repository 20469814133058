import React, { useRef } from "react";

import search from "../../../assets/images/common/search.svg";
import cx from "classnames";
import Input from "../../../forms/Input";

const SearchInput = props => {
  const internalRef = useRef();
  const ref = props.innerRef || internalRef;

  const handleSearchClick = () => {
    
    const event = { key: "Enter", target: ref?.current };
    props.onKeyPress && props.onKeyPress(event);
  };

  return (
    <div className={cx({"search-input": !props.className}, props.className)}>
      <Input innerRef={ref} {...props} />
      <img
        className="mark pointer"
        src={search}
        alt="Search"
        onClick={handleSearchClick}
      />
    </div>
  );
};

export default SearchInput;
