import React, { Component } from "react";
import { connect } from "react-redux";
import { Switch, Route, Redirect } from "react-router";
import moment from "moment";

import Plan from "../../containers/Plan";
import Recipes from "../../containers/Recipes";
import Rawkstars from "../../containers/Rawkstars";
import Wellness from "../../containers/Wellness";
import Admins from "../../containers/Admins";
import AddEvent from "../../forms/EventForm";
import AddArticle from "../../forms/ArticleForm";
import AddRecipe from "../../forms/RecipeForm";
import UserForm from "../../forms/UserForm";
import AdminForm from "../../forms/AdminForm";
import Login from "../../containers/Login";
import Reviews from "../../containers/Reviews";
import { Settings } from "../../containers/Settings";
import AACPPlan from "../../containers/AACPPlan";
import ForgotPassword from "../../containers/ForgotPassword";
import NewPassword from "../../containers/NewPassword";

const AuthRoute = ({
  component: Component,
  isAuthenticated,
  isLoading,
  ...rest
}) => (
  <Route
    {...rest}
    render={
      (props) => {
        return isAuthenticated ? (
          <Component
            {...props}
            isLoading={isLoading}
            isAuthenticated={isAuthenticated}
          />
        ) : (
          <Redirect to={{ pathname: "/login" }} />
        );
      }
      // )
    }
  />
);

const UnauthRoute = ({ component: Component, isAuthenticated, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      !isAuthenticated ? (
        <Component {...props} />
      ) : (
        <Redirect to={{ pathname: "/" }} />
      )
    }
  />
);

class Routes extends Component {
  componentDidMount() {
    if (this.props.isAuthenticated) {
      this.fetchData();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.isAuthenticated && !prevProps.isAuthenticated) {
      this.fetchData();
    }
  }

  fetchData = () => {
    this.props.getPermissionsForAdmin();
    this.props.getPermissions();
  };

  render() {
    // if (this.props.loading || this.props.loadingPermissions) {
    //   return null;
    // }

    return (
      <Switch>
        <UnauthRoute
          exact
          path="/login"
          component={Login}
          isAuthenticated={this.props.isAuthenticated}
        />
        <UnauthRoute
          exact
          path="/forgot"
          component={ForgotPassword}
          isAuthenticated={this.props.isAuthenticated}
        />
        <UnauthRoute
          exact
          path="/reset-password/:token"
          component={NewPassword}
          isAuthenticated={this.props.isAuthenticated}
        />
        <AuthRoute
          exact
          path="/plan"
          component={Plan}
          isAuthenticated={this.props.isAuthenticated}
        />
        <AuthRoute
          exact
          path="/plan/:id"
          component={AACPPlan}
          isAuthenticated={this.props.isAuthenticated}
        />
        <AuthRoute
          exact
          path="/recipes/add-recipe"
          component={AddRecipe}
          isAuthenticated={this.props.isAuthenticated}
        />
        <AuthRoute
          exact
          path="/recipes/edit-recipe/:id"
          component={() => <AddRecipe editing />}
          isAuthenticated={this.props.isAuthenticated}
        />
        <AuthRoute
          exact
          path="/recipes"
          component={Recipes}
          isAuthenticated={this.props.isAuthenticated}
        />
        <AuthRoute
          exact
          path="/rawkstars"
          component={Rawkstars}
          isAuthenticated={this.props.isAuthenticated}
          isLoading={this.props.loading || this.props.loadingPermissions}
        />
        <AuthRoute
          exact
          path="/rawkstars/add-user"
          component={UserForm}
          isAuthenticated={this.props.isAuthenticated}
        />
        <AuthRoute
          exact
          path="/rawkstars/edit-user/:id"
          component={() => <UserForm editing />}
          isAuthenticated={this.props.isAuthenticated}
        />
        <AuthRoute
          exact
          path="/wellness/add-event"
          component={AddEvent}
          isAuthenticated={this.props.isAuthenticated}
        />
        <AuthRoute
          exact
          path="/wellness/edit-event/:id"
          component={() => <AddEvent editing />}
          isAuthenticated={this.props.isAuthenticated}
        />
        <AuthRoute
          exact
          path="/wellness/add-article"
          component={AddArticle}
          isAuthenticated={this.props.isAuthenticated}
        />
        <AuthRoute
          exact
          path="/wellness/edit-article/:id"
          component={() => <AddArticle editing />}
          isAuthenticated={this.props.isAuthenticated}
        />
        <AuthRoute
          exact
          path="/wellness"
          component={Wellness}
          isAuthenticated={this.props.isAuthenticated}
        />
        <AuthRoute
          exact
          path="/settings/admins/add-admin"
          component={AdminForm}
          isAuthenticated={this.props.isAuthenticated}
        />
        <AuthRoute
          exact
          path="/settings/admins/edit-admin/:id"
          component={() => <AdminForm editing />}
          isAuthenticated={this.props.isAuthenticated}
        />
        <AuthRoute
          exact
          path={["/reviews/user/:id", "/reviews/recipe/:id"]}
          component={Reviews}
          isAuthenticated={this.props.isAuthenticated}
        />
        <AuthRoute
          exact
          path={["/settings", "/settings/:slug"]}
          component={() => <Settings />}
          isAuthenticated={this.props.isAuthenticated}
        />
        <Redirect from="/" to="/plan" />
      </Switch>
    );
  }
}

const mapStateToProps = (state) => ({
  loadingPermissions: state.loading["GET_PERMISSIONS_FOR_ADMIN"],
  loading: state.loading["GET_PERMISSIONS"],
  isAuthenticated:
    Boolean(state.session.accessToken) &&
    moment(state.session.expiration).isAfter(moment()),
});

const mapDispatchToProps = (dispatch) => ({
  getPermissionsForAdmin: () => dispatch({ type: "GET_PERMISSIONS_FOR_ADMIN" }),
  getPermissions: () => dispatch({ type: "GET_PERMISSIONS" }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Routes);
