export const OPTIONS_DATE = [
  { value: undefined, label: "-" },
  { value: 1, label: "Week №1" },
  { value: 2, label: "Week №2" },
  { value: 3, label: "Week №3" },
  { value: 4, label: "Week №4" },
  { value: 5, label: "Week №5" },
  { value: 6, label: "Week №6" },
  { value: 7, label: "Week №7" },
  { value: 8, label: "Week №8" },
  { value: 9, label: "Week №9" },
  { value: 10, label: "Week №10" },
  { value: 11, label: "Week №11" },
  { value: 12, label: "Week №12" },
  { value: 13, label: "Week №13" },
  { value: 14, label: "Week №14" },
  { value: 15, label: "Week №15" },
  { value: 16, label: "Week №16" },
  { value: 17, label: "Week №17" },
  { value: 18, label: "Week №18" },
  { value: 19, label: "Week №19" },
  { value: 20, label: "Week №20" },
  { value: 21, label: "Week №21" },
  { value: 22, label: "Week №22" },
  { value: 23, label: "Week №23" },
  { value: 24, label: "Week №24" },
  { value: 25, label: "Week №25" },
  { value: 26, label: "Week №26" },
  { value: 27, label: "Week №27" },
  { value: 28, label: "Week №28" },
  { value: 29, label: "Week №29" },
  { value: 30, label: "Week №30" },
  { value: 31, label: "Week №31" },
  { value: 32, label: "Week №32" },
  { value: 33, label: "Week №33" },
  { value: 34, label: "Week №34" },
  { value: 35, label: "Week №35" },
  { value: 36, label: "Week №36" },
  { value: 37, label: "Week №37" },
  { value: 38, label: "Week №38" },
  { value: 39, label: "Week №39" },
  { value: 40, label: "Week №40" },
  { value: 41, label: "Week №41" },
  { value: 42, label: "Week №42" },
  { value: 43, label: "Week №43" },
  { value: 44, label: "Week №44" },
  { value: 45, label: "Week №45" },
  { value: 46, label: "Week №46" },
  { value: 47, label: "Week №47" },
  { value: 48, label: "Week №48" },
  { value: 49, label: "Week №49" },
  { value: 50, label: "Week №50" },
  { value: 51, label: "Week №51" },
];
