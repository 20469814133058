import React, { useState, SyntheticEvent } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import PageHeader from "../../components/PageHeader";
import check from "../../assets/images/common/check-w.svg";
import Layout from "../../components/Layout";
import Input from "../../forms/Input";
import Button from "../../components/Button";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

const NewPassword = (props) => {
  const [state, setCurrentState] = useState({
    password: "",
    passwordRepeat: "",
    errors: {},
    success: false,
  });
  const navigate = useHistory();

  const setState = (newState) => setCurrentState((prevState) => ({ ...prevState, ...newState }));

  const updateField = (e, field) => {
    setState({ [field]: e.currentTarget.value, errors: {} });
  };

  const validateFields = () => {
    const passwordMinimum = !!state.password && state.password.length > 5;

    if (!passwordMinimum) {
      setState({ errors: { password: "Minimum 6 characters" } });

      return;
    }

    const passwordMatch = state.password === state.passwordRepeat;

    if (!passwordMatch) {
      setState({ errors: { passwordRepeat: "Passwords do not match" } });

      return;
    }

    return true;
  };

  const onSuccess = () => {
    setState({ success: true });
    navigate.push('/login');
  };

  const createNewPassword = () => {
    if (validateFields()) {
      props.resetPassword(props.match.params.token, state.password, onSuccess);
    }
  };

  let actionButtonText = props.loading ? "Creating..." : "Create";

  if (state.success) actionButtonText = "Created";

  return (
    <Layout disableSidebar>
      <div className="login-box login-form restore-password">
        <PageHeader title="Create New Password" />
        <Input
          disabled={props.loading}
          error={state.errors.password}
          label="Password"
          placeholder="Type Here"
          type="password"
          value={state.password}
          onChange={(e) => updateField(e, "password")}
        />
        <Input
          disabled={props.loading}
          error={state.errors.passwordRepeat}
          label="Repeat Password"
          placeholder="Type Here"
          type="password"
          value={state.passwordRepeat}
          onChange={(e) => updateField(e, "passwordRepeat")}
        />
          <p style={{ alignSelf: 'flex-start' }}>
            Back to <Link to="/login" className='forgot-password-link'>Log In</Link>
          </p>
        <Button
          className="action-button"
          disabled={props.loading}
          primary={state.success}
          limited
          onClick={createNewPassword}
        >
          {state.success && <img src={check} alt="Sent" />}
          {actionButtonText}
        </Button>
        <p className="error">{props.error}</p>
      </div>
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  loading: state.loading["RESET_PASSWORD"],
  error: state.error["RESET_PASSWORD"],
});

const mapDispatchToProps = (dispatch) => ({
  resetPassword: (token, password, onSuccess) =>
    dispatch({ type: "RESET_PASSWORD", token, password, onSuccess }),
});

export default connect(mapStateToProps, mapDispatchToProps)(NewPassword);
