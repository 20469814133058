import { useMemo } from "react";
import { SETTINGS_TABS } from "..";

export const useGetDefaultActiveTab = (slug) => {
  return useMemo(() => {
    const defaultActiveTab = SETTINGS_TABS.findIndex((tab) => {
      return tab.path === slug;
    });

    if (defaultActiveTab === -1) {
      return { activeTab: 0 };
    }
    return { activeTab: defaultActiveTab };
  }, [slug]);
};
