import ReactDOM from 'react-dom';
import usePortal from '../../../utils/usePortal'

const ModalLayout = (props) => {
    const portalElement = usePortal('fullscreen-root');

    return ReactDOM.createPortal(
        props.children,
        portalElement
    )
}

export default ModalLayout;