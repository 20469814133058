import * as ActionTypes from "./actionTypes";

const setMeals = meals => ({
  type: ActionTypes.SET_MEALS,
  meals
});

const addMeal = meal => ({
  type: ActionTypes.ADD_MEAL,
  meal
});

const deleteMeal = id => ({
  type: ActionTypes.DELETE_MEAL,
  id
});

const updateMeal = meal => ({
  type: ActionTypes.UPDATE_MEAL,
  meal
});

export default {
  setMeals,
  addMeal,
  deleteMeal,
  updateMeal
};
