import React from "react";

import Tooltip from "../../../../components/Tooltip";

import star from "../../../../assets/images/common/star.svg";
import cooking from "../../../../assets/images/common/cookings.svg";
import edit from "../../../../assets/images/common/edit.svg";
import trash from "../../../../assets/images/common/trash.svg";
import clock from "../../../../assets/images/common/clock.svg";
import { useOptimizerImage } from "../../../../utils/useOptimizerImage";

const Card = (props) => {
  const optimizedImage = useOptimizerImage({
    common: props?.images?.big,
    retina: props?.images?.bigX2,
  });
  
  return (
    <div className="recipe-card-new card-full">
      <div className="image" style={optimizedImage}></div>
      <div className="info-container">
        <div className="info">
          <p className="title">{props.title}</p>
        </div>
        <div className="statistics">
          <div className="element">
            <img src={clock} alt="Star" />
            <p className="text-muted">{props.time} MIN</p>
          </div>
          <div className="element">
            <img src={star} alt="Star" />
            <p className="text-muted">{props.rating} ({props.reviewsAmount})</p>
          </div>
        </div>
        <div style={{ position: "absolute", bottom: "23px", right: "10px" }}>
          <Tooltip
            buttons={[
              {
                title: "Reviews",
                icon: star,
                onClick: props.goReviewsPage,
              },
              props.canEdit && {
                title: "Edit",
                icon: edit,
                onClick: props.onEditClick,
              },
              props.canDelete && {
                title: "Delete",
                icon: trash,
                onClick: props.onDeleteClick,
              },
            ]}
          />
        </div>
      </div>
    </div>
  );
};

export default Card;
