import { call, put, takeLatest } from "redux-saga/effects";
import UsersActions from "../../redux/users/actions";
import EnumActions from "../../redux/enum/actions";
import {
  callGetUsers,
  callRemoveUser,
  callUpdateUser,
  callAddUser,
  callRestoreUser,
  callHardDeleteUser,
} from "../../api/users";

export function* getUsers(action) {
  try {
    yield put({ type: "GET_USERS_REQUEST" });

    const response = yield call(callGetUsers, action.params);
    yield put(UsersActions.setUsers(response.data));

    yield put({ type: "GET_USERS_SUCCESS" });
  } catch (error) {
    yield put({
      type: "GET_USERS_FAILURE",
      error: error.message,
    });
  }
}

export function* removeUser(action) {
  try {
    yield put({ type: "REMOVE_USER_REQUEST" });

    const response = yield call(callRemoveUser, action.id);
    yield put(UsersActions.removeUser(action.id));

    yield put({ type: "REMOVE_USER_SUCCESS" });
  } catch (error) {
    yield put({
      type: "REMOVE_USER_FAILURE",
      error: error.message,
    });
  }
}

export function* hardDeleteUser(action) {
  try {
    yield put({ type: "HARD_DELETE_USER_REQUEST" });
    
    const response = yield call(callHardDeleteUser, action.id);
    yield put(UsersActions.removeUser(action.id));

    yield put({ type: "HARD_DELETE_USER_SUCCESS" });
  } catch (error) {
    yield put({
      type: "HARD_DELETE_USER_FAILURE",
      error: error.message,
    });
  }
}

export function* restoreUser(action) {
  try {
    yield put({ type: "RESTORE_USER_REQUEST" });

    yield call(callRestoreUser, action.id);
    yield put(UsersActions.restoreUser(action.id));

    yield put({ type: "RESTORE_USER_SUCCESS" });
  } catch (error) {
    yield put({ type: "RESTORE_USER_FAILURE", error: error.message });
  }
}

export function* updateUser(action) {
  try {
    yield put({ type: "UPDATE_USER_REQUEST" });

    const response = yield call(callUpdateUser, action.id, action.body);
    action.onSuccess();

    yield put({ type: "UPDATE_USER_SUCCESS" });
  } catch (error) {
    yield put({
      type: "UPDATE_USER_FAILURE",
      error: error.message,
    });
  }
}

export function* addUser(action) {
  try {
    yield put({ type: "ADD_USER_REQUEST" });

    const response = yield call(callAddUser, action.user);
    action.onSuccess();

    yield put({ type: "ADD_USER_SUCCESS" });
  } catch (error) {
    yield put({
      type: "ADD_USER_FAILURE",
      error: error.message,
    });
  }
}

const eventsSaga = [
  takeLatest("GET_USERS", getUsers),
  takeLatest("ADD_USER", addUser),
  takeLatest("UPDATE_USER", updateUser),
  takeLatest("REMOVE_USER", removeUser),
  takeLatest("RESTORE_USER", restoreUser),
  takeLatest("HARD_DELETE_USER", hardDeleteUser),
];

export default eventsSaga;
