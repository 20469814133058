import React, { useState } from "react";
import InputRange from "react-input-range";
import cx from "classnames";
import { Range } from "react-range";

import Checkbox from "../../../forms/Checkbox";
import Collapse from "./Collapse";

import check from "../../../assets/images/common/check-w.svg";
import Button from "../../Button";

export const Multiple = (props) => {
  const handleClick = (value) => {
    const copy = [...props.values];
    const index = props.values.indexOf(value);

    if (index !== -1) {
      copy.splice(index, 1);
    } else {
      copy.push(value);
    }

    props.onChange(copy);
  };

  return (
    <Collapse title={props.title} collapseDisabled={true}>
      <div className="options">
        {props.options.map(option => (
          <button
            key={option.label}
            className={cx("option", {
              active: props.values && props.values.includes(option.value)
            })}
            onClick={() => handleClick(option.value)}
          >
            <img className="check" src={check} alt="Active" />
            {option.label}
          </button>
        ))}
      </div>
    </Collapse>
  );
};

export const Single = (props) => (
  <Collapse title={props.title}>
    <div className="options">
      {props.options.map(option => (
        <button
          key={option.label}
          className={cx("option", {
            active: props.value === option.value
          })}
          onClick={() => props.onChange(option.value)}
        >
          <img className="check" src={check} alt="Active" />
          {option.label}
        </button>
      ))}
    </div>
  </Collapse>
);

export const WithCheckboxes = (props) => {
  const handleClick = (value) => {
    const copy = [...props.values];
    const index = props.values.indexOf(value);

    if (index !== -1) {
      copy.splice(index, 1);
    } else {
      copy.push(value);
    }

    props.onChange(copy);
  };

  const [showAll, setShowAll] = useState(false);

  const showAllOptions = () => setShowAll(true);

  return (
    <Collapse title={props.title}>
      <div className="options options-checkboxes">
        {(!showAll ? props.options.slice(0, 4) : props.options).map(option => (
          <Checkbox
            key={option.label}
            active={props.values.includes(option.value)}
            name={option.label}
            onChange={() => handleClick(option.value)}
          />
        ))}
        {!showAll && props.options.length > 5 && (
          <Button link onClick={showAllOptions}>
            Show all
          </Button>
        )}
      </div>
    </Collapse>
  );
};

export const Slider = (props) => (
  <Collapse title="Duration" collapseDisabled={props.isCollapseDisabled}>
    <div className="slider-container">
      {props.isCollapseDisabled ? (
        <div className="single-range">
          <Range
            max={120}
            min={0}
            values={[props.value[1]]}
            onFinalChange={props.onFinalChange}
            onChange={props.onChange}
            renderTrack={({ props, children }) => (
              <div {...props} className="range-track">
                {children}
              </div>
            )}
            renderThumb={({ props }) => (
              <div className="range-thumb" {...props} />
            )}
          />
          <p
            className="value"
            style={{
              left: `calc(${(props.value[1] * 0.85 * 100) / 120}%)`,
              transform:
                (props.value[1] * 0.85 * 100) / 120 > 60
                  ? "translateX(-50%)"
                  : "translateX(-24%)"
            }}
          >
            {props.value[1]} min or less
          </p>
        </div>
      ) : (
        <InputRange
          maxValue={120}
          minValue={0}
          value={{ min: props.value[0], max: props.value[1] }}
          // @ts-expect-error Values may not include properties
          onChange={value => props.onChange([value.min, value.max])}
        />
      )}
    </div>
  </Collapse>
);

export const Rating = (props) => (
  <Collapse title="Reviews">
    <div className="options">
      {props.options.map(option => (
        <button
          key={option.label}
          className={cx("option", { active: props.value === option.value })}
          onClick={() => props.onChange(option.value)}
        >
          <img className="check" src={check} alt="Active" />
          {props.value === option.value && option.activeLabel
            ? option.activeLabel
            : option.label}
        </button>
      ))}
    </div>
  </Collapse>
);
