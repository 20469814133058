import React, { useState } from "react";
import cx from "classnames";
import ClickOutside from "react-click-outside";

const CustomSelect = ({
  options,
  value,
  leftLabel,
  labesClassName,
  wrapperClassName,
  onChange
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const handleChange = (val) => () => {
    setIsOpen(false);
    onChange(val);
  };

  return (
    <div className={cx("custom-select-1", wrapperClassName)}>
      {leftLabel && (
        <label className={cx("label", labesClassName)}>{leftLabel}</label>
      )}
      <ClickOutside onClickOutside={() => setIsOpen(false)}>
        <div className="select">
          <button className="toggle" onClick={() => setIsOpen(prev => !prev)}>
            {value}
          </button>
          {isOpen && (
            <div className="options-wrapper">
              {options.map(opt => (
                <div
                  key={opt.value}
                  onClick={handleChange(opt.value)}
                  className="option"
                >
                  {opt.label}
                </div>
              ))}
            </div>
          )}
        </div>
      </ClickOutside>
    </div>
  );
};

export default CustomSelect;
