import React, { useEffect, useRef } from "react";

const Textarea = (props) => {
  const ref = useRef();

  useEffect(() => {
    const textarea = (props.innerRef?.current ?? ref.current);
    const handler = () => {
      textarea.style.height = 'auto';
      textarea.style.height = textarea.scrollHeight + 'px';
    }
    textarea.addEventListener('input', handler);

    return () => {
      textarea.removeEventListener('input', handler);
    }
  }, [])

  return (
    <div className="textarea-container">
      {props.label && <label htmlFor={props.label}>{props.label}</label>}
  
      <textarea
        ref={(props.innerRef ?? ref)}
        id={props.label ? props.label : undefined}
        className={`${props.postfix ? "with-postfix" : ""}`}
        {...props}
      />
      {props.postfix && <p className="postfix">{props.postfix}</p>}
  
      <span className="error">{props.error && `* ${props.error}`}</span>
    </div>
  )
};

export default Textarea;
