import React from "react";
import moment from "moment";

const Day = props => (
  <div className="day">
    <p className="day-dayname">{moment(props.date).format("ddd")}</p>
    {!props.withoutDate && <p className="day-date">{moment(props.date).format("MMM D")}</p>}
  </div>
);

export default Day;
