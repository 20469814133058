import { call, put, takeLatest } from "redux-saga/effects";
import AdminsActions from "../../redux/admins/actions";
import EnumActions from "../../redux/enum/actions";
import {
  callGetAdmins,
  callRemoveAdmin,
  callUpdateAdmin,
  callAddAdmin
} from "../../api/admins";
import { callGetPermissions } from "../../api/enum";

export function* getPermissions(action) {
  try {
    yield put({ type: "GET_PERMISSIONS_REQUEST" });

    const response = yield call(callGetPermissions);
    yield put(
      EnumActions.setEnums({
        permissions: response.data
      })
    );

    if (action.callback) {
      action.callback(response.data);
    }

    yield put({ type: "GET_PERMISSIONS_SUCCESS" });
  } catch (error) {
    yield put({
      type: "GET_PERMISSIONS_FAILURE",
      error: error.message
    });
  }
}

export function* getAdmins(action) {
  try {
    yield put({ type: "GET_ADMINS_REQUEST" });

    const response = yield call(callGetAdmins);
    yield put(AdminsActions.setAdmins(response.data));

    yield put({ type: "GET_ADMINS_SUCCESS" });
  } catch (error) {
    yield put({
      type: "GET_ADMINS_FAILURE",
      error: error.message
    });
  }
}

export function* removeAdmin(action) {
  try {
    yield put({ type: "REMOVE_ADMIN_REQUEST" });

    const response = yield call(callRemoveAdmin, action.id);
    yield put(AdminsActions.removeAdmin(action.id));

    yield put({ type: "REMOVE_ADMIN_SUCCESS" });
  } catch (error) {
    yield put({
      type: "REMOVE_ADMIN_FAILURE",
      error: error.message
    });
  }
}

export function* updateAdmin(action) {
  try {
    yield put({ type: "UPDATE_ADMIN_REQUEST" });

    const response = yield call(callUpdateAdmin, action.id, action.body);
    action.onSuccess();

    yield put({ type: "UPDATE_ADMIN_SUCCESS" });
  } catch (error) {
    yield put({
      type: "UPDATE_ADMIN_FAILURE",
      error: error.message
    });
  }
}

export function* addAdmin(action) {
  try {
    yield put({ type: "ADD_ADMIN_REQUEST" });

    const response = yield call(callAddAdmin, action.admin);
    action.onSuccess();

    yield put({ type: "ADD_ADMIN_SUCCESS" });
  } catch (error) {
    yield put({
      type: "ADD_ADMIN_FAILURE",
      error: error.message
    });
  }
}

const adminsSaga = [
  takeLatest("GET_PERMISSIONS", getPermissions),
  takeLatest("GET_ADMINS", getAdmins),
  takeLatest("ADD_ADMIN", addAdmin),
  takeLatest("UPDATE_ADMIN", updateAdmin),
  takeLatest("REMOVE_ADMIN", removeAdmin)
];

export default adminsSaga;
