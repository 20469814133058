import { call, put, takeLatest } from "redux-saga/effects";
import ReviewsActions from "../../redux/reviews/actions";
import { callGetReviews, callRemoveReview } from "../../api/reviews";

export function* getReviews(action) {
  try {
    yield put({ type: "GET_REVIEWS_REQUEST" });

    const response = yield call(callGetReviews, action.page, action.params);
    yield put(ReviewsActions.setReviews(response.data.results));

    if (action.updatePagination) {
      action.updatePagination(response.data.pages);
    }

    yield put({ type: "GET_REVIEWS_SUCCESS" });
  } catch (error) {
    yield put({
      type: "GET_REVIEWS_FAILURE",
      error: error.message
    });
  }
}

export function* removeReview(action) {
  try {
    yield put({ type: "REMOVE_REVIEW_REQUEST" });

    const response = yield call(callRemoveReview, action.id);

    action.onSuccess();

    yield put({ type: "REMOVE_REVIEW_SUCCESS" });
  } catch (error) {
    yield put({
      type: "REMOVE_REVIEW_FAILURE",
      error: error.message
    });
  }
}

const reviewSaga = [
  takeLatest("GET_REVIEWS", getReviews),
  takeLatest("REMOVE_REVIEW", removeReview)
];

export default reviewSaga;
