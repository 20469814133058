import React, { Component } from "react";
import { Row, Col } from "react-grid-system";
import { Link } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { connect } from "react-redux";

import Layout from "../../components/Layout";
import PageHeader from "../../components/PageHeader";
import RoundedInput from "../../forms/RoundedInput";
import Button from "../../components/Button";
import Table from "../../components/Table";
import UserProfile from "./components/UserProfile";
import Checkbox from "../../forms/Checkbox";
import withPermissions from "../../utils/PermissionManager";

import trash from "../../assets/images/common/trash.svg";
import arrowBack from "../../assets/images/common/arrow-back.svg";
import arrowNext from "../../assets/images/common/arrow-next.svg";
import ConfirmModal from "./components/ConfirmModal";
import Status from "../../components/Status";
import UserMenu from "./components/UserMenu";

import { SUBSCRIPTION_TYPES } from "../../config/index";
import { EMPTY_FILTER } from "../../redux/users/reducer";
import * as UsersActionTypes from "../../redux/users/actionTypes";
import { DownloadButton } from "./components/DownloadButton/DownloadButton";
import { callGetUsersCSV } from "../../api/users";
import { PageLimit } from "../../components/PageLimits/PageLimit";
import { tableNames, TableSettings } from "../../components/TableSettings";
import UsersFilters from "./components/UsersFilters";
import loader from "../../assets/images/common/loader-web.svg";

export const SUBSCRIPTION_TYPES_NAMES = [
  { key: "none", value: "No" },
  ...SUBSCRIPTION_TYPES,
];

export const SUBSCRIPTION_STATUSES = [
  "active",
  "active-cancels",
  "past-due",
  "canceled",
  "trial",
  "abort",
];

export const LIMIT_USERS_OPTIONS = [
  { value: 10, label: 10 },
  { value: 25, label: 25 },
  { value: 50, label: 50 },
  { value: 100, label: 100 },
];

export const YES_NO = ["yes", "no"];

export const DEFAULT_SUBSCRIPTION_STATUSES = ["active", "past-due", "trial"];

const getColorByStatus = (status) => {
  switch (status) {
    case "past-due":
      break;
    case "abort":
      return "warning";
    case "canceled":
      return "error";
    case "active-cancels":
      break;
    default:
      return "success";
  }
};

class Rawkstars extends Component {
  constructor(props) {
    super(props);
    this.tableRef = React.createRef(null);
  }

  state = {
    selected: [],
    isSelectedAll: false,
    isFilterModalVisible: false,
    isConfirmDeleteUsersVisible: false,
    deleteUserId: undefined,
    limit: 10,
    sort: this.props.sort,
  };

  getUsers = () => {
    const params = { ...this.props.filters };
    params.search = this.props.search;
    params.page = this.props.page;
    params.limit = this.state.limit;
    params.isRecipeAuthor = this.getParamValue(params, "isRecipeAuthor");
    params.hasAllergy = this.getParamValue(params, "hasAllergy");
    params.sortBy = this.state.sort.field ?? "user";
    params.sortAt = this.state.sort.orderBy;

    Object.keys(params).forEach((key) => {
      if (params[key] === "") {
        delete params[key];
      }
    });

    this.props.getUsers(params);
  };

  componentWillMount() {
    this.getUsers();
  }

  getParamValue = (params, key) => {
    if (params[key].length === 1) {
      return params[key][0] === "yes";
    }
  };

  getStateHash = () => JSON.stringify(this.props.filters);

  loadUsers = () => setTimeout(() => this.getUsers());

  onSubmitFilter = (params) => {
    this.props.setFilters(params);

    this.loadUsers();
  };

  onChangeSubscriptionTypeFilter = (type, id) => {
    const copy = [...this.props.filters[type]];

    if (this.props.filters[type].includes(id)) {
      const index = this.props.filters[type].indexOf(id);
      copy.splice(index, 1);
    } else {
      copy.push(id);
    }

    this.props.setPage(1);
    this.props.setFilters({ ...this.props.filters, [type]: copy });

    this.setState({
      selected: [],
    });

    this.loadUsers();
  };

  resetPagination = () => {
    this.props.setPage(1);
    this.props.setFilters({ ...this.props.filters });
  };

  searchKeyPress = (e) => {
    if (e.key === "Enter") {
      this.resetPagination();
      this.loadUsers();
    }
  };

  setSelected = (id) => {
    const copy = [...this.state.selected];

    let resetAllSelected = false;

    if (copy.includes(id)) {
      const index = copy.indexOf(id);
      copy.splice(index, 1);
      if (this.state.isSelectedAll) {
        resetAllSelected = true;
      }
    } else {
      copy.push(id);
    }

    this.setState({
      ...this.state,
      selected: copy,
      isSelectedAll: resetAllSelected ? false : this.state.isSelectedAll,
    });
  };

  openModal = () => {
    this.setState({
      isFilterModalVisible: true,
    });
  };

  closeModal = () => {
    this.setState({
      isFilterModalVisible: false,
    });
  };

  openConfirmModal = (id) => {
    if (id || this.state.selected.length) {
      this.setState({ isConfirmDeleteUsersVisible: true, deleteUserId: id });
    }
  };

  onSelectAll = () => {
    if (this.state.isSelectedAll) {
      this.setState({ selected: [], isSelectedAll: false });
    } else {
      const allIds = [];
      this.props.users.forEach((user) => allIds.push(user._id));
      this.setState({ selected: allIds, isSelectedAll: true });
    }
  };

  closeConfirmModal = () =>
    this.setState({
      isConfirmDeleteUsersVisible: false,
      deleteUserId: undefined,
    });

  onPageChange = (page) => {
    this.props.setPage(page.selected + 1);

    this.setState({
      selected: [],
      isSelectedAll: false,
    });

    this.loadUsers();
  };

  onSortChange = (field, orderBy) => {
    this.setState({ sort: { field, orderBy } });

    this.loadUsers();
  };

  onSearchChange = (e) => {
    this.props.setSearch(e.target.value);
  };

  removeUsers = (id) => {
    if (this.props.filters.deletedOnly) {
      this.props.hardDeleteUser(id);
    } else {
      this.props.removeUsers(id);
    }
    this.setState({ selected: [], deleteUserId: undefined });
  };

  usedFiltersCount = () => {
    let count = 0;
    Object.keys(this.props.filters).forEach((key) => {
      if (Array.isArray(this.props.filters[key])) {
        count += this.props.filters[key].length;
      } else {
        if (
          typeof this.props.filters[key] === "string" &&
          this.props.filters[key] !== ""
        ) {
          count += 1;
        }
        if (
          typeof this.props.filters[key] === "boolean" &&
          this.props.filters[key]
        ) {
          count += 1;
        }
      }
    });

    return count;
  };

  resetFilters = () => {
    this.props.setFilters(EMPTY_FILTER);
    this.loadUsers();
  };

  changeLimitUsers = (e) => {
    this.setState({ limit: e.value });
    this.resetPagination();
    this.loadUsers();
  };

  render() {
    return (
      <Layout>
        <PageHeader title="Users" />
        <Row justify="between">
          <Col md={9}>
            <RoundedInput
              placeholder="Search"
              value={this.props.search}
              onChange={this.onSearchChange}
              onKeyPress={this.searchKeyPress}
            />
          </Col>

          <Col md={3}>
            <Link
              to="/rawkstars/add-user"
              className={`button ${
                !this.props.can("create", "user") ? "button-disabled" : ""
              }`}
            >
              + Add user
            </Link>
          </Col>
        </Row>

        <div className={this.props.loading ? "recipes-loading" : ""}>
          <Row
            style={{ gap: 10, marginTop: 16, marginLeft: 0, marginRight: 0 }}
            justify={"between"}
            align="end"
          >
            <Row
              style={{ gap: 10, marginTop: 16, marginLeft: 0, marginRight: 0 }}
            >
              {/* {SHORTCUTS_TYPES.map((short) => (
                <Button
                  key={short.key}
                  small
                  outlined={
                    !this.props.filters.subscriptionTypes?.includes(short.key)
                  }
                  limited
                  className="button-compact"
                  onClick={() =>
                    this.onChangeSubscriptionTypeFilter(
                      "subscriptionTypes",
                      short.key
                    )
                  }
                >
                  {short.value}
                </Button>
              ))}
              {SHORTCUTS_STATUSES.map((short) => (
                <Button
                  key={short}
                  small
                  outlined={
                    !this.props.filters.subscriptionStatuses?.includes(short)
                  }
                  limited
                  className="button-compact"
                  onClick={() =>
                    this.onChangeSubscriptionTypeFilter(
                      "subscriptionStatuses",
                      short
                    )
                  }
                >
                  {short.charAt(0).toUpperCase() + short.slice(1)}
                </Button>
              ))} */}
            </Row>
            <DownloadButton
              cb={() => callGetUsersCSV({ filters: this.props.filters })}
              fileName={"Rawkstars"}
            />
          </Row>
          <UsersFilters
            filter={this.props.filters}
            onSubmit={this.onSubmitFilter}
            key={this.getStateHash()}
          />
          {this.usedFiltersCount() > 0 && (
            <Button
              small
              limited
              className="button-compact button--right-icon"
              onClick={this.resetFilters}
            >
              Reset filters
            </Button>
          )}

          {this.props.users.length &&
          !this.props.isLoading &&
          this.props.isAuthenticated &&
          !this.props.loading ? (
            <TableSettings
              name={tableNames.users}
              meta={this.props.meta}
              tableRef={this.tableRef}
              itemsOnPageCount={this.props?.users?.length || 0}
              columns={[
                {
                  id: "select",
                  isHidden: false,
                  title: () => (
                    <Checkbox
                      value={this.state.isSelectedAll}
                      onChange={this.onSelectAll}
                    />
                  ),
                  target: "_id",
                  renderer: (id) => (
                    <>
                      {this.props.can("delete", "user") && (
                        <Checkbox
                          value={this.state.selected.includes(id)}
                          onChange={() => this.setSelected(id)}
                        />
                      )}
                    </>
                  ),
                },
                {
                  id: "user",
                  isHidden: false,
                  title: () => <div style={{ marginLeft: 65 }}>USER</div>,
                  target: "user",
                  renderer: (user) => {
                    return (
                      <div className="fixed-column fixed-column-user">
                        <UserProfile {...user} />
                      </div>
                    );
                  },
                  sortable: true,
                },

                {
                  id: "email",
                  isHidden: false,
                  title: "EMAIL",
                  target: "email",
                  sortable: true,
                  renderer: (email) => (
                    <p className="fixed-column fixed-column-email">{email}</p>
                  ),
                },
                {
                  id: "location",
                  isHidden: true,
                  title: "LOCATION",
                  target: "location",
                  sortable: true,
                  renderer: (loc) => (
                    <p className="fixed-column fixed-column-location">{loc}</p>
                  ),
                },
                {
                  id: "joined",
                  isHidden: false,
                  title: "JOINED",
                  target: "joined",
                  sortable: true,
                },
                {
                  id: "lastActivity",
                  isHidden: false,
                  title: "LAST ACTIVITY",
                  target: "lastActivity",
                  sortable: true,
                },
                {
                  id: "reviewsCount",
                  isHidden: false,
                  title: "REVIEWS",
                  target: "reviewsCount",
                  sortable: true,
                },
                {
                  id: "subscriptionStatus",
                  isHidden: false,
                  title: "SUBSCRIPTION",
                  target: "subscriptionStatus",
                  renderer: (id) => (
                    <div className="fixed-column fixed-column-status">
                      <Status color={getColorByStatus(id)}>
                        {id.charAt(0).toUpperCase() + id.slice(1)}
                      </Status>
                    </div>
                  ),
                },
                {
                  id: "subscriptionType",
                  isHidden: false,
                  title: "SUBSCRIPTION TYPE",
                  target: "subscriptionType",
                  renderer: (type) => (
                    <p className="fixed-column fixed-column-type">{type}</p>
                  ),
                },
                {
                  id: "isAACPActive",
                  isHidden: false,
                  title: "Has AACP",
                  target: "isAACPActive",
                  renderer: (type) => (
                    <p className="fixed-column fixed-column-type">
                      {type ? "Yes" : "No"}
                    </p>
                  ),
                },
                {
                  id: "actions",
                  isHidden: false,
                  title: () => (
                    <>
                      {this.props.can("delete", "user") && (
                        <img
                          src={trash}
                          alt="Delete selected users"
                          onClick={() => this.openConfirmModal()}
                        />
                      )}
                    </>
                  ),
                  target: "_id",
                  isHidden: false,
                  renderer: (id) => (
                    <UserMenu
                      id={id}
                      history={this.props.history}
                      isDeleted={
                        !!this.props.users.find((user) => user._id === id)
                          .deletedAt
                      }
                      onDelete={() => this.openConfirmModal([id])}
                      onRestore={() => this.props.restoreUser(id)}
                      isLast={this.props.users.findIndex(el => el._id === id) >= this.props.users.length - 2}
                    />
                  ),
                },
              ]}
            >
              {({ preparedColumns }) => (
                <Table
                  tableRef={this.tableRef}
                  perPage={50}
                  onSort={this.onSortChange}
                  sort={this.state.sort}
                  columns={preparedColumns}
                  data={this.props.users.map((u) => ({
                    ...u,
                    user: {
                      name: `${u.firstName} ${u.lastName}`,
                      photo: u.profileImage,
                    },
                    location:
                      u?.location || u?.countryCode
                        ? `${u.location}, ${u.countryCode}`
                        : "-",
                    subscriptionStatus:
                      typeof u.subscriptionStatus === "undefined" ||
                      u.subscriptionStatus === null
                        ? "abort"
                        : u.subscriptionStatus,
                    subscriptionType: SUBSCRIPTION_TYPES_NAMES.find(
                      (type) => type.key === u.subscription
                    )?.value,
                  }))}
                />
              )}
            </TableSettings>
          ) : this.props.isLoading || this.props.loading ? (
            <div className="page-loader">
              <img src={loader} alt="loader" />
            </div>
          ) : (
            <Row justify="center" className="not-found">
              Users not found
            </Row>
          )}
          <div className="page-controller users">
            <div className="pagination">
              {this.props.meta.totalPages > 1 && (
                <ReactPaginate
                  onPageChange={this.onPageChange}
                  pageCount={this.props.meta.totalPages}
                  activeClassName="active"
                  previousLabel={<img src={arrowBack} alt="back" />}
                  nextLabel={<img src={arrowNext} alt="next" />}
                />
              )}
            </div>
            <PageLimit
              value={this.state.limit}
              options={LIMIT_USERS_OPTIONS}
              placeholder="Select week"
              onChange={this.changeLimitUsers}
            />
          </div>
        </div>

        <ConfirmModal
          visible={this.state.isConfirmDeleteUsersVisible}
          close={this.closeConfirmModal}
          delete={(ids) => {
            this.removeUsers(ids);

            this.closeConfirmModal();
          }}
          ids={this.state.deleteUserId || this.state.selected}
        />
      </Layout>
    );
  }
}

const mapStateToProps = (state) => ({
  users: state.users.all,
  loading: state.loading["GET_USERS"],
  meta: state.users.meta,
  search: state.users.search,
  page: state.users.page,
  filters: state.users.filters,
  sort: state.users.sort,
});

const mapDispatchToProps = (dispatch) => ({
  getUsers: (params) => dispatch({ type: "GET_USERS", params }),
  removeUsers: (id) => dispatch({ type: "REMOVE_USER", id }),
  restoreUser: (id) => dispatch({ type: "RESTORE_USER", id }),
  setSearch: (search) =>
    dispatch({ type: UsersActionTypes.SET_USERS_SEARCH, search }),
  setPage: (page) => dispatch({ type: UsersActionTypes.SET_USERS_PAGE, page }),
  setFilters: (filters) =>
    dispatch({ type: UsersActionTypes.SET_USERS_FILTERS, filters }),
  setSort: (sort) => dispatch({ type: UsersActionTypes.SET_USERS_SORT, sort }),
  hardDeleteUser: (id) => dispatch({ type: "HARD_DELETE_USER", id }),
});

export default withPermissions(
  connect(mapStateToProps, mapDispatchToProps)(Rawkstars)
);
