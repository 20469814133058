import { isValidEmail } from "../../utils/helpers";

export default state => {
  let hasErrors = false;
  const errors = {};

  if (!state.firstName) {
    hasErrors = true;
    errors.firstName = "First name is required";
  }

  // if (!state.lastName) {
  //   hasErrors = true;
  //   errors.lastName = "Last name is required";
  // }

  if (!state.email) {
    hasErrors = true;
    errors.email = "Email is required";
  } else {
    if (!isValidEmail(state.email)) {
      hasErrors = true;
      errors.email = "Email is wrong";
    }
  }

  // if (!state.country) {
  //   hasErrors = true;
  //   errors.country = "Country is required";
  // }

  // if (!state.countryCode) {
  //   hasErrors = true;
  //   errors.countryCode = "Country code is required";
  // }

  // if (!state.location) {
  //   hasErrors = true;
  //   errors.location = "Location is required";
  // }

  if (!state.joined) {
    hasErrors = true;
    errors.joined = "Date is required";
  } else {
    if (!/\d{4}-\d{2}-\d{2}/.test(state.joined)) {
      hasErrors = true;
      errors.joined = "Date is wrong";
    }
  }

  if (hasErrors) {
    return errors;
  }

  return false;
};
