import React from "react";
import { callGetUsersCSV } from "../../../../api/users";
import Button from "../../../../components/Button";
import { useDownloadFile } from "../../../../utils/use-download-file";

export const DownloadButton = ({ cb, fileName }) => {
  const { download, name, ref, url } = useDownloadFile({
    apiDefinition: cb,
    //  async () => {

    // },
    fileName,
    postDownloading: () => {},
    preDownloading: () => {},
    onError: (e) => {},
  });
  return (
    <Button
      small
      limited
      outlined
      className="button-compact"
      onClick={download}
    >
      Export in XLS
    </Button>
  );
};
