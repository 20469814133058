import { all } from "redux-saga/effects";

import authenticationSaga from "./authentication";
import recipeSaga from "./recipe";
import articleSaga from "./article";
import mealSaga from "./meal";
import eventSaga from "./event";
import usersSaga from "./users";
import adminsSaga from "./admins";
import reviewsSaga from "./review";
import quotesSaga from "./quotes";
import settingsSaga from "./settings";
import backgroundsSaga from "./backgrounds";
import aacpPlanSaga from "./aacpPlans";

export default function* root() {
  yield all([
    ...authenticationSaga,
    ...recipeSaga,
    ...articleSaga,
    ...mealSaga,
    ...eventSaga,
    ...usersSaga,
    ...adminsSaga,
    ...reviewsSaga,
    ...quotesSaga,
    ...settingsSaga,
    ...backgroundsSaga,
    ...aacpPlanSaga
  ]);
}
