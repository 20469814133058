import { useCallback, useState } from "react";

function maybeJson(origin) {
  if (typeof origin !== "string") {
    return origin;
  }

  try {
    return JSON.parse(origin);
  } catch (_error) {
    return origin;
  }
}

function maybeFunction(value) {
  return (...args) => {
    return typeof value === "function" ? value(...args) : value;
  };
}

// Increment if you update the format
const version = { _$v: 1 };

function save(name, value) {
  localStorage.setItem(name, JSON.stringify([value, version]));
}

function getSaved(name) {
  if (typeof window !== "undefined") {
    const inWrap = maybeJson(localStorage.getItem(name));

    if (Array.isArray(inWrap) && inWrap[1]?._$v === version._$v) {
      return inWrap[0];
    }
  }
  return undefined;
}

/**
 * @param name Must be unique for whole application
 */
export default function usePersistentState(value, name) {
  const [state, setState] = useState(
    () => getSaved(name) ?? maybeFunction(value)()
  );

  const setBothState = useCallback(
    (newValue) =>
      setState((prevValue) => {
        const finalNew = maybeFunction(newValue)(prevValue);

        save(name, finalNew);

        return finalNew;
      }),
    []
  );

  return [state, setBothState];
}
