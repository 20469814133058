import React, { useMemo, useState } from "react";
import Modal from "../Modal";
import settings from "../../assets/images/sidebar/settings-b.svg";
import right from "../../assets/images/common/chevron-right.svg";
import left from "../../assets/images/common/chevron-left.svg";
import eyeClose from "../../assets/images/common/eye-closed.svg";
import eyeOpen from "../../assets/images/common/eye-open.svg";
import dragIcon from "../../assets/images/common/drag.svg";
import usePersistentState from "../../utils/usePersistentState";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import useCanScrollTable from "./useCanScrollTable";

export const tableNames = {
  users: "users-table",
};

export const filterValidFields = (data) =>
  data.map(({ renderer, ...item }) => ({ ...item }));

export const TableSettings = (props) => {
  const { name, columns, children, meta, itemsOnPageCount, tableRef } =
    props ?? {};
  const [isOpen, setIsOpen] = useState(false);
  const [storeColumns, setStoreColumns] = usePersistentState(
    columns || [],
    name
  );
  const [columnsList, setColumnsList] = useState(storeColumns);
  const handleCloseModal = () => {
    setIsOpen(false);
  };

  const handleSave = () => {
    setStoreColumns(columnsList);
    handleCloseModal();
  };

  const handleReset = () => {
    setColumnsList(columns);
  };

  const handleOnDragEnd = (result) => {
    if (!result.destination) return;

    const newList = [...columnsList];
    const [reorderedItem] = newList.splice(result.source.index, 1);
    newList.splice(result.destination.index, 0, reorderedItem);

    setColumnsList(newList);
  };

  const toggleVisible = (item, state) => {
    setColumnsList((prev) =>
      prev.map((columnItem) => {
        if (columnItem.id === item.id) {
          return { ...columnItem, isHidden: state };
        }
        return columnItem;
      })
    );
  };

  const renderColumns = useMemo(() => {
    return columnsList.map((item) => {
      const { isHidden, ...existColumn } = columns.find(
        (column) => column.id === item.id
      );
      if (existColumn) {
        return { ...item, ...existColumn };
      }
    });
  }, [storeColumns]);

  const { canScroll, canScrollToLeft, canScrollToRight } = useCanScrollTable(
    tableRef
  );

  return (
    <div className="table-settings">
      <div className="table-settings__top">
        <div>
          {!!meta && (
            <p>{`Lines ${(itemsOnPageCount < meta.limit
              ? 1
              : meta.limit - itemsOnPageCount + 1) +
              meta.limit * (meta.page - 1)}-${itemsOnPageCount +
              meta.limit * (meta.page - 1)} out of ${
              meta.total
            } lines are displayed`}</p>
          )}
        </div>
        <div className="table-settings__iconButtons">
          <button
            className="table-settings__iconButton"
            onClick={() => setIsOpen(true)}
          >
            <img src={settings} />
          </button>
          {tableRef && canScroll ? (
            <>
              <button
                className="table-settings__iconButton"
                disabled={!canScrollToLeft}
                onClick={() => {
                  tableRef.current.scrollBy({
                    left: -200,
                    behavior: "smooth",
                  });
                }}
              >
                <img src={left} />
              </button>
              <button
                disabled={!canScrollToRight}
                className="table-settings__iconButton"
                onClick={() => {
                  tableRef.current.scrollBy({
                    left: 200,
                    behavior: "smooth",
                  });
                }}
              >
                <img src={right} />
              </button>
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
      <div className="table-settings__content">
        {children({
          preparedColumns: renderColumns,
        })}
      </div>
      <Modal
        className="settings-modal"
        visible={isOpen}
        buttons={[
          { onClick: handleCloseModal, children: "Close" },
          { onClick: handleReset, children: "Reset", outlined: true },
          { onClick: handleSave, children: "Save" },
        ]}
        close={handleCloseModal}
      >
        <div className="row-settings">
          <DragDropContext onDragEnd={handleOnDragEnd}>
            <Droppable droppableId={name}>
              {(provided) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  className="row-settings__list"
                >
                  {columnsList.map((item, index) => (
                    <Draggable
                      key={`row-${index + 1}`}
                      draggableId={`row-${index + 1}`}
                      index={index}
                    >
                      {(provided) => (
                        <div
                          className="row-settings__listItem"
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <div className="row-settings__drag">
                            <div className="row-settings__dragIcon">
                              <img src={dragIcon} />
                            </div>
                            <p>
                              {typeof item?.title === "string"
                                ? item?.title
                                : item?.id}
                            </p>
                          </div>

                          <button
                            className="row-settings__hideButton"
                            onClick={() => toggleVisible(item, !item?.isHidden)}
                          >
                            <img src={item.isHidden ? eyeClose : eyeOpen} />
                          </button>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
      </Modal>
    </div>
  );
};
