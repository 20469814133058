import React from "react";
import { Row, Col } from "react-grid-system";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import moment from 'moment';

import { call } from "../../api/core";
import { getUpdatedBody } from "../../utils/helpers";
import validate from "./validation";
import Layout from "../../components/Layout";
import PageHeader from "../../components/PageHeader";
import Input from "../Input";
import Button from "../../components/Button";
import ImageUpload from "../../components/ImageUpload";
import AddInfo from "../AddInfo";
import withPermissions from "../../utils/PermissionManager";
import NoPermissions from "../../components/NoPermissions";

class AddEvent extends React.Component {
  state = {
    title: "",
    start: null,
    end: null,
    location: "",
    photo: "",
    info: "",
    signUpUrl: '',
    callToActionText: '',
    changedFields: [],
    errors: null
  };

  async componentDidMount() {
    if (this.props.editing) {
      const response = await call(
        `/event/${this.props.match.params.id}`,
        "GET",
        {}
      );

      this.setState({
        ...response.data,
        end: moment(response.data.end).format('YYYY-MM-DD'),
        start: moment(response.data.start).format('YYYY-MM-DD'),
      });
    }
  }

  changeField = (field, value) => {
    const changedFields = [...this.state.changedFields];

    if (changedFields.indexOf(field) === -1) {
      changedFields.push(field);
    }

    this.setState({
      [field]: value,
      changedFields
    });
  };

  onSuccess = () => {
    this.props.history.push("/wellness");
  };

  onSubmit = () => {
    const errors = validate(this.state);

    if (errors) {
      this.setState({ errors });
      return;
    } else {
      this.setState({ errors: null });
    }

    if (this.props.editing) {
      const body = getUpdatedBody(this.state.changedFields, this.state);

      if (body.start) {
        body.start = moment(body.start).format('MM/DD/YYYY');
      }

      if (body.end) {
        body.end = moment(body.end).format('MM/DD/YYYY');
      }

      this.props.updateArticle(
        this.props.match.params.id,
        body,
        this.onSuccess
      );
    } else {
      this.props.addArticle(
        {
          title: this.state.title,
          start: moment(this.state.start).format('MM/DD/YYYY'),
          end: this.state.end ? moment(this.state.end).format('MM/DD/YYYY') : null,
          location: this.state.location,
          photo: this.state.photo,
          signUpUrl: this.state.signUpUrl,
          callToActionText: this.state.callToActionText,
          info: this.state.info
        },
        this.onSuccess
      );
    }
  };

  render() {
    const buttonText = this.props.editing ? "Update" : "Publish";
    const loading = this.props.adding || this.props.updating;

    const canRead = this.props.can("read", "event");
    const canEdit = this.props.can("edit", "event");
    const canCreate = this.props.can("create", "event");

    const editingMode = canRead && canEdit && this.props.editing;
    const creatingMode = canCreate && !this.props.editing;

    return (
      <Layout>
        <PageHeader
          title="Add Event"
          breadcrumbs="Back to Calendar of Events"
          breadcrumbsLink="/wellness"
        />
        {editingMode || creatingMode ? (
          <>
            <Row>
              <Col md={6}>
                <ImageUpload
                  disableUpload={!this.props.can("create", "file")}
                  value={this.state.photo}
                  onChange={value => this.changeField("photo", value)}
                />
              </Col>
              <Col md={6}>
                <Input
                  label="Title"
                  placeholder="Type here"
                  value={this.state.title}
                  onChange={e => this.changeField("title", e.target.value)}
                  error={this.state.errors && this.state.errors.title}
                />
                <Row>
                  <Col md={6}>
                    <Input
                      label="Start"
                      placeholder="Select dates"
                      type="date"
                      value={this.state.start}
                      onChange={e => this.changeField("start", e.target.value)}
                      error={this.state.errors && this.state.errors.start}
                    />
                  </Col>
                  <Col md={6}>
                    <Input
                      label="End"
                      placeholder="Select dates"
                      type="date"
                      value={this.state.end}
                      onChange={e => this.changeField("end", e.target.value)}
                      error={this.state.errors && this.state.errors.end}
                    />
                  </Col>
                </Row>
                <Input
                  label="Location"
                  placeholder="Type here"
                  value={this.state.location}
                  onChange={e => this.changeField("location", e.target.value)}
                  error={this.state.errors && this.state.errors.location}
                />
                <Row>
                  <Col md={6}>
                    <Input
                      label="Signup URL"
                      placeholder="Type here"
                      value={this.state.signUpUrl}
                      onChange={e => this.changeField("signUpUrl", e.target.value)}
                      error={this.state.errors && this.state.errors.signUpUrl}
                    />
                  </Col>
                  <Col md={6}>
                    <Input
                      label="Call to action Text"
                      placeholder="Type here"
                      value={this.state.callToActionText}
                      onChange={e => this.changeField("callToActionText", e.target.value)}
                      error={this.state.errors && this.state.errors.callToActionText}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            <AddInfo
              value={this.state.info}
              onChange={value => this.changeField("info", value)}
              error={this.state.errors && this.state.errors.info}
            />
            <Button limited onClick={this.onSubmit}>
              {buttonText + (loading ? "..." : "")}
            </Button>
            <p className="error">
              {this.state.errors
                ? "Fill all requirement fields and try again"
                : ""}
            </p>
          </>
        ) : (
          <NoPermissions />
        )}
      </Layout>
    );
  }
}

const mapStateToProps = state => ({
  adding: state.loading["ADD_EVENT"],
  updating: state.loading["UPDATE_EVENT"]
});

const mapDispatchToProps = dispatch => ({
  addArticle: (event, onSuccess) =>
    dispatch({ type: "ADD_EVENT", event, onSuccess }),
  updateArticle: (id, body, onSuccess) =>
    dispatch({ type: "UPDATE_EVENT", id, body, onSuccess })
});

export default withPermissions(
  connect(mapStateToProps, mapDispatchToProps)(withRouter(AddEvent))
);
