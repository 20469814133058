import React from "react";

import Tooltip from "../../../../components/Tooltip";

import edit from "../../../../assets/images/common/edit.svg";
import trash from "../../../../assets/images/common/trash.svg";

const Article = (props) => (
  <div className="article">
    <h3
      className={`title ${props.canEdit ? "hoverable" : ""}`}
      onClick={props.canEdit ? () => props.onEditClick(props._id) : undefined}
    >
      {props.title}
    </h3>
    <div className="photo" style={{ backgroundImage: `url(${props.photo})` }}>
      {props.duration ? <div className="badge">{props.duration}</div> : <div />}
      <Tooltip
        buttons={[
          props.canEdit && {
            title: "Edit",
            icon: edit,
            onClick: () => props.onEditClick(props._id),
          },
          props.canDelete && {
            title: "Delete",
            icon: trash,
            onClick: () => props.onDeleteClick(props._id),
          },
        ]}
      />
    </div>
  </div>
);

export default Article;
