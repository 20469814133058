import { call, put, takeLatest } from "redux-saga/effects";
import SettingsActions from "../../redux/settings/actions";

import {
  callGetMaxPlanQuantity,
  callUpdateMaxPlanQuantity,
} from "../../api/settings";

export function* getMaxPlanQuantity(action) {
  try {
    yield put({ type: "GET_MAX_PLAN_QUANTITY_REQUEST" });

    const response = yield call(callGetMaxPlanQuantity);

    yield put(
      SettingsActions.setMaxPlanQuantity(response.data.maxNumberOfPlans)
    );

    yield put({ type: "GET_MAX_PLAN_QUANTITY_SUCCESS" });
    action.onSuccess();
  } catch (error) {
    yield put({
      type: "GET_MAX_PLAN_QUANTITY_FAILURE",
      error: error.message,
    });
  }
}

export function* updateMaxPlanQuantity(action) {
  try {
    yield put({ type: "UPDATE_MAX_PLAN_QUANTITY_REQUEST" });

    const response = yield call(
      callUpdateMaxPlanQuantity,
      action.localMaxPlanQuantity
    );

    yield put(SettingsActions.setMaxPlanQuantity(action.localMaxPlanQuantity));

    yield put({ type: "UPDATE_MAX_PLAN_QUANTITY_SUCCESS" });
    action.onSuccess();
  } catch (error) {
    action.onError(error);
    yield put({
      type: "UPDATE_MAX_PLAN_QUANTITY_FAILURE",
      error: error.message,
    });
  }
}

const settingsSaga = [
  takeLatest("UPDATE_MAX_PLAN_QUANTITY", updateMaxPlanQuantity),
  takeLatest("GET_MAX_PLAN_QUANTITY", getMaxPlanQuantity),
];

export default settingsSaga;
