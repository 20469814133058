import { call, put, takeLatest } from "redux-saga/effects";
import RecipeActions from "../../redux/recipe/actions";
import EnumActions from "../../redux/enum/actions";
import {
  callAddRecipe,
  callUpdateRecipes,
  callGetRecipes,
  callRemoveRecipe,
  callGetIngredientsList,
} from "../../api/recipe";
import {
  callGetAllergens,
  callGetTypes,
  callGetSeasons,
  callGetForms,
  callGetUnits,
  callGetCategories,
} from "../../api/enum";
import { toast } from "react-toastify";

export function* getFormData() {
  try {
    yield put({ type: "GET_RECIPE_FORM_DATA_REQUEST" });

    const allergens = yield call(callGetAllergens);
    const types = yield call(callGetTypes);
    const seasons = yield call(callGetSeasons);
    const forms = yield call(callGetForms);
    const units = yield call(callGetUnits);
    const categories = yield call(callGetCategories);

    const data = {
      allergens: allergens.data,
      types: types.data,
      seasons: seasons.data,
      forms: forms.data,
      units: units.data,
      categories: categories.data,
    };

    yield put(EnumActions.setEnums(data));

    yield put({ type: "GET_RECIPE_FORM_DATA_SUCCESS" });
  } catch (error) {
    yield put({
      type: "GET_RECIPE_FORM_DATA_FAILURE",
      error: error.message,
    });
  }
}

export function* getRecipes(action) {
  try {
    yield put({ type: "GET_RECIPES_REQUEST" });

    const response = yield call(callGetRecipes, action.page, action.params);
    if (action.setPageCount) {

      action.setPageCount(
        response.data.pages === 1 ? null : response.data.pages
      );
    }
    yield put(RecipeActions.setRecipes(response.data.results));

    yield put({ type: "GET_RECIPES_SUCCESS" });
  } catch (error) {
    yield put({
      type: "GET_RECIPES_FAILURE",
      error: error.message,
    });
  }
}

export function* getIngredientsList(action) {
  try {
    yield put({ type: "GET_INGREDIENTS_LIST_REQUEST" });

    const response = yield call(callGetIngredientsList);

    yield put(EnumActions.setEnums({
      ingredients: response.data.ingredients
    }));

    yield put({ type: "GET_INGREDIENTS_LIST_SUCCESS" });
  } catch (error) {
    yield put({
      type: "GET_INGREDIENTS_LIST_FAILURE",
      error: error.message,
    });
  }
}

const formatReceiptDataFroApi = (recipe) => {
  const data = new FormData();

  Object.keys(recipe).forEach((key) => {
    if (key === "image") {
      if (recipe.image?.file) {
        data.append("file", recipe.image.file);
      } else {
        data.append("image", recipe.image);
      }

      return;
    }

    data.append(key, JSON.stringify(recipe[key]));
  });

  return data;
};

export function* addRecipe(action) {
  try {
    yield put({ type: "ADD_RECIPE_REQUEST" });

    const data = formatReceiptDataFroApi(action.recipe);

    yield call(callAddRecipe, data);
    action.onSuccess();

    yield put({ type: "ADD_RECIPE_SUCCESS" });
  } catch (error) {
    toast.error(`Create recipe server error: ${error.message}`);
    yield put({
      type: "ADD_RECIPE_FAILURE",
      error: error.message,
    });
  }
}

export function* updateRecipe(action) {
  try {
    yield put({ type: "UPDATE_RECIPE_REQUEST" });

    const data = formatReceiptDataFroApi(action.body);

    yield call(callUpdateRecipes, action.id, data);
    action.onSuccess();

    yield put({ type: "UPDATE_RECIPE_SUCCESS" });
  } catch (error) {
    toast.error(`Update recipe server error: ${error.message}`);
    yield put({
      type: "UPDATE_RECIPE_FAILURE",
      error: error.message,
    });
  }
}

export function* removeRecipe(action) {
  try {
    yield put({ type: "REMOVE_RECIPE_REQUEST" });

    yield call(callRemoveRecipe, action.id);
    action.onSuccess();

    yield put({ type: "REMOVE_RECIPE_SUCCESS" });
  } catch (error) {
    yield put({
      type: "REMOVE_RECIPE_FAILURE",
      error: error.message,
    });
  }
}

const recipeSaga = [
  takeLatest("GET_RECIPE_FORM_DATA", getFormData),
  takeLatest("GET_RECIPES", getRecipes),
  takeLatest("ADD_RECIPE", addRecipe),
  takeLatest("UPDATE_RECIPE", updateRecipe),
  takeLatest("REMOVE_RECIPE", removeRecipe),
  takeLatest("GET_INGREDIENTS_LIST", getIngredientsList),
];

export default recipeSaga;
