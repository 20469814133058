import * as ActionTypes from "./actionTypes";

const initialState = {
  all: [],
  current: null
};

export default function messages(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.SET_AACP_PLANS:
      return {
        ...state,
        all: action.plans,
      };
    case ActionTypes.SET_CURRENT_AACP_PLAN:
      return {
        ...state,
        current: action.plan,
      };
    default:
      return state;
  }
}
