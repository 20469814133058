import React from "react";
import plus from '../../assets/images/common/plus-circle-outline.svg';
import minus from '../../assets/images/common/minus-circle-outline.svg';

class Counter extends React.Component {
  increase = () => {
    const value =
      typeof this.props.value === "number"
        ? this.props.value
        : this.props.defaultValue;

    if (value === undefined) {
      return;
    }

    const nextValue = value + 1;

    if (this.props.onChange) {
      this.props.onChange(nextValue);
    }
  };

  decrease = () => {
    const value =
      typeof this.props.value === "number"
        ? this.props.value
        : this.props.defaultValue;

    if (value === undefined) {
      return;
    }

    const nextValue = value - 1;

    if (nextValue >= 1 && this.props.onChange) {
      this.props.onChange(nextValue);
    }
  };

  render() {
    const value =
      typeof this.props.value === "number"
        ? this.props.value
        : this.props.defaultValue;

    return (
      <div className={`counter ${this.props.disabled ? "disabled" : ""}`}>
        <button className="counter-button card-counter-button" onClick={this.decrease}>
          <img src={minus} />
        </button>
        <span className="counter-value card-counter-value">{value || 0}</span>
        <button className="counter-button card-counter-button" onClick={this.increase}>
          <img src={plus} />
        </button>
      </div>
    );
  }
}

export default Counter;
