import React from "react";
import RCSlider from "rc-slider";
import Tooltip from "rc-tooltip";

const Handle = RCSlider.Handle;
const createSliderWithTooltip = RCSlider.createSliderWithTooltip;

const SliderWithTooltip = createSliderWithTooltip(RCSlider)

class Slider extends React.Component {
  handle = props => (
    <Tooltip
      prefixCls="rc-slider-tooltip"
      overlay={`${props.value} min`}
      visible={true}
      placement="bottom"
      key={props.index}
      align={{
        offset: [0, 5]
      }}
    >
      <Handle {...props} />
    </Tooltip>
  );

  render() {
    return (
      <div className="slider">
        <p className="label">Cooking time</p>
        <SliderWithTooltip min={0} max={120} {...this.props} handle={this.handle} tipFormatter={value => `${value} min`} tipProps={{
          placement: 'bottom',
          visible: true,
        }} />
      </div>
    );
  }
}

export default Slider;
