export default state => {
  let hasErrors = false;
  const errors = {};

  if (!state.title || state.title.length < 6) {
    hasErrors = true;
    errors.title = "Title is required (minimum 6 characters)";
  }

  if (!state.content || state.content === "<p><br></p>") {
    hasErrors = true;
    errors.content = "Info is required";
  }

  if (hasErrors) {
    return errors;
  }

  return false;
};
