import * as ActionTypes from "./actionTypes";

const setUser = (id, name) => ({
  type: ActionTypes.SET_USER,
  id,
  name
});

const clearUser = () => ({
  type: ActionTypes.CLEAR_USER
});

const setPermissions = permissions => ({
  type: ActionTypes.SET_PERMISSIONS,
  permissions
});

export default {
  setUser,
  clearUser,
  setPermissions
};
