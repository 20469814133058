import * as ActionTypes from "./actionTypes";

const setMaxPlanQuantity = (maxPlanQuantity) => ({
  type: ActionTypes.SET_MAX_PLAN_QUANTITY,
  maxPlanQuantity,
});

export default {
  setMaxPlanQuantity,
};
