import React, { useState } from "react";
import { Col, Row } from "react-grid-system";
import Input from "../../../../forms/Input";
import Checkbox from "../../../../forms/Checkbox";
import { SUBSCRIPTION_STATUSES, SUBSCRIPTION_TYPES_NAMES } from "../../index";
import { MultySelect } from "../../../../components/MultySelect";
import { SUBSCRIPTION_TYPES } from "../../../../config";
import Button from "../../../../components/Button";

const SHORTCUTS_TYPES = [...SUBSCRIPTION_TYPES];

const SHORTCUTS_STATUSES = [
  "active",
  // "canceled"
];

const UsersFilters = ({ filter, onSubmit }) => {
  const [filters, setFilters] = useState(filter);

  const onStateChange = (type, value, multiple) => {
    setFilters((prevState) => {
      let newFilters = {
        ...prevState,
      };

      if (multiple) {
        const currentValues = newFilters[type];
        if (currentValues.includes(value)) {
          newFilters[type] = currentValues.filter(el => el !== value);
        } else {
          newFilters[type] = currentValues.concat(value);
        }
      } else {
        newFilters[type] = value;
      }
      onSubmit(newFilters);
      return newFilters;
    });
  };

  return (
    <>
      <Row style={{ marginBottom: "24px", marginTop: "24px" }}>
        <Col md={5}>
          <div>
            <p className="sub-type-title">Subscription type</p>
            <Row
              style={{ gap: 10, marginTop: 16, marginLeft: 0, marginRight: 0 }}
            >
              {SUBSCRIPTION_TYPES_NAMES.map((short) => (
                <Button
                  key={short.key}
                  small
                  outlined={
                    !filter.subscriptionTypes?.includes(short.key)
                  }
                  limited
                  className="button-compact"
                  onClick={() =>
                    onStateChange(
                      "subscriptionTypes",
                      short.key,
                      true
                    )
                  }
                >
                  {short.value}
                </Button>
              ))}
            </Row>
          </div>
        </Col>
        <Col md={5}>
          <div>
            <p className="sub-type-title">Subscription status</p>
            <Row
              style={{ gap: 10, marginTop: 16, marginLeft: 0, marginRight: 0 }}
            >
              {SUBSCRIPTION_STATUSES.map((short) => (
                <Button
                  key={short}
                  small
                  outlined={
                    !filter.subscriptionStatuses?.includes(short)
                  }
                  limited
                  className="button-compact"
                  onClick={() =>
                    onStateChange(
                      "subscriptionStatuses",
                      short,
                      true
                    )
                  }
                >
                  <span style={{ textTransform: 'capitalize' }}>{short}</span>
                </Button>
              ))}
            </Row>
          </div>
        </Col>
        <Col md={2}>
          <div
            style={{
              display: "flex",
              height: "100%",
              alignItems: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                height: "32px",
                marginTop: "30px",
              }}
            >
              <Checkbox
                name="With AACP"
                value={filters.hasAACP}
                onChange={() =>
                  onStateChange("hasAACP", filters.hasAACP ? undefined : true)
                }
              />
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={5}>
          <span className="label">Joined</span>
          <div className="checkboxes-list">
            <Row justify="between">
              <Col md={6}>
                <Input
                  label="From"
                  placeholder="Type here"
                  type="date"
                  value={filters.registrationDateStart}
                  onChange={(e) =>
                    onStateChange("registrationDateStart", e.target.value)
                  }
                />
              </Col>
              <Col md={6}>
                <Input
                  label="To"
                  placeholder="Type here"
                  type="date"
                  value={filters.registrationDateEnd}
                  onChange={(e) =>
                    onStateChange("registrationDateEnd", e.target.value)
                  }
                />
              </Col>
            </Row>
          </div>
        </Col>
        <Col md={5}>
          <span className="label">Last activity</span>
          <div className="checkboxes-list">
            <Row justify="between">
              <Col md={6}>
                <Input
                  label="From"
                  placeholder="Type here"
                  type="date"
                  value={filters.lastActivityStart}
                  onChange={(e) =>
                    onStateChange("lastActivityStart", e.target.value)
                  }
                />
              </Col>
              <Col md={6}>
                <Input
                  label="To"
                  placeholder="Type here"
                  type="date"
                  value={filters.lastActivityEnd}
                  onChange={(e) =>
                    onStateChange("lastActivityEnd", e.target.value)
                  }
                />
              </Col>
            </Row>
          </div>
        </Col>
        <Col md={2}>
          <div
            style={{
              display: "flex",
              height: "100%",
              alignItems: "flex-end",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                height: "32px",
                marginBottom: "24px",
              }}
            >
              <Checkbox
                name="Deleted users"
                value={filters.deletedOnly}
                onChange={() =>
                  onStateChange("deletedOnly", !filters.deletedOnly)
                }
              />
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default UsersFilters;
