import * as ActionTypes from "./actionTypes";

const setArticles = articles => ({
  type: ActionTypes.SET_ARTICLES,
  articles
});

const removeArticle = id => ({
  type: ActionTypes.REMOVE_ARTICLE,
  id
});

export default {
  setArticles,
  removeArticle
};
