import React from "react";
import { useEffect } from "react";
import { useCallback } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import Modal from "../../components/Modal";
import { OPTIONS_DATE } from "../../utils/optionsDate";
import Input from "../Input";
import Select from "../Select";
import Textarea from "../Textarea";
import { useValidateQuotes } from "./use-validate-quotes";

export const QuotesFormModal = (props) => {
  const { opened, setOpened, id, isEdit = false } = props;

  const quote = useSelector((state) =>
    state.quotes.quotes.find((quote) => id === quote._id)
  );

  const errors = useSelector((state) => state.error);

  const [quoteBody, setQuoteBody] = useState();
  const [quoteNumber, setQuoteNumber] = useState();
  const [quoteAuthor, setQuoteAuthor] = useState();

  const { errorAuthor, errorBody, errorNumber, hasError } = useValidateQuotes({
    author: quoteAuthor,
    body: quoteBody,
    number: quoteNumber,
  });

  const dispatch = useDispatch();

  const clearForm = useCallback(() => {
    setQuoteNumber(undefined);
    setQuoteAuthor("");
    setQuoteBody("");
  }, []);

  useEffect(() => {
    if (!isEdit) {
      clearForm();
    } else {
      setQuoteNumber(quote?.number);
      setQuoteAuthor(quote?.author);
      setQuoteBody(quote?.body);
    }
  }, [clearForm, isEdit, quote]);

  const confirmHandler = () => {
    if (!isEdit) {
      dispatch({
        type: "ADD_QUOTE",
        quote: {
          body: quoteBody,
          author: quoteAuthor,
          number: quoteNumber,
        },
      });
    } else {
      dispatch({
        type: "UPDATE_QUOTE",
        quote: {
          body: quoteBody,
          author: quoteAuthor,
          number: quoteNumber,
          _id: id,
        },
      });
    }
    clearForm();
    setOpened(false);
  };

  const closeHandler = useCallback(() => {
    !isEdit && clearForm();
    setOpened(false);
  }, [clearForm, setOpened]);

  return (
    <Modal
      className="recipes-modal"
      withoutBorder
      visible={opened}
      close={closeHandler}
      buttons={[
        {
          children: "Cancel",
          disabled: false,
          outlined: true,
          small: false,
          onClick: closeHandler,
        },
        {
          children: "Save",
          disabled: hasError,
          outlined: false,
          small: false,
          onClick: confirmHandler,
        },
      ]}
    >
      <h3 className="quotes-title">{isEdit ? "Edit quote" : "Add quote"}</h3>

      <Textarea
        rows={10}
        classNames={{ label: "addInfo-label" }}
        value={quoteBody}
        onChange={(e) => setQuoteBody(e.target.value)}
        label={"Content"}
        placeholder="Content"
        error={errorBody}
      />
      <Input
        label={"Author"}
        error={errorAuthor}
        placeholder="Author"
        type="text"
        value={quoteAuthor}
        onChange={(e) => setQuoteAuthor(e?.target?.value)}
      />
      <Select
        value={+quoteNumber}
        label={"Week"}
        options={OPTIONS_DATE}
        placeholder="Select week"
        error={errorNumber}
        onChange={(option) => {
          setQuoteNumber(+option?.target?.value);
        }}
      />
    </Modal>
  );
};
