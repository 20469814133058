import { isValidEmail } from "../../utils/helpers";

export default state => {
  let hasErrors = false;
  const errors = {};

  if (!state.name) {
    hasErrors = true;
    errors.name = "Name is required";
  }

  if (!state.email) {
    hasErrors = true;
    errors.email = "Email is required";
  } else {
    if (!isValidEmail(state.email)) {
      hasErrors = true;
      errors.email = "Email is wrong";
    }
  }

  if (hasErrors) {
    return errors;
  }

  return false;
};
