import { call } from "../core";

export const callAddArticle = recipe => {
  return call("/article", "POST", { body: recipe });
};

export const callGetArticles = () => {
  return call(`/article/list/1`, "GET", {});
};

export const callRemoveArticle = id => {
  return call(`/article/${id}`, "DELETE", {});
};

export const callUpdateArticle = (id, body) => {
  return call(`/article/${id}`, "PUT", { body });
};
