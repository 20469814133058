export default function valueOnError(origin, value, log) {
  return (...args) => {
    try {
      const result = origin(...args);

      if (result instanceof Promise) {
        return new Promise((resolve) => {
          result.then(resolve).catch(() => resolve(value()));
        });
      }

      return result;
    } catch (error) {
      if (log) {
        console.error("Catched error: ", error);
      }

      return value();
    }
  };
}
