import { call, put, takeLatest } from "redux-saga/effects";
import BackgroundsActions from "../../redux/backgrounds/actions";

import {
  callAddBackground,
  callGetBackgrounds,
  callRemoveBackground,
  callUpdateBackground,
} from "../../api/backgrounds";
import { toast } from "react-toastify";

export function* getBackgrounds(action) {
  try {
    yield put({ type: "GET_BACKGROUNDS_REQUEST" });

    const response = yield call(
      callGetBackgrounds,
      action.requestInfo ?? {
        page: 1,
        limit: 10,
        sortAt: 1,
        sortBy: "startDate",
      }
    );
    yield put(BackgroundsActions.setBackgrounds(response.data));

    yield put({ type: "GET_BACKGROUNDS_SUCCESS" });
  } catch (error) {
    yield put({
      type: "GET_BACKGROUNDS_FAILURE",
      error: error.message,
    });
  }
}

export function* removeBackground(action) {
  try {
    yield put({ type: "REMOVE_BACKGROUND_REQUEST" });

    const response = yield call(callRemoveBackground, action.id);

    yield put(BackgroundsActions.removeBackground(action.id));

    yield put({ type: "REMOVE_BACKGROUND_SUCCESS" });
  } catch (error) {
    yield put({
      type: "REMOVE_BACKGROUND_FAILURE",
      error: error.message,
    });
  }
}

export function* updateBackground(action) {
  try {
    yield put({ type: "UPDATE_BACKGROUND_REQUEST" });

    const resp = yield call(callUpdateBackground, action.background);

    yield put(BackgroundsActions.editBackground(resp?.data));
    action.onSuccess();
    yield put({ type: "UPDATE_BACKGROUND_SUCCESS" });
  } catch (error) {
    toast.error(
      `Create background error: ${error.code === 409 ? "background overlaps with other backgrounds" : error.message}`
    );
    action.onError();
    action.onError();
    yield put({
      type: "UPDATE_BACKGROUND_FAILURE",
      error: error.message,
    });
  }
}

export function* addBackground(action) {
  try {
    yield put({ type: "ADD_BACKGROUND_REQUEST" });

    const response = yield call(callAddBackground, action.background);
    yield put(BackgroundsActions.addBackground(response?.data));
    action.onSuccess();
    yield put({ type: "ADD_BACKGROUND_SUCCESS" });
  } catch (error) {
    toast.error(
      `Create background error: ${error.code === 409 ? "background overlaps with other backgrounds" : error.message}`
    );
    action.onError();
    yield put({
      type: "ADD_BACKGROUND_FAILURE",
      error: error.message,
    });
  }
}

const backgroundsSaga = [
  takeLatest("GET_BACKGROUNDS", getBackgrounds),
  takeLatest("ADD_BACKGROUND", addBackground),
  takeLatest("UPDATE_BACKGROUND", updateBackground),
  takeLatest("REMOVE_BACKGROUND", removeBackground),
];

export default backgroundsSaga;
