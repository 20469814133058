import React from "react";
import { Col } from "react-grid-system";
import Button from "../../../components/Button";

export const AddButton = (props) => {
  const { buttonHandler, children } = props;
  return (
    <Col md={2}>
      <Button className={`button`} onClick={buttonHandler}>
        {children}
      </Button>
    </Col>
  );
};
