import React, { useState } from "react";
import { useEffect } from "react";
import { useRef } from "react";
import arrowDown from "../../assets/images/common/arrow-down.svg";

const Select = (props) => {
  const [isOpen, setIsOpen] = useState(false);

  const ref = useRef(null);

  useEffect(() => {
    ref.current.blur();
    setIsOpen(false);
  }, [props.value]);
  return (
    <div className="select-container">
      {props.label && <label htmlFor={props.label}>{props.label}</label>}
      <div className="wrapper">
        <select
          id={props.label ? props.label : undefined}
          className={`select ${props.value ? "selected" : ""} ${props.class ||
            ""}`}
          ref={ref}
          {...props}
          onChange={e => {
            setIsOpen(false);
            props.onChange(e);
          }}
          onFocus={() => {
            setIsOpen(true)
          }}
          onBlur={() => setIsOpen(false)}
        >
          <option value="" disabled hidden>
            {props.placeholder || "Please choose"}
          </option>
          {props.options.map((option) => (
            <option
              value={option.value}
              selected={option.value === props.value || option.selected}
              disabled={!!option.disabled}
            >
              {option.label}
            </option>
          ))}
        </select>
        <div className="arrow">{">"}</div>
        <div className={`global-postfix ${isOpen ? 'active' : ''}`}>
          <img src={arrowDown} className='select-arrow pure' />
        </div>
      </div>
      <span className="error">{props.error}</span>
    </div>
  );
};

export default Select;
