import React from "react";
import { Col, Row } from "react-grid-system";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import moment from 'moment';

import PageHeader from "../../components/PageHeader";
import Input from "../Input";
import Button from "../../components/Button";
import Layout from "../../components/Layout";
import Chip from "../../components/Chip";
import { call } from "../../api/core";

import validate from "./validation";
import { getUpdatedBody } from "../../utils/helpers";
import withPermissions from "../../utils/PermissionManager";
import NoPermissions from "../../components/NoPermissions";
import Checkbox from "../Checkbox";

class UserForm extends React.Component {
  state = {
    firstName: "",
    lastName: "",
    email: "",
    location: '',
    joined: '',
    changedFields: [],
    errors: null,
    error: '',
    isAACPActive: false,
  };

  async componentDidMount() {
    if (this.props.editing) {
      const response = await call(
        `/user/${this.props.match.params.id}`,
        "GET",
        {}
      );

      this.setState({
        ...response.data,
        joined: moment(response.data.joined).format('YYYY-MM-DD'),
      });
    }
  }

  updateChangedFields = field => {
    const changedFields = [...this.state.changedFields];

    if (changedFields.indexOf(field) === -1) {
      changedFields.push(field);
    }

    this.setState({
      changedFields
    });
  };

  changeField = (field, value) => {
    this.setState({
      [field]: value
    });

    this.updateChangedFields(field);
  };

  onSuccess = () => {
    this.props.history.push("/rawkstars");
  };

  onSubmit = () => {
    const errors = validate(this.state);

    if (errors) {
      this.setState({ errors });
      return;
    } else {
      this.setState({ errors: null });
    }

    if (this.props.editing) {
      this.props.updateUser(
        this.props.match.params.id,
        getUpdatedBody(this.state.changedFields, {
          ...this.state,
          joined: moment(this.state.joined, 'YYYY-MM-DD').format('MM/DD/YYYY'),
        }),
        this.onSuccess
      );
    } else {
      this.props.addUser(
        {
          firstName: this.state.firstName,
          lastName: this.state.lastName,
          email: this.state.email,
          location: this.state.location,
          country: this.state.country,
          countryCode: this.state.countryCode,
          joined: moment(this.state.joined, 'YYYY-MM-DD').format('MM/DD/YYYY'),
        },
        this.onSuccess,
      );
    }
  };

  changeCheckbox() {
    console.log(1);
    this.setState({
      isAACPActive: !this.state.isAACPActive,
    });
    this.updateChangedFields('isAACPActive');
  }

  render() {
    const buttonText = this.props.editing ? "Update" : "Add";
    const loading = this.props.adding || this.props.updating;

    const canRead = this.props.can("read", "user");
    const canEdit = this.props.can("edit", "user");
    const canCreate = this.props.can("create", "user");

    const editingMode = canRead && canEdit && this.props.editing;
    const creatingMode = canCreate && !this.props.editing;

    return (
      <Layout>
        <PageHeader
          title={this.props.editing ? "Edit User" : "Add User"}
          breadcrumbs="Back to Rawkstars"
          breadcrumbsLink="/rawkstars"
        />
        {editingMode || creatingMode ? (
          <>
            <Row>
              <Col md={6}>
                <Input
                  label="First Name"
                  placeholder="Type here"
                  value={this.state.firstName}
                  onChange={e => this.changeField("firstName", e.target.value)}
                  error={this.state.errors && this.state.errors.firstName}
                />
                <Input
                  label="Last Name"
                  placeholder="Type here"
                  value={this.state.lastName}
                  onChange={e => this.changeField("lastName", e.target.value)}
                  error={this.state.errors && this.state.errors.lastName}
                />
                <Input
                  label="Email"
                  placeholder="Type here"
                  value={this.state.email}
                  onChange={e => this.changeField("email", e.target.value)}
                  error={this.state.errors && this.state.errors.email}
                />
                <Input
                  label="Joined"
                  placeholder="Type here"
                  type="date"
                  value={this.state.joined}
                  onChange={e => this.changeField("joined", e.target.value)}
                  error={this.state.errors && this.state.errors.joined}
                />
              </Col>
            </Row>
            <div className="edit-user-actions">
              <Button disabled={this.state.changedFields.length === 0} limited onClick={this.onSubmit}>
                {buttonText + (loading ? "..." : "")}
              </Button>
              <Checkbox name="Has AACP pass" value={this.state.isAACPActive} onChange={this.changeCheckbox.bind(this)} />
            </div>
            <p className="error">
              {this.state.errors
                ? "Fill all requirement fields and try again"
                : "" || this.props.addingError || this.props.updatingError}
            </p>
          </>
        ) : (
          <NoPermissions />
        )}
      </Layout>
    );
  }
}

const mapStateToProps = state => ({
  adding: state.loading["ADD_USER"],
  addingError: state.error["ADD_USER"],
  updating: state.loading["UPDATE_USER"],
  updatingError: state.error["UPDATE_USER"],
});

const mapDispatchToProps = dispatch => ({
  addUser: (user, onSuccess) => dispatch({ type: "ADD_USER", user, onSuccess }),
  updateUser: (id, body, onSuccess) =>
    dispatch({ type: "UPDATE_USER", id, body, onSuccess }),
});

export default withPermissions(
  connect(mapStateToProps, mapDispatchToProps)(withRouter(UserForm))
);
