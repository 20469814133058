import React from "react";
import { call } from "../../../../api/core";

class PDFUploader extends React.Component {
  state = {
    file: null,
    loading: null
  };

  handleUpload = async e => {
    if (!e.target.files[0]) {
      return;
    }
    this.setState({ loading: true },()=>{
      if(this.props.onUploadStart) {
        this.props.onUploadStart();
      }
    });

    const formData = new FormData();
    formData.append("file", e.target.files[0]);

    this.setState({
      file: e.target.files[0]
    });

    const response = await call("/files/upload", "POST", {
      headers: { "Content-Type": "multipart/form-data" },
      body: formData
    });

    this.setState({
      loading: false
    },()=>{
      if (this.props.onChange) {
        this.props.onChange(response.data.tmpFile);
      }
      if(this.props.onUploadEnd) {
        this.props.onUploadEnd();
      }
    });

    
  };

  render() {
    const propsFilename = this.props.value
      ? this.props.value.split("/")[this.props.value.split("/").length - 1]
      : "";
    const stateFilename = this.state.file ? this.state.file.name : "";
    const filename = stateFilename || propsFilename;

    const defaultMessage = this.props.disableUpload
      ? "You cannot upload files"
      : "+ Upload PDF";

    return (
      <div className="pdf-uploader">
        <input
          id="upload-pdf"
          type="file"
          accept="application/pdf"
          multiple={false}
          style={{ display: "none" }}
          onChange={this.handleUpload}
          disabled={this.props.disableUpload}
        />
        <p className="label">Upload PDF</p>
        <label
          htmlFor="upload-pdf"
          className={`area ${filename ? "selected" : ""}`}
        >
          {this.state.loading?"Uploading PDF file...":(filename || defaultMessage)}
        </label>
      </div>
    );
  }
}

export default PDFUploader;
