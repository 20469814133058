import React from "react";

import Input from "../../../Input";
import Button from "../../../../components/Button";
import AddInfo from "../../../AddInfo";
import note from '../../../../assets/images/common/note-edit.svg';

const Notes = props => (
  <React.Fragment>
    <div className="note-title">
      <img src={note} />
      <p>Notes</p>
    </div>
    <AddInfo value={props.notes} onChange={props.changeNote} hideLabel noMargin />
  </React.Fragment>
);

export default Notes;
