import React from "react";

import { MaxNumberPlans } from "./MaxNumberPlans";
import { BackgroundsTab } from "./BackgroundsTab";

export const GeneralTab = () => {
  return (
    <div>
      <MaxNumberPlans />
      <BackgroundsTab />
    </div>
  );
};
