import { call, put, takeLatest } from "redux-saga/effects";
import { callCreateAACPPlan, callDeleteAACPMeal, callDeleteAACPPlans, callUpdateAACPMeal, callUpdateAACPPlan, callgetAACPPlans } from "../../api/aacpPlans";
import AACPPlansActions from '../../redux/aacpPlans/actions';

export function* createPlan(action) {
  try {
    yield put({ type: "CREATE_AACP_PLAN_REQUEST" });

    yield call(callCreateAACPPlan, action.plan);
    action.onSuccess();
    yield put({ type: "CREATE_AACP_PLAN_SUCCESS" });
  } catch (error) {
    yield put({
      type: "CREATE_AACP_PLAN_FAILURE",
      error: error.message
    });
  }
}

export function* getAACPPlans(action) {
  try {
    yield put({ type: "GET_AACP_PLANS_REQUEST" });
    const response = yield call(callgetAACPPlans, action.slug);
    yield put(action.slug ? AACPPlansActions.setCurrentPlan(response.data[0]) :AACPPlansActions.setPlans(response.data));

    yield put({ type: "GET_AACP_PLANS_SUCCESS" });
  } catch (error) {
    yield put({
      type: "GET_AACP_PLANS_FAILURE",
      error: error.message
    });
  }
}

export function* deleteAACPPlan(action) {
  try {
    yield put({ type: "DELETE_AACP_PLAN_REQUEST" });

    const response = yield call(callDeleteAACPPlans, action.id);
    yield put(AACPPlansActions.setPlans(response.data));
    action.onSuccess();
    yield put({ type: "DELETE_AACP_PLAN_SUCCESS" });
  } catch (error) {
    yield put({
      type: "DELETE_AACP_PLAN_FAILURE",
      error: error.message
    });
  }
}

export function* updateAACPPlan(action) {
  try {
    yield put({ type: "UPDATE_AACP_PLAN_REQUEST" });
    const response = yield call(callUpdateAACPPlan, action.id, action.meals, action.name, action.isPlanActive);
    action.onSuccess();
    yield put({ type: "UPDATE_AACP_PLAN_SUCCESS" });
  } catch (error) {
    yield put({
      type: "UPDATE_AACP_PLAN_FAILURE",
      error: error.message
    });
  }
}

export function* deleteAACPMeal(action) {
  try {
    yield put({ type: "DELETE_AACP_MEAL_REQUEST" });
    yield call(callDeleteAACPMeal, action.id, action.mealId);
    action.onSuccess();
    yield put({ type: "DELETE_AACP_MEAL_SUCCESS" });
  } catch (error) {
    yield put({
      type: "DELETE_AACP_MEAL_FAILURE",
      error: error.message
    });
  }
}

export function* updateAACPMeal(action) {
  try {
    yield put({ type: "UPDATE_AACP_MEAL_REQUEST" });
    yield call(callUpdateAACPMeal, action.id, action.mealId, action.mealPlan);
    action.onSuccess();
    yield put({ type: "UPDATE_AACP_MEAL_SUCCESS" });
  } catch (error) {
    yield put({
      type: "UPDATE_AACP_MEAL_FAILURE",
      error: error.message
    });
  }
}

const aacpPlanSaga = [
  takeLatest("CREATE_AACP_PLAN", createPlan),
  takeLatest('GET_AACP_PLANS', getAACPPlans),
  takeLatest('DELETE_AACP_PLAN', deleteAACPPlan),
  takeLatest('UPDATE_AACP_PLAN', updateAACPPlan),
  takeLatest('DELETE_AACP_MEAL', deleteAACPMeal),
  takeLatest('UPDATE_AACP_MEAL', updateAACPMeal),
];

export default aacpPlanSaga;