import React from "react";
import { useRef } from "react";
import Button from "../Button";
import loader from "../../assets/images/common/loader-web.svg";

export const UploadImage = React.memo((props) => {
  const {
    onChangeImageUrl,
    onChangeImageFile,
    onChangeImages,
    imageValue,
    isEmpty,
    disableUpload,
    isLoading,
    error,
  } = props;

  const ref = useRef(null);

  const handleUploadImage = ({ target }) => {
    const files = target.files;

    onChangeImageUrl(URL.createObjectURL(files[0]));
    onChangeImageFile(files[0]);
    onChangeImages();
    target.value = "";
  };

  const handleDeleteImage = () => {
    onChangeImageUrl("");
    onChangeImageFile(null);
    onChangeImages({});
  };

  return (
    <div className="image-upload image-upload-new">
      <div
        className={`
            image-preview new
            ${isEmpty ? "empty" : ""}
            ${isLoading ? "loading" : ""}
          `}
        style={imageValue}
      >
        {isLoading && <img className="loader" src={loader} alt="loader" />}
      </div>
      <p className="size">Recomended image size: 3840 x 2160</p>
      <p className="size">Allowed file extensions: jpeg, png, webp, svg</p>
      <input
        ref={ref}
        id="upload-file"
        type="file"
        accept="image/*"
        multiple={false}
        style={{ display: "none" }}
        onChange={handleUploadImage}
        disabled={disableUpload || isLoading}
      />

      <div className="buttons">
        {false ? (
          "You cannot upload files"
        ) : (
          <>
            <label htmlFor="upload-file" className="button button-small">
              Upload Photo
            </label>
            <Button small outlined onClick={handleDeleteImage}>
              Delete
            </Button>
          </>
        )}
      </div>
      <span className="error">{error ? `* ${error}` : ""}</span>
    </div>
  );
});
