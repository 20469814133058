import React from "react";
import { Row, Col } from "react-grid-system";

import Input from "../../../Input";

const getPrettyNumber = (num) => {
  if (num % 1 !== 0) {
    return num.toFixed(1);
  }

  return num;
};

const Nutritions = props => (
  <React.Fragment>
    <Row>
      <Col md={3}>
        <div className="input-bold-title">
          <Input
            error={props.caloriesError}
            type="number"
            label="Calories"
            placeholder="Type here"
            postfix="Kcal"
            value={getPrettyNumber(props.calories)}
            name="calories"
            onChange={props.onChange}
          />
        </div>
      </Col>
      <Col md={3}>
        <div className="input-bold-title">
          <Input
            error={props.carbsError && props.carbsError.grams}
            type="number"
            label="Carbs"
            placeholder="Type here"
            postfix="g"
            value={getPrettyNumber(props.carbs.grams)}
            name="carbs"
            onChange={props.onChange}
          />
        </div>
      </Col>
      <Col md={3}>
        <div className="input-bold-title">
          <Input
            error={props.proteinError && props.proteinError.grams}
            type="number"
            label="Protein"
            placeholder="Type here"
            postfix="g"
            value={getPrettyNumber(props.protein.grams)}
            name="protein"
            onChange={props.onChange}
          />
        </div>
      </Col>
      <Col md={3}>
        <div className="input-bold-title">
          <Input
            error={props.fatError && props.fatError.grams}
            type="number"
            label="Fat"
            placeholder="Type here"
            postfix="g"
            value={getPrettyNumber(props.fat.grams)}
            name="fat"
            onChange={props.onChange}
          />
        </div>
      </Col>
    </Row>
  </React.Fragment>
);

export default Nutritions;
